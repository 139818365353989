import styled from 'styled-components';

export const Fade = styled.div`
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    display: flex; 
    justify-content: center;
    align-items: center;
    z-index: 5000;
    backdrop-filter: blur(10px);
    background: white;
    flex-direction: column;
    & > button {
        margin-top: var(--space);
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: var(--space);
`

export const CropperContainer = styled.div`
    width: 500px;
    height: 500px;
    position: relative;
`

export const Container = styled.img`
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 12px rgba(0,0,0,15%);
    opacity: 1;
    transition: all 300ms ease-in-out;
    :hover {
        opacity: 0.75;
    }
`

export const Placeholder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,10%);
    color: grey;
    font-size: 32px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    transition: all 300ms ease-in-out;
    :hover {
        opacity: 0.75;
    }
`