import { faChartBar, faClock, faImage } from '@fortawesome/free-regular-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { Alert } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { updateThumb } from '../actions/common.actions';
import ProviderShiftHours from '../components/ProviderShiftHours/ProviderShiftHours';
import Thumb from '../components/Thumb';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import store from '../store';
import AddressInput from './AddressInput/AddressInput';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

export const VALID_NAME_REGEXP = /^[a-zzżźćńółęąśŻŹĆĄŚĘŁÓŃA-Z0-9!@#$%^^&*().-?+'":;]+(?: [a-zzżźćńółęąśŻŹĆĄŚĘŁÓŃA-Z0-9!@#$%^^&*().-?+'":;]+)*$/

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function ProviderForm({ date, workerId, initialData, hide }) {
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { 
        data: segments = [],
    } = useQuery({ queryKey: ['segments'], queryFn: DataService.getDicts })

    const queryClient = useQueryClient()

    const onScheduleChange = function(action) {
        const state = formik.values['schedule']
        let newState;
        const { weekDay, type, which, hour } = action
        switch (type) {
            case 'SET_OPEN':
                newState = { 
                    ...state, 
                    [weekDay]: { from: 7, to: 21 }  
                }
                break;
            case 'SET_CLOSED':
                const result = { ...state }
                delete result[weekDay]
                newState = result
                break;
            case 'CHANGE_HOUR':
                newState = { 
                    ...state, 
                    [weekDay]: { ...state[weekDay], [which]: hour }
                }
                break;
        }
        formik.setFieldValue('schedule', newState)
    }

    const ProviderSchema = useMemo(() => {
        return (
            Yup.object().shape({
                name: Yup.string()
                    .min(3).max(100)
                    .matches(VALID_NAME_REGEXP)
                    .required('Nazwa jest wymagana'),
                description: Yup.string()
                  .min(0)
                  .max(1000),
                segments: Yup.array().min(1).max(10).required('Minimum jeden segment jest wymagany'),  
                address: Yup.object().required('Adres jest wymagany'),  
                phone: Yup.string().required().min(9).max(9), 
                // thumb: Yup.string().required()
              })
        )
    }, [])

    const availableSegments = useMemo(() => {
        return UtilHelper.parseOptions(segments, 'key', 'name')
            // .filter((segment) => (provider.segments || []).includes[segment.value])
            // @todo: Add filtering above
    }, [segments])

    const formik = useFormik({
        initialValues: {
          name: initialData?.name || '',
          description: initialData?.description || '',
          segments: initialData?.segments || [],
          address: initialData?.address || {},
          phone: initialData?.phone,
          thumb: initialData?.thumb,
          schedule: initialData?.schedule || {}
        },
        validateOnMount: true,
        validationSchema: ProviderSchema,
        onSubmit: async values => {
            setLoading(true)
            if (initialData) {
                await DataService.editProvider(values)
                hide()
            } else {
                await DataService.addProvider(values)
                history.push('/panel')
            }
            queryClient.invalidateQueries({queryKey: ['provider']})
            setLoading(false)
        },
    });

    const onThumbUpdate = useCallback((imgData) => {
        store.dispatch(updateThumb(imgData))
            .then(url => {
                formik.setFieldValue('thumb', url)
            })
    }, [])

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                <Fragment containerStyles={{ alignItems: 'center' }} icon={faImage} text="Miniatura">
                    <Thumb
                        callback={onThumbUpdate}
                        src={formik.values.thumb}
                    />
                </Fragment>
                <Fragment icon={faChartBar} text="Dane podstawowe">
                    <section className={styles.section}>
                        <label>Nazwa</label>
                        <Input 
                            placeholder="max. 50 znaków"
                            error={Boolean(formik.errors['name']) && formik.dirty}
                            className={styles.textInput}
                            name="name"
                            id="name"
                            type="text"
                            value={formik.values['name']}
                            onChange={formik.handleChange}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Segmenty</label>
                        <Select
                            isMulti
                            placeholder="Wybierz obszary działalności"
                            options={availableSegments}
                            value={UtilHelper.resolveValueFromDict(availableSegments, formik.values['segments'])}
                            onChange={values => formik.setFieldValue('segments', values.map(v => v.value))}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Opis</label>
                        <textarea 
                            placeholder="max. 1000 znaków"
                            style={{ height: 100 }}
                            value={formik.values['description']}
                            error={Boolean(formik.errors['description']) && formik.dirty}
                            className={styles.textInput}
                            name="description"
                            id="description"
                            type="text"
                            onChange={formik.handleChange}
                        />
                    </section>
                </Fragment>
                <Fragment 
                    text="Dane teleadresowe" 
                    icon={faPhoneAlt}
                >                
                    <section className={styles.section}>
                        <label>Adres</label>
                        <AddressInput 
                            placeholder="max. 50 znaków"
                            error={Boolean(formik.errors['address']) && formik.dirty}
                            className={styles.textInput}
                            name="address"
                            id="address"
                            type="text"
                            value={formik.values['address'].displayAddress}
                            onChange={(addressDto) => formik.setFieldValue('address', addressDto)}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Telefon</label>
                        <Input 
                            placeholder=""
                            error={Boolean(formik.errors['phone']) && formik.dirty}
                            className={styles.textInput}
                            name="phone"
                            value={formik.values['phone']}
                            id="phone"
                            type="text"
                            onChange={formik.handleChange}
                        />
                    </section>
                </Fragment>
                <Fragment text="Godziny otwarcia" icon={faClock}>
                    <Alert variant="outlined" severity="info">
                        Poniższe godziny to tylko informacyjny czas działania salonu.
                        By ustalić czas pracy poszczególnych pracowników przejdź do kalendarza i
                        wybierz pracownika. 
                    </Alert>
                    <ProviderShiftHours
                        containerStyles={{ marginTop: 16, overflow: 'visible' }}
                        schedule={formik.values['schedule']}
                        onChange={onScheduleChange}
                    />
                </Fragment>
                <div className={styles.space}></div>
                <Submit
                    disabled={!formik.isValid}
                    label="Zapisz"
                    loading={loading}
                    className={styles.submit} 
                    onSubmit={formik.handleSubmit}
                ></Submit>
        </form>
    )
}

export default ProviderForm
