import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function CategoryForm({ category, callback, hide, availableCategories }) {

    const [loading, setLoading] = useState(false)

    const queryClient = useQueryClient()

    const CategorySchema = useMemo(() => {
        return (
            Yup.object().shape({
                category: Yup.string()
                  .required('Kategoria jest wymagana'),
                name: Yup.string().required()
              })
        )
    }, [])

    const formik = useFormik({
        initialValues: {
            category,
            name: ''
        },
        validateOnMount: true,
        validationSchema: CategorySchema,
        onSubmit: async values => {
            setLoading(true)
            const added = await DataService.addCustomCategory(values)
            queryClient.invalidateQueries({queryKey: ['customCategories']})
            callback(added.key)
            setLoading(false)
            hide()
        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Fragment icon={faChartBar} text="Dane podstawowe">
                <section className={styles.section}>
                    <label>Kategoria</label>
                    <Select
                        options={availableCategories}
                        placeholder="Wybierz kategorię"
                        value={UtilHelper.resolveValueFromDict(availableCategories, formik.getFieldProps('category').value)}
                        onChange={option => formik.setFieldValue('category', option.value)}
                    />
                </section>
                <section className={styles.section}>
                    <label>Nazwa</label>
                    <Input 
                        placeholder="max. 50 znaków"
                        error={Boolean(formik.errors['name']) && formik.dirty}
                        className={styles.textInput}
                        name="name"
                        id="name"
                        type="text"
                        value={formik.getFieldProps('name').value}
                        onChange={formik.handleChange}
                    />
                </section>
            </Fragment>
            <div className={styles.space}></div>
            <Submit
                disabled={!formik.isValid}
                label="Zapisz"
                loading={loading}
                className={styles.submit}
            ></Submit>
        </form>
    )
}

export default CategoryForm
