import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, IconButton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { logout } from '../../actions/common.actions'
import DataService from '../../services/DataService'
import store from '../../store'
import { Row } from '../layout/common'
import NotificationsIndicator from '../NotificationsIndicator'

function Logout({ user }) {

    const onLogout = () => {
        store.dispatch(logout())
    }
    
    return (
        <Row vCentered>
            <NotificationsIndicator />
            <Avatar sx={{ width: 30, height: 30 }} src={user?.thumb}></Avatar>
            <small style={{ margin: '0 8px'}}>{user?.firstname}</small>
            <IconButton onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOut} />
            </IconButton>
        </Row>
    )
}

export default Logout
