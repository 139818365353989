import moment from 'moment'
import _ from 'lodash'

export default {
    isSameDayFilter: function(date, path = null) {
        return (item) => moment(date).isSame(moment(path ? _.get(item, path) : item), 'date')
    },
    isSameHourFilter: function(date, path = null) {
        return (item) => moment(date).isSame(moment(path ? _.get(item, path) : item), 'hour')
    },
}