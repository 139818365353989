import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, OutlinedInput } from '@material-ui/core'
import { Button, IconButton, Select, Slider, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useCallback } from 'react'
import { pushModal } from '../../../actions/common.actions'
import { Row } from '../../../components/layout/common'
import PageContainer from '../../../components/layout/PageContainer'
import StyledTableRow from '../../../components/layout/StyledTableRow'
import LoadingDots from '../../../components/LoadingDots/LoadingDots'
import { availableUnits } from '../../../const/constants'
import ProductForm from '../../../forms/ProductForm'
import DataService from '../../../services/DataService'
import store from '../../../store'
import Nav from '../Nav'
import styled from 'styled-components';

const Formatter = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' })

function CurrentStateInput({ product }) {
    const queryClient = useQueryClient()
    const { currentState, _id } = product
    const [value, setValue] = useState(currentState)
    const [isEditing, setIsEditing] = useState(false)
    const onSave = async () => {
        await DataService.updateProductState(_id, value)
        queryClient.invalidateQueries({queryKey: ['products']})
        setIsEditing(false)
    }
    const onCancel = () => {
        setIsEditing(false)
        setValue(0)
    }
    const Component = isEditing ? (
        <>
            <OutlinedInput 
                value={value} 
                onChange={e => setValue(e.target.value)} 
                style={{ width: 75}} />
            <IconButton color="success" onClick={onSave} variant="outlined">
                <FontAwesomeIcon icon={faCheck} />
            </IconButton>
            <IconButton color="error" onClick={onCancel} variant="outlined">
                <FontAwesomeIcon icon={faTimes} />
            </IconButton>
        </>
    ) : (
        <>
            <Button color="success" onClick={() => setIsEditing(true)} variant="contained">Uzupełnij</Button>
        </>
    )

    return (
        <Row marginBetween>
            {Component}
        </Row>
    )
}

function ProductsPage() {
    const { data: products = [], isFetching } = useQuery({queryKey: ['products'], queryFn: DataService.getProducts })

    const onAdd = useCallback(() => {
        store.dispatch(pushModal({
            header: 'Dodaj produkt',
            Component: ProductForm
        }))
    }, [])

    const onEdit = useCallback((product) => {
        store.dispatch(pushModal({
            header: 'Edytuj produkt',
            Component: ProductForm,
            extraProps: {
                initialData: product
            }
        }))
    }, [])

    return (
        <PageContainer>
            <Nav title="Produkty" />
            <Button
                size="large"
                startIcon={<FontAwesomeIcon icon={faPlus} />} 
                variant="outlined" 
                onClick={onAdd}>
                Nowy produkt
            </Button>
            <br/>
            <br/>
                <TableContainer>
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>L.p.</TableCell>
                            <TableCell>Nazwa</TableCell>
                            <TableCell align="center">Aktualny stan</TableCell>
                            <TableCell align="center">Jednostka</TableCell>
                            <TableCell align="center">Cena</TableCell>
                            <TableCell align="center">Pozostało</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((product, index) => {
                            const { latestState, currentState } = product
                            return (
                                <StyledTableRow key={product._id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell align="center" width={200}>
                                        {currentState}
                                    </TableCell>
                                    <TableCell align="center">
                                        {availableUnits.find(unit => unit.value === product.unit)?.label}
                                    </TableCell>
                                    <TableCell align="center">
                                        {Formatter.format(product.price)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {currentState && <Slider 
                                            disabled 
                                            valueLabelDisplay="auto"
                                            color={'success'}
                                            value={currentState} 
                                            max={latestState} 
                                        />}
                                    </TableCell>
                                    <TableCell>
                                        <Row marginBetween>
                                            <Button 
                                                startIcon={<FontAwesomeIcon icon={faEdit}/>} 
                                                onClick={() => onEdit(product)}
                                                variant="outlined"
                                            >
                                                Edytuj
                                            </Button>
                                            <CurrentStateInput product={product} />
                                        </Row>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="center" colSpan={7}>
                                {isFetching && <LoadingDots />}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                    </Table>
                </TableContainer>
        </PageContainer>
    )
}

export default ProductsPage
