import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Column } from '../../../components/layout/common';
import CalendarDay from './CalendarDay';
import { useQuery } from '@tanstack/react-query'
import DataService from '../../../services/DataService';
import useWorkerPermission from '../../../hooks/useWorkerPermissions';
import DateHelper from '../../../helpers/DateHelper';
import UtilHelper from '../../../helpers/UtilHelper';
import _ from 'lodash';

const HeaderTile = styled.div`
    padding: 16px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    background: #ededed;
    border-right: 1px solid grey;
    align-items: flex-end;
    line-height: 16px;
`

const Income = styled.small`
    padding: 5px;
    font-size: 10px;
    border: 1px solid var(--main-color);
    border-radius: 10px;
    color: var(--main-color);
`

function CalendarDayView({ currentDate, mode }) {

    const { data: workers, isLoading } = useQuery({queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })
    const hasIncomeVisibilityPerm = useWorkerPermission('SHOW_TOTAL_DAILY_AMOUNT')

    const { 
        data: schedule = [],
    } = useQuery({ queryKey: ['shift'], queryFn: DataService.getWorkersScheduleForWeek, refetchOnWindowFocus: false })

    const day = useMemo(() => {
        if (!workers) return null
        // const start = moment(currentDate).startOf('date')

        return _.orderBy(workers.map((w) => ({
            ...w,
            schedule: schedule
                .filter(DateHelper.isSameDayFilter(currentDate, 'date'))
                .find(UtilHelper.isSameFilter(w?._id, 'worker'))
        })), ['schedule'], ['asc'])
        .map((worker, index) => {
            const date = moment(currentDate)
            return (
                <CalendarDay
                    key={worker._id}
                    worker={worker}
                    mode={mode}
                    dayIndex={index}
                    isShiftVisible={true}
                    date={date}
                    HeaderComponent={({ income }) => {
                        return (
                            <HeaderTile>
                                <Column>
                                    <small>{worker.lastname}</small>
                                    <br/>
                                    <b>{worker.firstname}</b>
                                </Column>
                                {(income > 0 && hasIncomeVisibilityPerm) && <Income>{income}zł</Income>}
                            </HeaderTile>
                        )
                    }}
                />
            )
        })
    }, [workers, currentDate, mode, hasIncomeVisibilityPerm, schedule])

    return (
        <>
        {day}
        </>
    )
}

export default CalendarDayView
