export const OPEN_HOURS = {
    start: 7, end: 24
}

export const NEW_SUFFIX = '(Nowy)'

export const parsedPaymentMethods = [{ label: 'Karta', value: 'CARD' }, { label: 'Gotówka', value: 'CASH' }, { label: 'Blik', value: 'BLIK' }]

export const availableUnits = [
    { value: 'ML', label: 'Mililitry' }, 
    { value: 'L', label: 'Litry' },
    { value: 'P', label: 'Sztuki' },
]
