import React from 'react'
import { Redirect } from 'react-router-dom'
import { showAlert } from '../../actions/common.actions'
import store from '../../store'

function PaymentPage() {

    store.dispatch(showAlert({
        onConfirm: () => {},
        title: 'Zakończono płatność',
        type: 'confirmation',
        text: "Dziękujemy za dokonanie płatności. Otrzymasz potwierdzenie na adres e-mail, gdy zostanie przeprocesowana."
    }))

    return (
        <Redirect to="/panel"/>
    )
}

export default PaymentPage
