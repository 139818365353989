import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useContext } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { DISPLAY_MULTIPLIER, LIMITS } from '../../../config/CalendarConfig';
import UIContext from '../../../context/UIContext';
import DataService from '../../../services/DataService';
import CalendarDayView from './CalendarDayView';
import CalendarNoOffersPlaceholder from './CalendarNoOffersPlaceholder';
import { calendarDisplayModes } from './CalendarPage';
import CalendarWeekView from './CalendarWeekView';

const Grid = styled.div`
    display: grid;
    grid-template-columns: 50px repeat(${props => props.columns}, 1fr);
    position: relative;
    @media (max-width: 1000px) {
        grid-template-columns: 50px repeat(${props => props.columns}, minmax(150px, 1fr));
    }
`

const HourTimeCell = styled.div`
    position: relative;
    height: calc(60px * ${DISPLAY_MULTIPLIER});
`

const HourTimeText = styled.div`
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
`

export const ShiftCell = styled.div`
    grid-row: 2;
    grid-column: ${props => props.column};
    border-right: 1px solid silver;
    max-height: ${props => props.visible ? 300 : 0}px;
    ${props => !props.visible && css`
        overflow: hidden;
    `}
    transition: all 1s linear;
`

export const FirstShiftCell = styled(ShiftCell)`
    border-right: none;
    grid-column: 1;
    align-items: center;
    justify-content: center;
    display: flex;
` 

export const FirstHoursColumn = styled.div`
    grid-row: 3;
    grid-column: 1;
`

function CalendarContent({ mode, currentDate, activeWorker }) {

    const { limits } = useContext(UIContext)
    
    const { 
        data: workers = [],
    } = useQuery({ queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })

    const isCopyShiftVisible = !_.isNil(activeWorker) && mode === calendarDisplayModes.WEEK

    const firstColumn = useMemo(() => {
        return (
            <>
                <HourTimeCell row={1}></HourTimeCell>
                <FirstShiftCell visible={isCopyShiftVisible}>
                    {/* <CalendarShiftCopy currentDate={currentDate}/> */}
                </FirstShiftCell>
                <FirstHoursColumn>
                {Array.from({ length: limits[1] - limits[0] }, (_, i) => i)
                    .map((hourIndex) => {
                        return (
                            <HourTimeCell>
                                <HourTimeText>{limits[0] + hourIndex}:00</HourTimeText>
                            </HourTimeCell>
                        )
                })}
                </FirstHoursColumn>
            </>
        )
    }, [isCopyShiftVisible, currentDate, limits])


    //@TODO: Divide to not to repeat
    switch (mode) {
        case calendarDisplayModes.DAY:
            return (
                <Grid columns={workers.length}>
                    <CalendarNoOffersPlaceholder/>
                    {firstColumn}
                    <CalendarDayView
                        mode={calendarDisplayModes.DAY}
                        currentDate={currentDate}
                    />
                </Grid>
            )
        case calendarDisplayModes.WEEK:
        default:
            return (
                <Grid columns={7}>
                    <CalendarNoOffersPlaceholder/>
                    {firstColumn}
                    <CalendarWeekView
                        mode={calendarDisplayModes.WEEK}
                        currentDate={currentDate} 
                    />
                </Grid>
            )
    }
}

const mapStateToProps = (state) => ({
    activeWorker: state.activeWorker 
})

export default connect(mapStateToProps)(CalendarContent)