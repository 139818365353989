import { useFormik } from 'formik'
import React, { useState, useMemo } from 'react'
import Submit from './Submit/Submit'
import styles from './Form.module.css';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import Fragment from './Fragment/Fragment';
import { Alert, Switch } from '@mui/material';
import { Row } from '../components/layout/common';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { useCallback } from 'react';
import DataService from '../services/DataService';
import { hideModal, showAlert } from '../actions/common.actions';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { useEffect } from 'react';
import LoadingDots from '../components/LoadingDots/LoadingDots';
import UtilHelper from '../helpers/UtilHelper';
import ProviderShiftHours from '../components/ProviderShiftHours/ProviderShiftHours';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function WorkerForm({ hide, history, _id }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const queryClient = useQueryClient()
    const isNew = _id == undefined

    const { 
        data: offers = [],
    } = useQuery({ queryKey: ['offers'], queryFn: DataService.getProviderOffers })

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    const availableOffers = useMemo(() => {
        return UtilHelper.parseOptions(offers, '_id', 'title')
    }, [offers])

    const WorkerSchema = useMemo(() => {
        return (
            Yup.object().shape({
                email: Yup.string().email().required(),
                firstname: Yup.string().min(3).max(50)
                    .when('isRegistered', {
                        is: false,
                        then: Yup.string().required()
                    }),
                lastname: Yup.string().min(3).max(50)
                    .when('isRegistered', {
                        is: false,
                        then: Yup.string().required()
                    }),
                offers: Yup.array(),
                schedule: Yup.object(),
                isSelected: Yup.boolean(),
                isRegistered: Yup.boolean()
              })
        )
    }, [])

    const onSubmit = useCallback(async (data) => {
        setLoading(true)
        try {
            if (isNew) {
                await DataService.addWorker(data)
            } else {
                await DataService.editWorker(data)
            }
            hide()
        } catch (e) {
            const { response } = e
            handleAddError(response?.data.message)
         
        } finally {
            setLoading(false)
        }
        queryClient.invalidateQueries({queryKey: ['workers']})
    }, [isNew])

    const handleAddError = (code) => {
        let text;
        switch (code) {
            case 'PROVIDER_WORKER_EXISTS':
                text = 'Pracownik z podanym adresem e-mail jest już dodany do Twojego salonu'
                break;
            case 'USER_NOT_EXISTS':
                text = 'Użytkownik o podanym adresie e-mail nie istnieje w systemie lub jest nieaktywny. Możesz skorzystać z przełącznika "Zarejestrowany", by dodać pracownika, który nie jest zarejestrowany.'
                break;
            case 'USER_ALREADY_EXISTS':
                text = 'Użytkownik już istnieje w systemie, jeśli chcesz dodać zarejstrowanego pracownika - użyj przełącznika "Zarejestrowany"'
                break
            default:
                text = 'Nie udało się dodać pracownika'
        }
        dispatch(showAlert({
            title: 'Wystąpił problem',
            type: 'error',
            text
        }))
    }
    
    const formik = useFormik({
        initialValues: {
            email: '',
            firstname: '',
            lastname: '',
            offers: [],
            schedule: {},
            isSelected: false,
            workerId: null,
            isRegistered: false
        },
        validationSchema: WorkerSchema,
        validateOnMount: true,
        onSubmit
    })

    const onScheduleChange = function(action) {
        const state = formik.values['schedule']
        let newState;
        const { weekDay, type, which, hour } = action
        switch (type) {
            case 'SET_OPEN':
                newState = { 
                    ...state, 
                    [weekDay]: { from: 7, to: 21 }  
                }
                break;
            case 'SET_CLOSED':
                const result = { ...state }
                delete result[weekDay]
                newState = result
                break;
            case 'CHANGE_HOUR':
                newState = { 
                    ...state, 
                    [weekDay]: { ...state[weekDay], [which]: hour }
                }
                break;
        }
        formik.setFieldValue('schedule', newState)
    }

    useEffect(() => {
        if (!_id) {
            setLoading(false)
            return;
        }
        DataService.getWorkerDetails(_id)
            .then(formik.setValues)
            .finally(setLoading)
    }, [])

    useEffect(() => {
        if (_id) {
            return;
        }
        const { schedule: initialSchedule } = provider
        formik.setFieldValue('schedule', initialSchedule)
    }, [])

    const { isRegistered } = formik.values

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Fragment
                text="Dane pracownika"
                icon={faUser}
                rightComponentVisible={true}
                RightComponent={() => {
                    return (
                        <Row vCentered marginBetween>
                            <small>Zarejestrowany</small>
                            <Switch
                                disabled={!isNew}
                                checked={isRegistered} 
                                onChange={e => formik.setFieldValue('isRegistered', e.target.checked)}
                            ></Switch>
                        </Row>
                    )
                }}
            >
                {!isRegistered && isNew && (
                    <Alert color="info" variant="outlined">
                        Jeśli dodasz pracownika, który nie jest zarejestrowany, zostanie utworzone dla niego konto,
                        a na podany adres e-mail zostanie wysłany e-mail z instrukcją jak dokończyć rejestrację.
                    </Alert>
                )}
                <div className={styles.section}>
                    <label>Adres e-mail</label>
                    <Input 
                        placeholder="Adres e-mail"
                        disabled={!isNew}
                        value={formik.values['email']}
                        error={Boolean(formik.errors['email']) && formik.dirty}
                        className={styles.textInput}
                        name="email"
                        id="email"
                        type="text"
                        onChange={formik.handleChange}
                    />
                </div>
                {!isRegistered && (
                    <>
                    <div className={styles.section}>
                        <label>Imię</label>
                        <Input 
                            placeholder="Imię"
                            disabled={!isNew}
                            value={formik.values['firstname']}
                            error={Boolean(formik.errors['firstname']) && formik.dirty}
                            className={styles.textInput}
                            name="firstname"
                            id="firstname"
                            type="text"
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={styles.section}>
                        <label>Nazwisko</label>
                        <Input 
                            value={formik.values['lastname']}
                            placeholder="Nazwisko"
                            disabled={!isNew}
                            error={Boolean(formik.errors['lastname']) && formik.dirty}
                            className={styles.textInput}
                            name="lastname"
                            id="lastname"
                            type="text"
                            onChange={formik.handleChange}
                        />
                    </div>
                    </>
                )}
            </Fragment>
            <Fragment 
                text="Konfiguracja"
                icon={faCogs}
            >
                <section className={styles.section}>
                    <label>Wykonywane usługi</label>
                    <Select
                        options={availableOffers}
                        placeholder="Wybierz usługi z listy"
                        isMulti
                        value={availableOffers.filter(offer => formik.values['offers'].includes(offer.value))}
                        onChange={values => formik.setFieldValue('offers', values.map(o => o.value))}
                    />
                </section>
                <section className={styles.section}>
                    <label>Godziny pracy</label>
                    <ProviderShiftHours
                        containerStyles={{ marginTop: 16, overflow: 'visible' }}
                        schedule={formik.values['schedule']}
                        onChange={onScheduleChange}
                    />
                </section>
            </Fragment>
            <Submit
                disabled={!formik.isValid}
                loading={loading}
                className={styles.submit} 
                label="Zapisz">
            </Submit>
        </form>
    )
}

export default WorkerForm
