import { useQuery } from "@tanstack/react-query"
import DataService from "../services/DataService"
import { useDispatch } from "react-redux"
import { setProvider } from "../actions/common.actions"
import useProvider from "../hooks/useProvider"
import { Tooltip } from '@material-ui/core';

export default function ProviderSwitch() {
    const { data: providers = [], isFetching, isFetched } = useQuery({ 
        queryFn: DataService.getProviders,
        queryKey: ['providers'],
        refetchOnWindowFocus: false
    })

    const provider = useProvider()

    const dispatch = useDispatch()

    const filteredProviders = providers.filter(p => p.isAdmin)

    const changeProvider = (e) => {
        const item = providers.find(p => p._id === e.target.id)
        dispatch(setProvider(item))
        window.location.reload()
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {filteredProviders.map((pr) => {
                const isActive = pr._id === provider._id
                return (
                    <>
                        <Tooltip title={pr.name}>
                            <img id={pr._id} onClick={changeProvider} src={pr.thumb} style={{ opacity: isActive ? 1.0 : 0.5, width: 30, height: 30, borderRadius: 15, marginLeft: 8, cursor: 'pointer' }} />
                        </Tooltip>
                    </>
                )
            })}
        </div>
    )
}