import React, { useState } from 'react'
import Select from 'react-select';
import UtilHelper from '../../helpers/UtilHelper';
import UtilService from '../../services/UtilService';

let timeout;

function Autocomplete({ formik, id, fetchFn, parseFn, selectCallback }) {
    const [options, setOptions] = useState([])

    const onQueryChange = (query) => {
        if (timeout) clearTimeout(timeout)
        if (query.length < 3) return;
        setTimeout(() => {
            fetchFn(query).then((results) => {
                setOptions(UtilHelper.parseOptions(results, '_id', parseFn))
            })
        }, 300);
    }

    return (
        <Select
            options={options}
            onInputChange={onQueryChange}
            getOptionValue={option => option.value}
            onChange={option => {
                formik.setFieldValue(id, option.value)
                selectCallback?.(option.value)
            }}
        />
    )
}

export default Autocomplete
