import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { pushModal } from '../../../actions/common.actions';
import { Row } from '../../../components/layout/common';
import PackagesForm from '../../../forms/PackagesForm';
import DataService from '../../../services/DataService';
import store from '../../../store';

let interval;

function CalendarPackageButton() {
    const { data : provider = {} } = useQuery({queryKey: ['provider'], queryFn: DataService.getProvider })
    const { activePackage } = provider
    const [overwrite, setOverwrite] = useState('')

    const click = useCallback(() => {
        store.dispatch(pushModal({
            header: 'Rozszerz pakiet',
            Component: PackagesForm,
            extraProps: {
                activePackage
            }
        }))
    }, [provider])

    useEffect(() => {
        if (activePackage?.index > 0) return;
        interval = setInterval(() => {
            setOverwrite(prev => prev ? '' : 'Rozszerz pakiet') 
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    return (
        <Button onClick={click} variant="outlined" fullWidth style={{ marginTop: 16 }}>
            <Row vCentered marginBetween>
                {/* <Avatar sx={{ width: 30, height: 30 }} src={provider.thumb} /> */}
                <span>{overwrite || _.truncate(activePackage?.name)}</span>
            </Row>
        </Button>
    )
}

export default CalendarPackageButton
