import { Backdrop } from "@material-ui/core"
import { CircularProgress } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Redirect } from "react-router-dom"
import useToken from "../hooks/useToken"
import DataService from "../services/DataService"

export default function AuthenticatedRoute({ render }) {
    const token = useToken()
    const { 
        isError, 
        isFetching,
        data: user
     } = useQuery({ 
         queryFn: DataService.getUser, 
         queryKey: ['user'],
         refetchOnWindowFocus: false,
         retry: false
    })
    if (!token) return <Redirect to="/login"></Redirect>
    if (isFetching) return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    )
    if (isError) return <Redirect to="/login"></Redirect>
    return render({user})
}