import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { pushModal, showAlert } from '../../../actions/common.actions'
import PageContainer from '../../../components/layout/PageContainer'
import StyledTableRow from '../../../components/layout/StyledTableRow'
import SmsPackagesForm from '../../../forms/SmsPackagesForm'
import DataService from '../../../services/DataService'
import store from '../../../store'
import Nav from '../Nav'

function SmsPage() {

    const { data : provider = {} } = useQuery({queryKey: ['provider'], queryFn: DataService.getProvider })
    const queryClient = useQueryClient()
    
    const { smsNotifications, sms } = provider

    const setSmsNotification = useCallback(async (key, checked) => {
        await DataService.editProvider({ smsNotifications: { ...smsNotifications, [key]: checked } })
        queryClient.invalidateQueries({ queryKey: ['provider'] })  
    }, [smsNotifications])

    const onBuyMore = useCallback(() => {
        store.dispatch(pushModal({
            Component: SmsPackagesForm,
            header: 'Kup SMS'
        }))
    }, [])

    return (
        <PageContainer>
            <Nav title="Powiadomienia SMS"></Nav>
            <Button onClick={onBuyMore} size="large" startIcon={<FontAwesomeIcon icon={faPlus} />} variant="outlined">Kup SMS</Button>
            <Alert style={{ marginBottom: 16, marginTop: 16 }} variant="standard" color="info">
                Liczba pozostałych SMS: <b>{sms}</b>
            </Alert>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Parametr</TableCell>
                            <TableCell align="center">Stan</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>
                                Wyślij SMS z przypomnieniem do klienta (24h przed)
                            </TableCell>
                            <TableCell align="center">
                                <Switch onChange={e => setSmsNotification('meetingReminder', e.target.checked)} checked={smsNotifications?.meetingReminder} />
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>
                                Wyślij SMS z potwierdzeniem wizyty
                            </TableCell>
                            <TableCell align="center">
                                <Switch onChange={e => setSmsNotification('meetingConfirmation', e.target.checked)} checked={smsNotifications?.meetingConfirmation} />
                            </TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    )
}

export default SmsPage
