import { FormControlLabel, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

function AlertDialog({ config, dispatch }) {
    const { text, type = 'confirmation', title, onConfirm, onReject, AdditionalContent = () => null } = config || {}
    const [open, setOpen] = React.useState(true);
    const [state, setState] = React.useState({})

    useEffect(() => {
        setOpen(config.visible)
    }, [config])

    const handleConfirm = () => {
        onConfirm(state)
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        onReject?.()
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
                <AdditionalContent 
                    state={state} 
                    setState={setState} 
                />
            </DialogContent>
            <DialogActions>
                {type === 'confirmation' && (
                    <Button onClick={handleConfirm}>
                        Potwierdź
                    </Button>
                )}                
                <Button onClick={handleClose} autoFocus>
                    Anuluj
                </Button>
            </DialogActions>
        </Dialog>
    );
}


const mapStateToProps = (state) => ({
    config: state.alert
})

export default connect(mapStateToProps)(AlertDialog)
