import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from '@material-ui/core'
import { OutlinedInput, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import DataService from '../services/DataService'
import StyledTableRow from './layout/StyledTableRow'

function ProductsTableForm({ updateProducts, productsArray }) {
    const { data: products = [] } = useQuery({ queryKey: ['products'], queryFn: DataService.getProducts })
    const selectRef = useRef()
    const availableProducts = useMemo(() => {
        return products.map(product => ({ label: product.name, value: product._id }))
    }, [products])

    const onSelect = useCallback((item) => {
        if (!item) return ;
        const selectedProduct = products.find(p => p._id === item.value)
        updateProducts([...productsArray, selectedProduct])
        selectRef.current.select.setValue(null)
    }, [availableProducts, productsArray, selectRef])

    const onRemove = useCallback((_id) => {
        updateProducts(productsArray.filter(p => p._id !== _id))
    }, [productsArray])
    
    const onEdit = useCallback((_id, key, val) => {
        updateProducts(productsArray.map(p => p._id === _id ? ({ ...p, [key]: Number(val) }) : p))
    }, [productsArray])

    return (
        <>
            <Select
                placeholder="Wybierz z listy by dodać nową pozycję"
                onChange={onSelect}
                ref={selectRef}
                options={availableProducts}
            />
            {productsArray.length > 0 && (
            <TableContainer style={{ marginTop: 16 }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableCell>Produkt</TableCell>
                        <TableCell width={110}>Ilość</TableCell>
                        <TableCell width={110}>Cena</TableCell>
                        <TableCell width={30}></TableCell>
                    </TableHead>
                    <TableBody>
                        {productsArray.map(product => {
                            return (
                                <StyledTableRow key={product._id}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>
                                        <OutlinedInput 
                                            type="number" 
                                            onChange={e => onEdit(product._id, 'count', e.target.value)} 
                                            value={String(product.count)}
                                        ></OutlinedInput>
                                    </TableCell>
                                    <TableCell>
                                        <OutlinedInput 
                                            type="number" 
                                            onChange={e => onEdit(product._id, 'price', e.target.value)} 
                                            value={String(product.price)}
                                        ></OutlinedInput>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => onRemove(product._id)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            )}
        </>
    )
}

export default ProductsTableForm
