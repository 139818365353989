import { showAlert } from "../actions/common.actions"
import store from "../store"
import _ from 'lodash'

const hours = []
for (let i = 5; i < 22; i++) {
    hours.push({
        label: `${i}:00`,
        value: i
    })
}

function parseToDict(array, labelField, valueField) {
    return array.map((item) => ({
        label: item[labelField],
        value: item[valueField]
    }))
}

function getStartHour(schedule) {
    if (!schedule || schedule.length === 0) return 7
    return _.min(_.values(schedule).map(row => row.from))
}

export default  {
    getHours: () => hours,
    parseToDict,
    getStartHour
}