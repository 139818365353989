import { faNoteSticky, faUser } from '@fortawesome/free-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import store from '../store';
import { pushModal } from '../actions/common.actions';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function ClientNoteForm({ hide, clientId, callback }) {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient()

    const onSubmit = useCallback(async (data) => {
        setLoading(true)
        try {
            await DataService.addNoteToClient(clientId, data.content)    
            callback?.()        
            hide()
        } catch (e) {
            const { response } = e
         
        } finally {
            setLoading(false)
        }
    }, [])

    const ClientNoteSchema = useMemo(() => {
        return (
            Yup.object().shape({
                content: Yup.string().min(3).max(1000).required()
              })
        )
    }, [])
    
    const formik = useFormik({
        initialValues: {
            content: '',
        },
        validationSchema: ClientNoteSchema,
        validateOnMount: true,
        onSubmit
    })

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Fragment
                text="Notatka"
                icon={faUser}
            >
                <div className={styles.section}>
                    <label>Treść</label>
                    <textarea 
                        placeholder="Treść notatki"
                        value={formik.values['content']}
                        style={{ height: 100 }}
                        error={Boolean(formik.errors['content']) && formik.dirty}
                        className={styles.textInput}
                        name="content"
                        id="content"
                        type="text"
                        onChange={formik.handleChange}
                    />
                </div>
                
            </Fragment>
            <Submit
                disabled={!formik.isValid}
                loading={loading}
                className={styles.submit} 
                label="Zapisz">
            </Submit>
        </form>
    )
}

export default ClientNoteForm
