import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CalendarChancesList from './CalendarChancesList'
import * as React from 'react';
import CalendarPendingMeetingsList from './CalendarPendingMeetingsList';
import { Badge, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import DataService from '../../../services/DataService';
import { Row } from '../../../components/layout/common';
import { pushModal } from '../../../actions/common.actions';
import ProviderForm from '../../../forms/ProviderForm';
import store from '../../../store';
import CalendarGapsList from './CalendarGapsList';

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);
  const { data: pendingMeetings = [] } = useQuery({ queryKey: ['pendingMeetings'], queryFn: DataService.getPendingMeetings })
  const pendingMeetingsCount = pendingMeetings.length
  const pendingMeetingsExists = pendingMeetingsCount > 0

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div style={{ marginTop: 32 }}>
      <Accordion expanded={expanded === 'requests'} onChange={handleChange('requests')}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
            <Badge 
                badgeContent={pendingMeetingsCount} 
                color={pendingMeetingsExists ? 'error' : 'default'}>
                Oczekujące prośby   
            </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <CalendarPendingMeetingsList />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'chances'} onChange={handleChange('chances')}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          Twoje okazje
        </AccordionSummary>
        <AccordionDetails>
          <CalendarChancesList />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'gaps'} onChange={handleChange('gaps')}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          Aktywne urlopy
        </AccordionSummary>
        <AccordionDetails>
          <CalendarGapsList />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}