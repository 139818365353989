import React from 'react'
import LoadingDots from '../../components/LoadingDots/LoadingDots'
import styled from 'styled-components';
import Button from '@mui/material/Button';

const Btn = styled.button`
    margin: 32px;
    &:disabled {
        background: silver;
    }
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px;
`

function Submit({ disabled, className, onSubmit, label, loading }) {

    if (loading) {
        return (
            <Container>
                <LoadingDots />
            </Container>
        )
    }

    return (
        <Button
            type="submit"
            variant="contained"
            disabled={disabled}
            style={{ margin: 32 }}
            className={className} 
        >{label}</Button>
    )
}

export default Submit
