import React from 'react'
import styled from 'styled-components';
import moment from 'moment';
import {
    DEFAULT_MEETING_COLOR,
    DISPLAY_MULTIPLIER,
} from '../../../config/CalendarConfig';
import { useDispatch } from 'react-redux';
import MeetingForm from '../../../forms/MeetingForm';
import { pushModal } from '../../../actions/common.actions';
import GapForm from '../../../forms/GapForm';


const GapItem = styled.div`
    z-index: -1;
    position: absolute;
    top: ${props => props.top * DISPLAY_MULTIPLIER}px;
    left: 0; right: 0;
    background: silver;
    height: ${props => props.duration * DISPLAY_MULTIPLIER}px;
    overflow: hidden;
    color: black;
    line-height: 14px;
    font-size: 12px;
    padding: 5px;
    transition: all 200ms linear;
    background-color: #ffffff;
    opacity: 0.8;
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(45deg, #b1b1b1 0, #b1b1b1 1px, #ffffff 0, #ffffff 50%);
`

function CalendarGap(props) {
    const { dateFrom, dateTo, top, worker, label } = props
    const dispatch = useDispatch()
    const start = moment(dateFrom)
    const end = moment(dateTo)
    const duration = end.diff(start, 'minutes')
    return (
        <GapItem
            onClick={(e) => {
                e.stopPropagation()
                dispatch(pushModal({
                    header: 'Edycja urlopu',
                    Component: GapForm,
                    item: props
                }))
            }}
            top={top}
            duration={duration}
        >
            <div style={{ padding: 5, background: 'rgba(255,255,255,0.7)' }}>
                <b>{label || 'Blokada czasu / urlop'}</b>
                <br/>
                <small>{worker?.firstname} {worker?.lastname}</small>
            </div>
        </GapItem>
    )
}

export default React.memo(CalendarGap)
