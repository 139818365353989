import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import styled from 'styled-components';
import { Column, Row } from '../../components/layout/common';
import _ from 'lodash';

const Container = styled.div`
    border-bottom: 1px solid #ededed;
    padding: 32px;
    h3 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: normal;
        color: grey;
    }
`

function Fragment({ icon, text, children, RightComponent, rightComponentVisible, containerStyles = {} }) {
    return (
        <Container>
            {text && <Row vCentered style={{ marginBottom: 8 }} stretched>
                <Row vCentered>
                    <FontAwesomeIcon color="grey" icon={icon} style={{ marginRight: 8 }} />
                    <h3 style={{ margin: 0 }}>{text}</h3>
                </Row>
                {rightComponentVisible && <RightComponent />}
            </Row>}
            <Column style={{...containerStyles}}>
                {children}
            </Column>
        </Container>
    )
}

export default Fragment
