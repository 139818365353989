import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { pushToast } from '../actions/common.actions';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import store from '../store';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function SmsPackagesForm({ hide }) {
    const [loading, setLoading] = useState()

    const { data: smsPackages = [] } = useQuery({ 
        queryKey: ['smsPackages'],
        queryFn: DataService.getSmsPackages 
    })
    
    const SmsPackagesSchema = useMemo(() => {
        return (
            Yup.object().shape({
                key: Yup.string().required()
            })
        )
    }, [])

    const formik = useFormik({
        initialValues: {
            key: null
        },
        validationSchema: SmsPackagesSchema,
        validateOnMount: true,
        onSubmit: async values => {
            try {
                setLoading(true)
                const redirectUri = await DataService.createOrder({ smsPackageKey: values.key, quantity: 1, type: 'SMS_PACKAGE', method: 'CARD' })
                window.location.replace(redirectUri)
                hide()
            } catch (e) {
                const { data } = e.response
                store.dispatch(pushToast({ type: 'error', text: data?.message }))
            } finally {
                setLoading(false)
            }
        },
    });

    const availableOffers = useMemo(() => {
        return UtilHelper.parseOptions(smsPackages, 'key', 'name')
    }, [smsPackages])

    const { key } = formik.values

    const priceData = useMemo(() => {
        const item = smsPackages.find(i => i.key === key)
        if (!item) {
            return null
        }
        return {
            netPrice: item?.priceFormatted,
            description: item?.description
        }
    }, [smsPackages, key])

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Fragment text="Szczegóły">
                <section className={styles.section}>
                    <label>Pakiet</label>
                    <Select
                        options={availableOffers}
                        placeholder="Wybierz pakiet"
                        value={UtilHelper.resolveValueFromDict(availableOffers, formik.getFieldProps('key').value)}
                        onChange={option => formik.setFieldValue('key', option.value)}
                    />
                </section>
            </Fragment>
            {priceData && <Fragment text="Cena netto">
                <section className={styles.section}>
                    <h1>{priceData.netPrice}</h1>
                    <small>{priceData.description}</small>
                </section>
            </Fragment>}
            <div className={styles.space}></div>
            <Submit
                disabled={!formik.isValid}
                label="Kup"
                loading={loading}
                className={styles.submit} 
                onSubmit={formik.handleSubmit}
            ></Submit>
        </form>
    )
}

export default SmsPackagesForm
