import { Backdrop } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import React from 'react'

function LoadingBackdrop() {
    return (
        <Backdrop
          sx={{ color: '#000' }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default LoadingBackdrop
