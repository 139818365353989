import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { availableUnits } from '../const/constants';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function ProductForm({ initialData, hide, segment }) {
    const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient()

    const ProductSchema = useMemo(() => {
        return (
            Yup.object().shape({
                name: Yup.string()
                  .required('Nazwa jest wymagana')
                  .min(3)
                  .max(50),
                price: Yup.number().required().min(0).max(10000),
                unit: Yup.string().required(),
              })
        )
    }, [])

    const formik = useFormik({
        initialValues: {
          _id: initialData?._id,  
          name: initialData?.name || '',
          count: initialData?.count || 1,
          unit: initialData?.unit || availableUnits[0].value,
          price: initialData?.price || 0,
        },
        validateOnMount: true,
        validationSchema: ProductSchema,
        onSubmit: async values => {
            setLoading(true)
            if (initialData) {
                await DataService.updateProduct(values)
            } else {
                await DataService.addProduct(values)
            }
            queryClient.invalidateQueries({queryKey: ['products']})
            setLoading(false)
            hide()
        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                <Fragment icon={faChartBar} text="Dane podstawowe">
                    <section className={styles.section}>
                        <label>Nazwa</label>
                        <Input 
                            placeholder="max. 50 znaków"
                            error={Boolean(formik.errors['name']) && formik.dirty}
                            className={styles.textInput}
                            name="name"
                            id="name"
                            value={formik.getFieldProps('name').value}
                            onChange={formik.handleChange}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Cena</label>
                        <Input 
                            placeholder="max. 50 znaków"
                            error={Boolean(formik.errors['price']) && formik.dirty}
                            className={styles.textInput}
                            name="price"
                            id="price"
                            type="number"
                            value={formik.getFieldProps('price').value}
                            onChange={formik.handleChange}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Jednostka</label>
                        <Select
                            name="unit"
                            id="unit"
                            options={availableUnits}
                            onChange={option => formik.setFieldValue('unit', option.value)}
                            value={UtilHelper.resolveValueFromDict(availableUnits, formik.getFieldProps('unit').value)}
                        >
                        </Select>
                    </section>
                </Fragment>
                <div className={styles.space}></div>
                <Submit
                    disabled={!formik.isValid}
                    label="Zapisz"
                    loading={loading}
                    className={styles.submit} 
                ></Submit>
        </form>
    )
}

export default ProductForm
