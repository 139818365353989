import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setActiveWorker } from '../../../actions/common.actions';
import { RoundItem, Row } from '../../../components/layout/common';
import DataService from '../../../services/DataService';

const Container = styled(RoundItem)`
    position: relative;
    &:hover {
        box-shadow: 0 0 0 #000;
        & > div {
            max-height: 1000px;
            opacity: 100%;
        }
    }
`

const RoundThumb = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 15px;
`

const List = styled.div`
    max-height: 0px;
    opacity: 0;
    right: 0;
    overflow: hidden;
    transition: all 100ms linear;
    position: absolute;
    top: 100%;
    z-index: 2;
    padding: 16px;
    background: white;
    box-shadow: 0 0 12px rgba(0,0,0,0.15);
    a {
        &:hover {
            background: silver;
        }
    }
`

function CalendarWorkersDropdown({ activeWorker, hidden }) {

    const dispatch = useDispatch()
    const { data: workers = [], isLoading } = useQuery({queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })

    if (hidden) {
        return <div></div>
    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Pracownik</InputLabel>
            <Select
                size="small"
                value={activeWorker?._id || null}
                label="Pracownik"
                onChange={(e) => {
                    const selectedWorker = workers.find(w => w._id === e.target.value)
                    dispatch(setActiveWorker(selectedWorker || null))
                }}
            >
                <MenuItem key={'null'} value={null}>
                    <Row vCentered marginBetween>
                        <Avatar>
                        </Avatar>
                        Wszyscy
                    </Row>
                </MenuItem>
                {workers.map(worker => (
                    <MenuItem key={worker._id} value={worker._id}>
                        <Row vCentered marginBetween>
                            <Avatar 
                                src={worker.thumb}
                            >
                                {worker.firstname[0]}{worker.lastname[0]} 
                            </Avatar> {worker.firstname}
                        </Row>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const mapStateToProps = (state) => ({
    activeWorker: state.activeWorker
})

export default connect(mapStateToProps)(CalendarWorkersDropdown)
