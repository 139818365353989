import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';
import { pushModal } from "../actions/common.actions";
import store from "../store";
import ProviderNotifications from "./ProviderNotifications";

const Container = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid silver;
    border-radius: 15px;
    background: silver;
    cursor: pointer;
`

function NotificationsIndicator(props) {

    const onClick = () => {
        store.dispatch(pushModal({
            Component: ProviderNotifications 
        }))
    }

    return (
        <Container onClick={onClick}>
            <FontAwesomeIcon color="white" icon={faMessage} />
        </Container>
    )
}

export default NotificationsIndicator