import styled from 'styled-components'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import DataService from '../../services/DataService'
import { Column, Row } from '../../components/layout/common'
import { Avatar, Button, IconButton, Typography } from '@mui/material'
import LoadingBackdrop from '../../components/LoadingBackdrop'
import { setProvider } from '../../actions/common.actions'
import { Redirect } from 'react-router-dom'
import UtilHelper from '../../helpers/UtilHelper';
import { useState, useMemo, useEffect } from 'react';
import Logout from '../../components/Logout/Logout';

const LogoutContainer = styled.div`
    position: absolute;
    top: var(--space);
    right: var(--space);
`

const Container = styled(Column)`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0; left: 0; right: 0; top: 0;
`

const Logo = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
`

export const getFormattedAddress = (displayAddress, isAddressHidden = false) => (displayAddress || '').replace(/\d{2}-\d{3}/, '').replace(', Polska', '')

function ProvidersPage({ provider, dispatch }) {
    const { data: providers = [], isFetching, isFetched } = useQuery({ 
        queryFn: DataService.getProviders,
        queryKey: ['providers'],
        refetchOnWindowFocus: false
    })

    const filteredProviders = providers.filter(p => p.isAdmin)
    
    const availableProviders = useMemo(() => UtilHelper.parseOptions(filteredProviders, '_id', (p) => {
        return `${p.name} (${getFormattedAddress(p.address.displayAddress)})`
    }), [filteredProviders])
    const [selected, setSelected] = useState()

    useEffect(() => {
        if (availableProviders.length === 0) return;
        if (selected) return;
        setSelected(availableProviders[0].value)
    }, [availableProviders])

    if (isFetching || !isFetched) {
        return <LoadingBackdrop />
    }

    if (providers.length == 0) {
        return <Redirect to="/nowy"></Redirect>
    }

    if (provider) {
        return <Redirect to="/panel"></Redirect>
    }

    const selectedItem = filteredProviders.find(p => p._id === selected)

    return (
        <>
        <Container>
            <LogoutContainer>
                <Logout />
            </LogoutContainer>
            <Logo src="logosoc.png" />
            <Row vCentered marginBetween>
                <Column>
                    <Select
                        styles={{
                            control: provided => ({
                                ...provided,
                                height: 40,
                                width: 200,
                                borderRadius: 10,
                                border: '1px solid #ededed'
                            }),
                            valueContainer: provided => ({
                                ...provided,
                            })
                        }}
                        options={availableProviders}
                        placeholder="Wybierz salon"
                        value={UtilHelper.resolveValueFromDict(availableProviders, selected)}
                        onChange={option => setSelected(option.value)}
                    />
                    <Button
                        disabled={!selected}
                        style={{ marginTop: 16 }}
                        onClick={() => {
                            dispatch(setProvider(selectedItem))
                        }}
                        variant="contained" 
                    >Dalej</Button>
                </Column>
            </Row>
        </Container>
        </>
    )
}

const avatarStyles = {
    marginBottom: 8,
    boxShadow: '0 0 12px 0 rgba(255,255,255,0.3)'
}

const mapStateToProps = (state) => ({
    provider: state.provider
})

export default connect(mapStateToProps)(ProvidersPage)
