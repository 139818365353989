import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, IconButton } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { pushModal, showAlert } from '../../../actions/common.actions';
import { Column, Row } from '../../../components/layout/common';
import GapForm from '../../../forms/GapForm';
import UtilHelper from '../../../helpers/UtilHelper';
import DataService from '../../../services/DataService';
import store from '../../../store';

const GapItem = styled(Row)`
    margin-bottom: 16px;
    cursor: pointer;
    small {
        font-size: 12px;
    }
    
    b {
        font-size: 12px;
        display: inline;
    }

    span {
        font-size: 12px;
        font-style: italic;
    }
`

function resolveWorker(workers, workerId) {
    return workers.find(w => w._id === workerId)
}

function resolveTimeRangeLabel(from, to) {
    const fullFormat = 'D MMM, H:mm'
    const dateFrom = moment(from)
    const dateTo = moment(to)
    if (dateFrom.isSame(dateTo, 'date')) {
        return `${dateFrom.format(fullFormat)} - ${dateTo.format('H:mm')}`
    } else {
        return `${dateFrom.format(fullFormat)} - ${dateTo.format(fullFormat)}`
    }
}

function CalendarGapsList() {

    const { data: gaps = [] } = useQuery({ 
        queryKey: ['gaps'], 
        queryFn: DataService.getGaps,
        refetchOnWindowFocus: false,
    })

    const { 
        data: workers = [],
    } = useQuery({ queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })

    const queryClient = useQueryClient()

    const mergedGaps = useMemo(() => {
        const merged = gaps.reduce((acc, curr) => {
            if (!acc.find(gap => gap._id === curr._id)) {
                return [...acc, curr]
            } else {
                return acc.map(gap => gap._id === curr._id ? ({...gap, dateTo: curr.dateTo }) : gap)
            }
        }, [])
        return _.orderBy(merged, ['dateFrom'], ['asc']).filter(g => moment(g.dateTo).isAfter(moment()))
    }, [gaps])

    const onAdd = useCallback(() => {
        store.dispatch(pushModal({
            Component: GapForm,
            header: 'Dodaj urlop'
        }))
    }, [])

    const onDelete = useCallback((gap) => {
        store.dispatch(showAlert({
            text: 'Czy na pewno chcesz usunąć ten urlop?',
            title: 'Usuwanie urlopu',
            onConfirm: async () => {
                await DataService.removeGap(gap)
                queryClient.invalidateQueries({ queryKey: ['gaps']})
            }
        }))
    }, [])

    return (
        <>
        <Button 
            fullWidth
            style={{ marginBottom: 16 }} 
            variant="outlined"
            onClick={onAdd}
        >Nowy urlop</Button>
        {mergedGaps.map((gap) => {
            const { worker: workerId, dateFrom, dateTo, _id, label } = gap
            const worker = resolveWorker(workers, workerId)
            return (
                <GapItem marginBetween vCentered>
                    <Avatar src={worker?.thumb}>
                        {UtilHelper.getFirstLetters(worker)}
                    </Avatar>
                    <Column>
                        <b>{worker?.firstname}</b><span>{label || ''}</span>
                        <small>{resolveTimeRangeLabel(dateFrom, dateTo)}</small>
                    </Column>                    
                    <IconButton
                        onClick={() => onDelete(gap)}
                        variant="text"
                        size="small"
                        color="error"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} color="red"/>  
                    </IconButton>
                </GapItem>
            )
        })}
        </>
    )
}

export default CalendarGapsList
