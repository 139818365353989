import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import DataService from '../../../services/DataService'
import styled from 'styled-components';
import { setCurrentDate, showAlert } from '../../../actions/common.actions';
import { Row, Column } from '../../../components/layout/common';
import { Avatar, ToggleButtonGroup, ToggleButton } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PendingMeetingItem = styled(Column)`
    margin-bottom: 16px;
    cursor: pointer;
    small {
        font-size: 12px;
    }
    
    b {
        font-size: 12px;
    }
`

function CalendarPendingMeetingsList() {

    const { data: pendingMeetings = [] } = useQuery({ queryKey: ['pendingMeetings'], queryFn: DataService.getPendingMeetings })
    const queryClient = useQueryClient()
    const dispatch = useDispatch()

    const navigateToMeeting = useCallback((date) => () => {
        dispatch(setCurrentDate(new Date(date)))
    }, [])

    const confirmMeeting = useCallback((_id) => () => {
        dispatch(showAlert({
            text: 'Czy na pewno chcesz potwierdzić tę wizytę?',
            title: 'Umów wizytę',
            onConfirm: async () => {
                await DataService.confirmMeeting({ _id })
                queryClient.invalidateQueries({ queryKey: ['meetings']})
                queryClient.invalidateQueries({queryKey: ['pendingMeetings']})
            }
        }))
    }, [])

    const deleteMeeting = useCallback((_id) => () => {
        dispatch(showAlert({
            text: 'Czy na pewno chcesz anulować tę wizytę?',
            title: 'Anuluj wizytę',
            onConfirm: async () => {
                await DataService.removeVisit(_id)
                queryClient.invalidateQueries({ queryKey: ['meetings']})
                queryClient.invalidateQueries({queryKey: ['pendingMeetings']})
            }
        }))
    }, [])

    return (
        pendingMeetings.map(({ _id, offer, date, clientName, clientPhone }) => {

            return (
                <PendingMeetingItem>
                    <Row vCentered onClick={navigateToMeeting(date)}>
                        <Avatar style={styles.avatar}></Avatar>
                        <Column>
                            <b>{moment(date).format('D MMM H:mm')}</b>
                            <small>{_.truncate(offer.title)}</small>
                            <small>{clientPhone} {clientName}</small>
                        </Column>
                    </Row>
                    <ToggleButtonGroup
                        style={styles.toggleGroup}
                        fullWidth
                        exclusive
                        size={'small'}
                        aria-label="Platform"
                        >
                        <ToggleButton onClick={confirmMeeting(_id)}>
                            <FontAwesomeIcon style={styles.marginRight} icon={faCheck} color="green"/>
                            Umów
                        </ToggleButton>
                        <ToggleButton onClick={deleteMeeting(_id)}>
                            <FontAwesomeIcon style={styles.marginRight} icon={faTimes} color="red"/>
                            Odrzuć
                        </ToggleButton>
                    </ToggleButtonGroup>
                </PendingMeetingItem>
            )
        })
    )
}

const styles = {
    marginRight: {
        marginRight: 8
    },
    toggleGroup: {
        marginTop: 5
    },
    avatar: {
        width: 35,
        height: 35,
        marginRight: 8
    }
}

export default CalendarPendingMeetingsList
