import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import OneSignal from 'react-onesignal';

export default async function useOneSignal(user) {
    const queryClient = useQueryClient()
    useEffect(() => {
        OneSignal.init({ appId: '25dea995-46b3-4dc5-9264-5778b398b3f5', allowLocalhostAsSecureOrigin: true })
            .then(() => {
                OneSignal.showSlidedownPrompt().then(() => {
                    OneSignal.setExternalUserId(user.email)
                })

                OneSignal.on('notificationDisplay', () => {
                    queryClient.invalidateQueries({queryKey: ['meetings']})
                })
            })
    }, [])
}