import { Alert } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import DataService from '../services/DataService'

function PaidModule({ render, moduleKey }) {

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    if (!provider.activePackage?.options?.includes?.(moduleKey)) {
        return (
            <Alert variant="outlined" color="info">
                Ten moduł jest niedostępny w twoim obecnym planie {provider.activePackage?.name}. By włączyć ten moduł rozszesz swój plan.
            </Alert>
        )
    }

    return render()
}

export default PaidModule
