import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { pushModal } from '../../actions/common.actions';
import Logout from '../../components/Logout/Logout';
import ProviderSwitch from '../../components/ProviderSwitch';
import PackagesForm from '../../forms/PackagesForm';
import useOneSignal from '../../hooks/useOneSignal';
import store from '../../store';
import ClientsPage from './ClientsPage/ClientsPage';
import ConfigPage from './ConfigPage/ConfigPage';
import InvoicesPage from './InvoicesPage/InvoicesPage';
import OffersPage from './OffersPage/OffersPage';
import './PanelPage.module.css';
import ProductsPage from './ProductsPage/ProductsPage';
import ReportPage from './ReportPage/ReportPage';
import SmsPage from './SmsPage/SmsPage';
import WorkersPage from './WorkersPage/WorkersPage';
import CalendarPage from './calendar/CalendarPage';

function PanelPage({ user }) {

    useOneSignal(user)

    const { provider } = store.getState()
    const sidebarToggle = useRef()

    const navConfig = [
        { url: '/panel/kalendarz', title: 'Kalendarz', img: 'icons/calendar.png', alwaysVisible: true },
        { url: '/panel/uslugi', title: 'Usługi', img: 'icons/services.png' },
        { url: '/panel/pracownicy', title: 'Pracownicy', img: 'icons/workers_2.png' },
        { url: '/panel/klienci', title: 'Klienci', img: 'icons/clients_1.png', alwaysVisible: true  },
        { url: '/panel/finanse', title: 'Finanse', img: 'icons/finance.png' },
        { url: '/panel/konfiguracja', title: 'Konfiguracja', img: 'icons/configuration.png' },
        { url: '/panel/produkty', title: 'Produkty', img: 'icons/products.png' },
        { url: '/panel/faktury', title: 'Faktury', img: 'icons/invoices_2.png' },
        { url: '/panel/powiadomienia', title: 'Powiadomienia SMS', img: 'icons/sms_1.png' }
    ]

    useEffect(() => {
        //@todo: move to hook
        if (!provider || localStorage.getItem('tt_packages_shown') || provider?.activePackage?.index > 0) {
            return false;
        }
        store.dispatch(pushModal({
            header: 'Rozszerz pakiet',
            Component: PackagesForm,
            extraProps: {
                activePackage: provider?.activePackage
            }
        }))
        localStorage.setItem('tt_packages_shown', true)
    }, [])

    useEffect(() => {
        sidebarToggle.current?.addEventListener?.('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });
    }, [])

    if (!provider) return <Redirect to="/salony"/>

    return (
        <div className="d-flex" id="wrapper">
            <div className="border-end" id="sidebar-wrapper">
                <div className="sidebar-heading">
                    <img style={{ width: 40, height: 'auto' }} alt="logo" src="logowhite.png"/>
                </div>
                <div className="list-group list-group-flush">
                    {navConfig.filter(item => !provider.isReception || item.alwaysVisible).map(link => {
                        return (
                            <Link className="tt-list-group-item" to={link.url}>
                                <img alt={link.title} src={link.img}></img>
                                {link.title}
                            </Link>
                        )
                    })}
                    
                    
                </div>
            </div>
            <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                    <div className="container-fluid">
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                            <button ref={sidebarToggle} className="btn btn-primary" id="sidebarToggle">
                                <FontAwesomeIcon icon={faBars}  color="white" />
                            </button>
                            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button> */}
                            <ProviderSwitch />
                        </div>
                        <div style={{ justifyContent: 'flex-end' }} className="collapse navbar-collapse" id="navbarSupportedContent">
                            <Logout user={user}/>
                        </div>
                    </div>
                </nav>
                <div>
                    <Switch>
                        <Route path='/panel/pracownicy' component={WorkersPage} />
                        <Route path='/panel/uslugi' component={OffersPage} />
                        <Route path='/panel/finanse' component={ReportPage} />
                        <Route path='/panel/klienci' component={ClientsPage}></Route>
                        <Route path='/panel/konfiguracja' component={ConfigPage}></Route>
                        <Route path='/panel/produkty' component={ProductsPage}></Route>
                        <Route path='/panel/faktury' render={() => <InvoicesPage user={user}/>}></Route>
                        <Route path='/panel/powiadomienia' render={() => <SmsPage user={user}/>}></Route>
                        <Route path='/panel/kalendarz' component={CalendarPage} exact />
                        <Redirect to="/panel/kalendarz"></Redirect>
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default PanelPage
