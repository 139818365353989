import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Column } from '../../../components/layout/common';
import CalendarDay from './CalendarDay';

const HeaderTile = styled.div`
    padding: 16px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    background: #ededed;
    border-right: 1px solid silver;
    align-items: flex-end;
    line-height: 16px;
`

const Income = styled.small`
    padding: 5px;
    font-size: 10px;
    border: 1px solid var(--main-color);
    border-radius: 10px;
    color: var(--main-color);
`

function CalendarWeekView({ currentDate, mode, activeWorker }) {
    const week = useMemo(() => {
        const start = moment(currentDate).startOf('isoWeek')
        return Array.from({ length: 7 }, (_, i) => i).map((dayIndex) => {
            const date = moment(start).add(dayIndex, 'days')
            return (
                <CalendarDay
                    dayIndex={dayIndex}
                    date={date}
                    isShiftVisible={!_.isNil(activeWorker)}
                    worker={activeWorker}
                    mode={mode}
                    HeaderComponent={({ income }) => {
                        return (
                            <HeaderTile>
                                <Column>
                                    <small>{date.format('ddd')}</small>
                                    <br/>
                                    <b>{date.format('D.MM')}</b>
                                </Column>
                                {/* {income > 0 &&<Income>{income}zł</Income>} */}
                            </HeaderTile>
                        )
                    }}
                />
            )
        })
    }, [currentDate, activeWorker])

    return (
        <>
        {week}
        </>
    )
}

const mapStateToProps = (state) => ({
    activeWorker: state.activeWorker 
})

export default connect(mapStateToProps)(CalendarWeekView)