export const SET_MODAL = 'SET_MODAL'
export const SET_PROVIDER = 'SET_PROVIDER'
export const SET_MEETINGS = 'SET_MEETINGS'
export const SET_ACTIVE_DAY = 'SET_ACTIVE_DAY'
export const SET_ACTIVE_WEEK = 'SET_ACTIVE_WEEK'
export const SET_SEGMENTS = 'SET_SEGMENTS'
export const SET_CHANCES = 'SET_CHANCES'
export const SET_PROVIDERS = 'SET_PROVIDERS'
export const SET_CHARITIES = 'SET_CHARITIES'
export const SET_USER = 'SET_USER'
export const UPDATE_MEETINGS_VERSION = 'UPDATE_MEETINGS_VERSION'
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE'
export const SET_ACTIVE_WORKER = 'SET_ACTIVE_WORKER'
export const PUSH_MODAL = 'PUSH_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
