import _ from 'lodash'
import { showAlert } from '../actions/common.actions'
import store from '../store'
import moment from 'moment'
import { areIntervalsOverlapping } from 'date-fns'

export default {
    isSameFilter: function(id, path = null) {
        return (item) => (id === (path ? _.get(item, path) : item))
    },
    parseOptions: (options, idKey, label) => options.map((o) => ({ 
        value: o[idKey], 
        label: typeof label === 'string' ? o[label] : label(o) 
    })),
    resolveValueFromDict: (array, value) => Array.isArray(value) ? array.filter(option => value.includes(option.value)) : (array.find(option => option.value === value) || null),
    withConfirmation: (fn, config = {}) => {
        return () => {
            store.dispatch(showAlert({
                text: 'Czy na pewno chcesz wykonać tę akcję?',
                title: 'Potwierdzenie',
                ...config,
                onConfirm: fn
            }))
        }
    },
    getFirstLetters: (worker) => {
        if (!worker) return null
        const { firstname, lastname } = worker
        return firstname[0] + lastname[0]
    },

    //@todo: find better solutions
    groupMeetings: (meetings) => {
        function isConcurrent(meeting, m) {
            return areIntervalsOverlapping({
                start: new Date(meeting.date), 
                end: moment(meeting.date).add(meeting.duration, 'minute').toDate()
            }, {
                start: new Date(m.date), 
                end: moment(m.date).add(m.duration, 'minute').toDate()
            })
        }

        const groups = []
        _.sortBy(meetings, ['date'], ['asc']).forEach((item, index) => {
            const overlaps = meetings.filter((meeting) => item._id !== meeting._id && isConcurrent(item, meeting))
            const foundGroup = groups.find(g => g.includes(item))
            if (foundGroup) {
                foundGroup.push(..._.difference(overlaps, foundGroup))
            } else {
                groups.push([item, ...overlaps])
            }
        })
        groups.forEach((items) => {
            items.forEach((item, index) => {
                item.groupCount = items.length
                item.groupIndex = index + 1
            })
        })
    }
}