import {
    useQuery,
    useQueryClient
} from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { connect, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import { setCurrentDate } from '../../../actions/common.actions';
import { Row } from '../../../components/layout/common';
import DataService from '../../../services/DataService';
import CalendarAccordion from './CalendarAccordion';
import CalendarAddBtn from './CalendarAddBtn';
import CalendarContent from './CalendarContent';
import CalendarEditProviderBtn from './CalendarEditProviderBtn';
import CalendarModeSwitch from './CalendarModeSwitch';
import CalendarPackageButton from './CalendarPackageButton';
import CalendarWorkersDropdown from './CalendarWorkersDropdown';
import UIContext from '../../../context/UIContext'
import UtilService from '../../../services/UtilService';
import CalendarClientsVisitsDropdown from './CalendarClientsVisitsDropdown';
import CalendarWeekAdd from './CalendarWeekAdd';
import { Button } from '@mui/material';


const Wrapper = styled.section`
    display: flex;
    flex-direction: row;
`

const Left = styled.section`
    width: 280px;
    padding: 16px;
    border-right: 1px solid silver;
`

const Right = styled.section`
    flex: 1;
    overflow-x: scroll;
`

export const calendarDisplayModes = { WEEK: 'week', DAY: 'day' }

function CalendarPage({ currentDate, updateDate, loading }) {
    const [dragData, setDragData] = useState({ dropArea: null, dragElement: null })
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const { isFetching } = useQuery({ queryKey: ['meetings'], queryFn: DataService.getMeetings })
    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider, 
    })
    const limits =  [UtilService.getStartHour(provider.schedule), 22]

    const [mode, setMode] = useState(calendarDisplayModes.WEEK)

    useEffect(() => {
        if (provider.customConfig?.defaultDayMode) {
            setMode(calendarDisplayModes.DAY)
        }
    }, [provider.customConfig?.defaultDayMode])

    const isDayMode = mode === calendarDisplayModes.DAY

    const onDateChange = useCallback(async (date) => {
        await updateDate(date)
        if (!moment(date).isSame(currentDate, 'isoWeek')) {
            queryClient.invalidateQueries({queryKey: ['meetings']})
            queryClient.invalidateQueries({queryKey: ['shift']})
            queryClient.invalidateQueries({queryKey: ['gaps']})
        }
    }, [currentDate])

    const changeWeek = (value) => {
        const newDate = moment(currentDate).add(value, 'week').toDate()
        onDateChange(newDate)
    }

    const goToToday = () => {
        const today = moment().startOf('date').toDate()
        onDateChange(today)
    }

    return (
        <Wrapper>
            <Left>
                <Calendar
                    key={currentDate.toISOString()}
                    // tileClassName="tt-calendar-tile"
                    onClickDay={onDateChange}
                    value={currentDate} />
                <Button style={{ width: '100%', marginTop: 8 }} color="primary" variant="outlined" onClick={goToToday}>Dziś</Button>
                <CalendarWeekAdd changeWeek={changeWeek}/>
                <CalendarEditProviderBtn/>
                <CalendarPackageButton/>
                <CalendarAccordion />
            </Left>
            <Right>
                <UIContext.Provider value={{ limits, dragData, setDragData, onDateChange }}>
                    <Row stretched vCentered>
                        <Row vCentered>
                            <CalendarModeSwitch mode={mode} setMode={setMode} />
                            <CalendarClientsVisitsDropdown />
                        </Row>
                        {(isFetching || loading) && <ClipLoader size={38} color="var(--main-color)" />}
                        <CalendarWorkersDropdown hidden={isDayMode} />
                    </Row>
                    <CalendarContent
                        currentDate={currentDate} 
                        mode={mode}
                    />
                </UIContext.Provider>
            </Right>
            <CalendarAddBtn />
        </Wrapper>
    )
}

const mapStateToProps = (state) => ({
    currentDate: state.currentDate,
    loading: state.loading
})

const mapDispatchToProps = (dispatch) => ({
    updateDate: (date) => dispatch(setCurrentDate(date))
})


export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage)

