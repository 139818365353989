import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { hideModal } from '../../actions/common.actions';
import { Column } from '../layout/common';

const SLIDE_TIME = 300;

export const Container = styled.div`
    h1 {
        margin-top: 0;
    }
    overflow: scroll;
    transition: all ${SLIDE_TIME}ms ease-in-out;
    width: ${props => props.visible ? '500px' : 0};
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255, 100%);
    box-shadow: 0 0 12px rgba(0,0,0,15%);
    opacity: ${props => props.visible ? 1 : 0};
    backdrop-filter: blur(20px);
    z-index: ${props => props.visible ? 1001 : -1000};
    display: flex;
    flex: 1;
    flex-direction: column;
`

const Header = styled.div`
    padding: 16px 32px;
    display: flex;
    /* border-bottom: 1px solid silver; */
    background: #efefef;
    font-size: 16px;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > button {
        border: none;
    }
`

function Modal({ id, header, Component, extraProps, item }) {
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    
    useEffect(() => {
        setVisible(true)
    }, [])

    const hide = () => {
        setVisible(false)
        setTimeout(() => {
            dispatch(hideModal(id))
        }, SLIDE_TIME);
    }

    const closeOnEscape = useCallback((e) => {
        if (e.key !== 'Escape') {
            return ;
        }
        hide()
    }, [dispatch])

    const closeOnClickOutside = useCallback((e) => {
        // dispatch(setModal(null))
    }, [])

    useEffect(() => {
        document.addEventListener('keyup', closeOnEscape)
        return () => document.removeEventListener('keyup', closeOnEscape)
    }, [])

    return (
        <Container visible={visible}>
            <Header>
                <span>{header}</span>
                <Button onClick={hide} startIcon={<FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>} variant="text">Powrót</Button>
            </Header>
            <Column style={{ flex: 1 }}>
                <Component initialData={item} {...extraProps} hide={hide} />
            </Column>
        </Container>
    )
}

export default Modal
