import {
    useQuery
} from '@tanstack/react-query';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { pushModal } from '../../../actions/common.actions';
import { DISPLAY_MULTIPLIER } from '../../../config/CalendarConfig';
import UIContext from '../../../context/UIContext';
import GapForm from '../../../forms/GapForm';
import MeetingForm from '../../../forms/MeetingForm';
import DateHelper from '../../../helpers/DateHelper';
import { default as MeetingHelper, default as UtilHelper } from '../../../helpers/UtilHelper';
import { useEventListener } from '../../../hooks/useEventListener';
import DataService from '../../../services/DataService';
import { ShiftCell } from './CalendarContent';
import CalendarGap from './CalendarGap';
import CalendarHourCell from './CalendarHourCell';
import CalendarMeeting from './CalendarMeeting';
import CalendarShiftPicker from './CalendarShiftPicker';
import CalendarNowLine from './CalendarNowLine';

const HeaderCell = styled.div`
    grid-column: ${props => props.column};
    grid-row: 1;
`

const HoursColumn = styled.div`
    grid-row: 3;
    grid-column: ${props => props.column};
    position: relative;    
    min-width: 150px;
`

export default function CalendarDay({ HeaderComponent, date, dayIndex, worker, isShiftVisible}) {

    const { data: meetings } = useQuery({ queryKey: ['meetings'], queryFn: DataService.getMeetings })
    const { data: gaps } = useQuery({ queryKey: ['gaps'], queryFn: DataService.getGaps, refetchOnWindowFocus: false })
    const dispatch = useDispatch()

    const { dragData } = useContext(UIContext)

    const { 
        data: schedule = [],
    } = useQuery({ queryKey: ['shift'], queryFn: DataService.getWorkersScheduleForWeek, refetchOnWindowFocus: false })

    const { limits } = useContext(UIContext)
    const start = limits[0], end = limits[1]

    const mouseUpCallback = (e) => {
        if (!window.potentialGap) { return ; }
        const { element, startDate, endDate, duration, worker: gapWorker } = window.potentialGap
        const selectedWorker = gapWorker
        element.remove()
        delete window['potentialGap']
        if (!duration || duration < 0) { 
            dispatch(pushModal({ 
                header: 'Dodawanie wizyty',
                Component: MeetingForm,
                extraProps: {
                    date: startDate.toDate(),
                    workerId: gapWorker?._id
                }
            }))
            return ;
        }
        dispatch(pushModal({
            Component: GapForm,
            header: 'Dodaj urlop/blokadę',
            extraProps: {
                initialData: {
                    dateFrom: startDate.toDate(),
                    dateTo: endDate.toDate(),
                    worker: selectedWorker,
                }
            }
        }))
    }

    const mouseMoveCallback = (e) => {
        if (!window.potentialGap) { return; }
        const { startDate, element } = window.potentialGap
        const quartersCount = Math.floor((e.pageY - (element.getBoundingClientRect().top +  window.scrollY)) / (15 * DISPLAY_MULTIPLIER))
        if (quartersCount > 0) {
            window.downTriggered = true;
        }
        const duration = quartersCount * 15
        const date = startDate.clone().add(duration, 'm')
        const endOfDay = startDate.clone().endOf('day')
        if (date.isBefore(endOfDay, 'h')) {
          window.potentialGap.endDate = date
          window.potentialGap.duration = duration
          element.style.height = duration * DISPLAY_MULTIPLIER + 'px'
        }
    }

    useEventListener('mousemove', mouseMoveCallback)
    useEventListener('mouseup', mouseUpCallback)

    const daySchedule = useMemo(() => {
        return schedule
            .filter(DateHelper.isSameDayFilter(date, 'date'))
            .find(UtilHelper.isSameFilter(worker?._id, 'worker'))
    }, [worker, date, schedule])

    const dayMeetings = useMemo(() => {
        let filtered = (meetings || [])
            .filter(DateHelper.isSameDayFilter(date, 'date'))
        if (worker) {
            filtered = filtered
                .filter(MeetingHelper.isSameFilter(worker?._id, 'worker._id'))
        }
        return filtered
    }, [meetings, worker, date])

    const dayGaps = useMemo(() => {
        let filtered = (gaps || [])
            .filter(DateHelper.isSameDayFilter(date, 'dateFrom'))
            .filter(MeetingHelper.isSameFilter(worker?._id || null, 'worker'))
        return filtered
    }, [gaps, worker, date])

    const dayIncome = useMemo(() => dayMeetings.reduce((acc, curr) => acc + curr.price, 0), [dayMeetings])
    
    const hours = useMemo(() => {
        const length = end - start
        return Array.from({ length }, (_, i) => i)
            .map((hourIndex, i) => {
                const hour = moment(date).hour(start + hourIndex).startOf('hour')
                const isDisabled = !_.isNil(worker) && (
                    !daySchedule?.from ||
                    !daySchedule?.to ||
                    daySchedule?.from > hour.hour() || 
                    daySchedule?.to <= hour.hour()
                )

                return (
                    <CalendarHourCell
                        hour={hour}
                        worker={worker}
                        isDisabled={isDisabled}
                        column={dayIndex + 2}
                    />
                )
            })
    }, [start, end, date, dayMeetings, daySchedule, worker])

    const meetingsItems = useMemo(() => {
        UtilHelper.groupMeetings(dayMeetings) //@TODO: convert to pure function
        return dayMeetings
            .map((meeting, index) => {
                const d = moment(meeting.date)
                const top = (d.hour() - start) * 60 + d.minutes()
                return <CalendarMeeting zIndex={index} {...meeting} top={top} />
            })
    }, [dayMeetings, start, end])

    const gapsItems = useMemo(() => {
        return dayGaps
            .map((gap) => {
                const d = moment(gap.dateFrom)
                const top = (d.hour() - start) * 60 + d.minutes()
                return <CalendarGap {...gap} top={top} worker={worker}/>
            })
    }, [dayGaps, worker, start, end])

    const MeetingPreview = () => {
        const { dragElement, dropArea } = dragData
        if (dragElement && dropArea && moment(dropArea).isSame(date, 'date') && ((dragElement.workerId === worker?._id) || !worker)) {
            const preview = { ...dragElement, date: dropArea, isPreview: true }
            const d = moment(preview.date)
            const top = (d.hour() - start) * 60 + d.minutes()
            return <CalendarMeeting zIndex={1000} {...preview} groupIndex={1} groupCount={1} top={top} />
        }
        return null
    }

    return (
        <>
            {(HeaderComponent != undefined) && (
                <HeaderCell column={dayIndex + 2}>
                    <HeaderComponent income={dayIncome} />
                </HeaderCell>
            )}
            <ShiftCell visible={isShiftVisible} column={dayIndex + 2}>
                <CalendarShiftPicker 
                    date={date} 
                    worker={worker} 
                />
            </ShiftCell>
            <HoursColumn column={dayIndex + 2}>
                <CalendarNowLine date={date} start={start} end={end} />
                {hours}
                {meetingsItems}
                <MeetingPreview></MeetingPreview>
                {gapsItems}
            </HoursColumn>
        </>
    )
}

