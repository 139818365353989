import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { Slider } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import DataService from '../services/DataService';
import store from '../store';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import moment from 'moment';
import Submit from './Submit/Submit';
import { pushToast } from '../actions/common.actions';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function ChanceForm({ initialData, hide }) {
    const [loading, setLoading] = useState(false)
    const { 
        data: workers = [],
    } = useQuery({ queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })

    const { 
        data: offers = [],
    } = useQuery({ queryKey: ['offers'], queryFn: DataService.getProviderOffers })

    const { provider, currentDate } = store.getState()

    const queryClient = useQueryClient()

    const ChanceSchema = useMemo(() => {
        return (
            Yup.object().shape({
                options: Yup.array().min(1),
                offers: Yup.array().min(1),
                worker: Yup.object().required(),  
                maxDiscount: Yup.number()
              })
        )
    }, [])
    
    const availableWorkers = useMemo(() => {
        return workers.map(w => ({...w, value: w._id, label: `${w.firstname} ${w.lastname}`}))
    }, [workers])

    const availableOffers = useMemo(() => {
        return offers.map(offer => ({...offer, value: offer._id, label: offer.title}))
    }, [offers, provider])

    const formik = useFormik({
        initialValues: {
          worker: availableWorkers.find(w => w._id === initialData?.worker?._id),
          options: initialData?.options || [],
          offers: initialData?.offers || [],
          maxDiscount: initialData?.maxDiscount || 0,
        },
        validationSchema: ChanceSchema,
        onSubmit: async values => {
            setLoading(true)
            const output = {...values, options: values.options.map(o => new Date(o))}
            try {
                if (initialData) {
                    await DataService.editChance({ ...output, _id: initialData._id })
                } else {
                    await DataService.addChance({ ...output,  provider })
                }
                queryClient.invalidateQueries({queryKey: ['chances']})
                hide()
            } catch (e) {
                const { data } = e.response
                store.dispatch(pushToast({ type: 'error', text: data?.message }))
                //@TODO error handling toast
            } finally {
                setLoading(false)
            }
            
        },
    });

    const { options: selectedOptions } = formik.values

    const onDatesChange = (newDate, index) => {
        let newVal;
        if (newDate) {
            newVal = selectedOptions.map((o, i) => i === index ? newDate : o)
        } else {
            newVal = selectedOptions.filter((o, i) => i !== index)
        }
        formik.setFieldValue('options', newVal)
    }

    const onAddOption = () => {
        const lastOption = selectedOptions[selectedOptions.length - 1]
        const newVal = lastOption ? moment(lastOption).add(15, 'minutes').toDate() : moment().add(15, 'minutes').startOf('hour').toDate()
        formik.setFieldValue('options', [...selectedOptions, newVal])
    }

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                <Fragment icon={faChartBar} text="Dane podstawowe">
                    <section className={styles.section}>
                        <label>Daty</label>
                        {selectedOptions.map((date, index) => {
                            return (
                                <>
                                <DateTimePicker 
                                    key={index}
                                    value={date}
                                    format={'d MMM yyyy H:mm'}
                                    isClockOpen={false}
                                    onChange={(val) => onDatesChange(val, index)}
                                />
                                <div style={{ height: 8 }}></div>
                                </>
                            )
                        })}
                    </section>
                    <Button
                        type="button"
                        variant="outlined"
                        onClick={onAddOption}
                    >Dodaj termin</Button>
                    <section className={styles.section}>
                        <label>Pracownik</label>
                        <Select
                            options={availableWorkers}
                            placeholder="Wybierz pracownika"
                            value={formik.values.worker}
                            onChange={option => formik.setFieldValue('worker', option)}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Usługi do wyboru</label>
                        <Select
                            options={availableOffers}
                            placeholder="Wybierz usługi"
                            isMulti
                            value={availableOffers.filter(offer => formik.values['offers'].map(o => o._id).includes(offer._id))}
                            onChange={values => formik.setFieldValue('offers', values)}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Rabat {formik.values['maxDiscount']}%</label>
                        <Slider
                            color={'primary'}
                            value={formik.values['maxDiscount']}
                            onChange={(e, value) => formik.setFieldValue('maxDiscount', value)} 
                        />
                    </section>
                </Fragment>
                <div className={styles.space}></div>
                <Submit
                    disabled={!formik.isValid}
                    label="Zapisz"
                    loading={loading}
                    className={styles.submit} 
                ></Submit>
        </form>
    )
}

export default ChanceForm
