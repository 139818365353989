import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';
import { modals } from '../../reducers/common.reducers';
import Modal from './Modal';

export const Foreground = styled.div`
    position: fixed;
    overflow: hidden;
    backdrop-filter: blur(2px);
    background: rgba(0,0,0,15%);
    opacity: ${props => props.visible ? 100 : 0};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props => props.visible ? 1000 : -1000};
`

function RightModals({ dispatch, modals }) {

    const foreground = useRef(null)
    const modalWrapper = useRef(null)

    useEffect(() => {
        if (!foreground || !modalWrapper) { return ; }
        // foreground.addEventListener('click', closeOnClickOutside)
        return () => {
            // foreground.removeEventListener('click', closeOnClickOutside)
        }
    }, [foreground, modalWrapper])

    return (
        <>
            <Foreground 
                ref={foreground} 
                visible={modals.length > 0}
            >
            </Foreground>
            {modals.map(modal => <Modal {...modal} />)}
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    modals: state.modals
})

export default connect(mapStateToProps)(RightModals)
