import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: calc(var(--space)/2);
    
`

export const ButtonsRow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: calc(var(--space)/2);
    margin-top: var(--space);
    margin-bottom: var(--space);
`

export const HeaderPart = styled.div`
    font-weight: 600;
`

export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space);
    padding-bottom: var(--space);
    border-bottom: 1px solid #ededed;
    & > * {
        margin: 0;
    }
`