import { Breadcrumbs } from '@mui/material'
import React from 'react'

function Nav({ title }) {
    return (
        <Breadcrumbs style={{ marginBottom: 16 }} separator="›">
            <span>Panel</span>
            <span>{title}</span>
        </Breadcrumbs>
    )
}

export default Nav
