import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import { showAlert } from '../../../actions/common.actions';
import { Row } from '../../../components/layout/common';
import DateHelper from '../../../helpers/DateHelper';
import UtilHelper from '../../../helpers/UtilHelper';
import DataService from '../../../services/DataService';
import UtilService from '../../../services/UtilService';

const Container = styled(Row)`
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dashed silver;
`

const Checkbox = styled.input`
    width: 15px;
    height: 15px;
    cursor: pointer;
    transition: all 200ms linear;
    border-radius: 10px;
`

const hours = UtilService.getHours()

function CalendarShiftPicker({ worker, date }) {

    const [isProcessing, setProcessing] = useState(false)
    const { 
        data: schedule = [],
        isFetching
    } = useQuery({ queryKey: ['shift'], queryFn: DataService.getWorkersScheduleForWeek, refetchOnWindowFocus: false })
    const queryClient = useQueryClient()

    const { data: workers = [], isLoading } = useQuery({
        queryKey: ['workers'], 
        queryFn: DataService.getWorkers, 
        refetchOnWindowFocus: false 
    })

    const dispatch = useDispatch()
    const history = useHistory()

    const isWorkerSelected = useMemo(() => {
        return workers.find(w => w._id === worker?._id)?.isSelected
    }, [workers, worker])

    useEffect(() => {
        if (!isFetching) { setProcessing(false) }
    }, [isFetching])

    const daySchedule = useMemo(() => {
        return schedule
            .filter(DateHelper.isSameDayFilter(date, 'date'))
            .find(UtilHelper.isSameFilter(worker?._id, 'worker'))
    }, [worker, date, schedule])

    const isActive = useMemo(() => Boolean(daySchedule && daySchedule.from && daySchedule.to), [daySchedule])

    const onChangeActive = useCallback(async (e) => {
        if (!isActive && !isWorkerSelected) {
            dispatch(showAlert({
                title: 'Włącz widoczność pracownika',
                text: 'Wybrany pracownik nie jest zaznaczony jako przyjmujący rezerwację online, Czy chcesz włączyć rezerwacje on-line dla pracownika?',
                onConfirm: () => history.push('/panel/pracownicy')
            }))
            return ;
        }
        let output = {}
        if (!isActive) {
            output = { 
                from: hours[0].value,
                to: hours[hours.length - 1].value
            }
        }
        setProcessing(true)
        await DataService.setScheduleForDate(date, worker?._id, output)
        queryClient.invalidateQueries(['shift'])
    }, [isActive, worker, date])

    const onChangeHour = useCallback(async (which, value) => {
        setProcessing(true)
        await DataService.setScheduleForDate(date, worker?._id, { 
            from: daySchedule.from,
            to: daySchedule.to,
            [which]: value
        })
        queryClient.invalidateQueries(['shift'])
    }, [worker, date, daySchedule, queryClient, worker])

    const availableHours = useMemo(() => {
        return {
            from: hours.filter(hour => hour.value < daySchedule?.to || !daySchedule?.to),
            to: hours.filter(hour => hour.value > daySchedule?.from || !daySchedule?.from),
        }
    }, [hours, daySchedule])

    if (isProcessing) {
        return (
            <Container>
                <ClipLoader size={38} color="var(--main-color)" />
            </Container>
        )
    }

    return (
        <Container>
            <Checkbox 
                value={isActive} 
                onChange={onChangeActive} 
                type="checkbox" 
                checked={isActive} 
                color="var(--main-color)"
            />
            &nbsp;
            <Select
                isDisabled={!isActive}
                options={availableHours.from}
                placeholder="Start"
                value={hours.find(h => h.value === daySchedule?.from)}
                onChange={(hour) => onChangeHour('from', hour.value)}
                styles={styles}
            ></Select>
            &nbsp;
            <Select
                isDisabled={!isActive}
                options={availableHours.to}
                placeholder="Koniec"
                value={hours.find(h => h.value === daySchedule?.to)}
                onChange={(hour) => onChangeHour('to', hour.value)}
                styles={styles}
            ></Select>
        </Container>
    )
}

const styles = {
    indicatorsContainer: (provided) => ({ display: 'none' }),
    control: (provided) => ({ ...provided, fontSize: 14 }),
    container: (provided) => ({ ...provided, flex: 1, zIndex: 200, maxWidth: 100 }),
    valueContainer: (provided) => ({ ...provided, justifyContent: 'center' })
}

export default CalendarShiftPicker
