import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { SliderPicker } from 'react-color';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { pushModal } from '../actions/common.actions';
import { Column, Row } from '../components/layout/common';
import PaidModule from '../components/PaidModule';
import ProductsTableForm from '../components/ProductsTableForm';
import UtilHelper from '../helpers/UtilHelper';
import useWorkers from '../hooks/useWorkers';
import DataService from '../services/DataService';
import store from '../store';
import CategoryForm from './CategoryForm';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function OfferForm({ initialData, hide, segment, segments }) {
    const [loading, setLoading] = useState(false)
    const workers = useWorkers()

    const {
        data: customCategories = [],
    } = useQuery({ queryKey: ['customCategories'], queryFn: DataService.getCustomCategories, refetchOnWindowFocus: false })

    const { provider } = store.getState()

    const queryClient = useQueryClient()

    const OfferSchema = useMemo(() => {
        return (
            Yup.object().shape({
                segment: Yup.string()
                    .required('Data jest wymagana'),
                category: Yup.string().required(),
                workers: Yup.array().required().min(1),
                title: Yup.string().required(),
                price: Yup.number().required().min(0).max(10000),
                duration: Yup.number().required().min(5).max(720),
                interval: Yup.number().min(15)
            })
        )
    }, [])

    const availableWorkers = useMemo(() => {
        return UtilHelper.parseOptions(workers, '_id', (w) => `${w.firstname} ${w.lastname}`)
    }, [workers])

    const availableSegments = useMemo(() => {
        return UtilHelper.parseOptions(segments, 'key', 'name')
        // .filter((segment) => (provider.segments || []).includes[segment.value])
        // @todo: Add filtering above
    }, [segments, provider])

    const formik = useFormik({
        initialValues: {
            workers: initialData?.workers || [],
            title: initialData?.title,
            description: initialData?.description || '',
            duration: initialData?.duration || 5,
            price: initialData?.price || 0,
            segment: segment,
            category: initialData?.category,
            provider,
            products: initialData?.products || [],
            color: initialData?.color || '#8b0000',
            customCategory: initialData?.customCategory,
            interval: initialData?.interval || 15
        },
        validateOnMount: true,
        validationSchema: OfferSchema,
        onSubmit: async values => {
            setLoading(true)
            if (initialData) {
                await DataService.editOffer({ ...values, _id: initialData._id })
            } else {
                await DataService.addOffer(values)
            }
            queryClient.invalidateQueries({ queryKey: ['offers'] })
            setLoading(false)
            hide()
        },
    });

    const availableCategories = useMemo(() => {
        if (segments.length < 1 || !formik.values.segment) {
            return []
        }
        const segment = segments.find(seg => seg.key === formik.values.segment)
        return UtilHelper.parseOptions(segment.categories, 'key', 'name')
    }, [formik.values.segment, segments])

    const availableSubcategories = useMemo(() => {
        const filtered = customCategories.filter(cc => cc.category === formik.values.category)
        return UtilHelper.parseOptions(filtered, 'key', 'name')
    }, [customCategories, formik.values.category])

    const onAddSubcategory = () => {
        store.dispatch(pushModal({
            header: 'Dodaj podkategorię',
            Component: CategoryForm,
            extraProps: {
                category: formik.values.category,
                callback: (subcategory) => {
                    const foundSubcategory = customCategories.find(cc => cc.key === subcategory)
                    if (foundSubcategory?.category === formik.values.category) {
                        formik.setFieldValue('customCategory', subcategory)
                    }
                },
                availableCategories
            }
        }))
    }

    const onCategoryChanged = (option) => {
        formik.setFieldValue('category', option.value)
        formik.setFieldValue('customCategory', null)
    }

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Fragment icon={faChartBar} text="Dane podstawowe">
                {!initialData && (
                    <section className={styles.section}>
                        <label>Segment</label>
                        <Select
                            options={availableSegments}
                            placeholder="Wybierz segment"
                            value={UtilHelper.resolveValueFromDict(availableSegments, formik.getFieldProps('segment').value)}
                            onChange={option => formik.setFieldValue('segment', option.value)}
                        />
                    </section>
                )}
                <section className={styles.section}>
                    <label>Kategoria</label>
                    <Select
                        options={availableCategories}
                        placeholder="Wybierz kategorię"
                        value={UtilHelper.resolveValueFromDict(availableCategories, formik.getFieldProps('category').value)}
                        onChange={onCategoryChanged}
                    />
                </section>
                {formik.values['category'] && <section className={styles.section}>
                    <label>Podkategoria</label>
                    <Row>
                        <Column style={{ marginRight: 8 }}>
                            <Select
                                options={availableSubcategories}
                                placeholder="Pole nieobowiązkowe"
                                value={UtilHelper.resolveValueFromDict(availableSubcategories, formik.getFieldProps('customCategory').value)}
                                onChange={option => formik.setFieldValue('customCategory', option.value)}
                            />
                        </Column>
                        <Button variant="outlined" onClick={onAddSubcategory}>Dodaj</Button>
                    </Row>
                </section>}
                <section className={styles.section}>
                    <label>Nazwa</label>
                    <Input
                        placeholder="max. 50 znaków"
                        error={Boolean(formik.errors['title']) && formik.dirty}
                        className={styles.textInput}
                        name="title"
                        id="title"
                        type="text"
                        value={formik.getFieldProps('title').value}
                        onChange={formik.handleChange}
                    />
                </section>
            </Fragment>
            <Fragment
                icon={faChartBar}
                text="Szczegóły"
            >
                <section className={styles.section}>
                    <label>Przypisani pracownicy</label>
                    <Select
                        options={availableWorkers}
                        placeholder="Wybierz pracowników"
                        isMulti
                        value={UtilHelper.resolveValueFromDict(availableWorkers, formik.getFieldProps('workers').value)}
                        onChange={values => formik.setFieldValue('workers', values.map(v => v.value))}
                    />
                </section>
                <section className={styles.section}>
                    <label>Opis</label>
                    <textarea
                        placeholder="max. 50 znaków"
                        style={{ height: 100 }}
                        error={Boolean(formik.errors['description']) && formik.dirty}
                        className={styles.textInput}
                        name="description"
                        id="description"
                        type="text"
                        value={formik.getFieldProps('description').value}
                        onChange={formik.handleChange}
                    />
                </section>
                <section className={styles.section}>
                    <label>Cena</label>
                    <Input
                        value={formik.getFieldProps('price').value}
                        error={Boolean(formik.errors['price']) && formik.dirty}
                        className={styles.textInput}
                        placeholder="PLN"
                        id="price"
                        type="number"
                        max={5000}
                        name="price"
                        onChange={formik.handleChange}
                    />
                </section>
                <section className={styles.section}>
                    <label>Czas trwania (min.)</label>
                    <Input
                        value={formik.getFieldProps('duration').value}
                        error={Boolean(formik.errors['duration']) && formik.dirty}
                        className={styles.textInput}
                        placeholder="podaj w min."
                        id="duration"
                        type="number"
                        max={5000}
                        name="duration"
                        onChange={formik.handleChange}
                    />
                </section>
                <section className={styles.section}>
                    <label>Interwał</label>
                    <Input
                        value={formik.getFieldProps('interval').value}
                        error={Boolean(formik.errors['interval']) && formik.dirty}
                        className={styles.textInput}
                        placeholder="podaj w min."
                        id="interval"
                        type="number"
                        max={5000}
                        name="interval"
                        onChange={formik.handleChange}
                    />
                </section>
                <section className={styles.section}>
                    <label>Kolor (w kalendarzu)</label>
                    <SliderPicker
                        color={formik.values['color']}
                        onChange={(val) => formik.setFieldValue('color', val.hex)}
                    />
                </section>
            </Fragment>
            <Fragment text="Wykorzystane produkty" icon={faShoppingCart}>
                <div className={styles.section}>
                    <PaidModule
                        moduleKey="PRODUCTS"
                        render={() => {
                            return (
                                <ProductsTableForm
                                    updateProducts={(newVal) => formik.setFieldValue('products', newVal)}
                                    productsArray={formik.values['products']}
                                />
                            )
                        }}
                    />
                </div>
            </Fragment>
            <div className={styles.space}></div>
            <Submit
                disabled={!formik.isValid}
                label="Zapisz"
                loading={loading}
                className={styles.submit}
            ></Submit>
        </form>
    )
}

export default OfferForm
