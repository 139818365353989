import { faCalendarPlus, faClock, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import styled from 'styled-components';
import { pushModal } from '../../../actions/common.actions';
import QuickMeetingForm from '../../../forms/QuickMeetingForm';
import store from '../../../store';
import { Button, SpeedDial, SpeedDialAction } from '@mui/material'
import GapForm from '../../../forms/GapForm';
import ChanceForm from '../../../forms/ChanceForm';
import DelayNotificationForm from '../../../forms/DelayNotificationForm';

const Btn = styled(Button)`
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background: #cc2d37;
    position: fixed;
    bottom: 32px;
    right: 32px;
    box-shadow: 0 0 12px rgba(0,0,0,30%);
    z-index: 100;
`

function CalendarAddBtn() {

    return (
        <SpeedDial
            ariaLabel="Akcja"
            color="red"
            sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: 100 }}
            icon={<FontAwesomeIcon style={{ fontSize: 24 }} icon={faCalendarPlus} color={'white'} />}
        >
            <SpeedDialAction
                key={'delay'}
                onClick={() => {
                    store.dispatch(pushModal({
                        header: 'Poinformuj klienta o opóźnieniu',
                        Component: DelayNotificationForm
                    }))
                }}
                icon={<FontAwesomeIcon style={{ fontSize: 18 }} icon={faClock} color={'grey'} />}
                tooltipTitle={'Poinformuj klienta'}
            />
            <SpeedDialAction
                key={'gap'}
                onClick={() => {
                    store.dispatch(pushModal({
                        header: 'Dodaj urlop',
                        Component: GapForm,
                    }))
                }}
                icon={<FontAwesomeIcon style={{ fontSize: 18 }} icon={faTimes} color={'grey'} />}
                tooltipTitle={'Blokada / urlop'}
            />
            <SpeedDialAction
                key={'chance'}
                onClick={() => {
                    store.dispatch(pushModal({
                        header: 'Dodaj okazję',
                        Component: ChanceForm
                    }))
                }}
                icon={<FontAwesomeIcon style={{ fontSize: 18 }} icon={faStar} color={'grey'} />}
                tooltipTitle={'Nowa okazja'}
            />
            <SpeedDialAction
                key={'quickVisit'}
                onClick={() => {
                    store.dispatch(pushModal({
                        header: 'Szybka wizyta',
                        Component: QuickMeetingForm
                    }))
                }}
                icon={<FontAwesomeIcon style={{ fontSize: 18 }} icon={faCalendarPlus} color={'grey'} />}
                tooltipTitle={'Szybka wizyta'}
            />
        </SpeedDial>
    )
}

export default CalendarAddBtn
