import { Avatar, Button, Chip, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Row } from '../../../components/layout/common'
import moment from 'moment'
import PageContainer from '../../../components/layout/PageContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import DataService from '../../../services/DataService'
import _ from 'lodash'
import store from '../../../store'
import StyledTableRow from '../../../components/layout/StyledTableRow'
import Nav from '../Nav'

const Formatter = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' })

const Label = styled.div`
    margin: 0 16px;
`

const FiltersRow = styled(Row)`
    margin-bottom: 16px;
`

const modes = [{ label: 'Tydzień', value: 'WEEK' }, { label: 'Miesiąc', value: 'MONTH' }, { label: 'Rok', value: 'YEAR' }]

const getEdgeDates = (inputMode, inputDate) => {
    let from, to;
    switch (inputMode) {
        case 'WEEK':
            from = moment(inputDate).startOf('isoWeek')
            to = moment(inputDate).endOf('isoWeek')
            break
        case 'MONTH':
            from = moment(inputDate).startOf('month')
            to = moment(inputDate).endOf('month')
            break
        case 'YEAR':
            from = moment(inputDate).startOf('year')
            to = moment(inputDate).endOf('year')
            break
    }
    return [from, to]
}

function ReportPage() {

    const [mode, setMode] = useState('MONTH')
    const [date, setDate] = useState(moment().toISOString())
    const [report, setReport] = useState([])
    
    const move = useCallback((val) => {
        const newDate = moment(date).add(val, mode.toLowerCase())
        setDate(newDate.toISOString())
    }, [date, mode, setDate])

    useEffect(() => {
        const [from, to] = getEdgeDates(mode, date)
        if (!from || !to) { return ; }
        const { provider } = store.getState()
        DataService.getReport(provider._id, from.toISOString(), to.toISOString())
            .then(setReport)
    }, [date, mode])

    const content = _.orderBy(report.slice(0, report.length - 1), ['totalIncome'], ['desc'])
    const summary = report[report.length - 1]

    const label = useMemo(() => {
        const [from, to] = getEdgeDates(mode, date)
        switch (mode) {
            case 'WEEK':
                return `${from.format('DD MMM')} - ${to.format('DD MMM')}`
            case 'MONTH':
                return `${from.format('MMMM')} ${to.format('YYYY')}`
            case 'YEAR':
                return moment(date).format('YYYY')
        }
    }, [mode, date])

    return (
        <PageContainer>
            <Nav title="Finanse" />
            <FiltersRow stretched>
                <Select onChange={e => setMode(e.target.value)} value={mode}>
                    {modes.map((mode) => (
                        <MenuItem value={mode.value}>{mode.label}</MenuItem>
                    ))}
                </Select>
                <Row vCentered>
                    <IconButton
                        onClick={() => move(-1)}
                        color="default"
                    ><FontAwesomeIcon icon={faChevronLeft}/></IconButton>
                    <Label>{label}</Label>
                    <IconButton 
                        onClick={() => move(1)}
                        variant="outlined"
                    ><FontAwesomeIcon icon={faChevronRight}/></IconButton>
                </Row>
                <div></div>
            </FiltersRow>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>L.p.</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Imię i nazwisko</TableCell>
                            <TableCell>Przychód (Gotówka)</TableCell>
                            <TableCell>Przychód (Karta)</TableCell>
                            <TableCell>Przychód (BLIK)</TableCell>
                            <TableCell>Łączny przychód</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {report.map((row, index) => {
                        const { cardIncome, cashIncome, blikIncome, totalIncome } = row
                        const { thumb, firstname, lastname } = row.worker || {}
                        const isTotalRow = !row.worker
                        return (
                            <StyledTableRow highlight={isTotalRow}>
                                <TableCell align="center">{!isTotalRow && (index + 1)}</TableCell>
                                <TableCell align="center">
                                    {!isTotalRow && <Avatar src={thumb}></Avatar>}
                                </TableCell>
                                <TableCell>{firstname} {lastname}</TableCell>
                                <TableCell align="center">{Formatter.format(cashIncome)}</TableCell>
                                <TableCell align="center">{Formatter.format(cardIncome)}</TableCell>
                                <TableCell align="center">{Formatter.format(blikIncome)}</TableCell>
                                <TableCell align="center">{Formatter.format(totalIncome)}</TableCell>
                            </StyledTableRow>
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    )
}

export default ReportPage
