import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { faPhoneAlt, faBell, faMobilePhone, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { Column, Row } from '../components/layout/common';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import _ from 'lodash';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';
import LoadingDots from '../components/LoadingDots/LoadingDots';
import store from '../store';
import { showAlert } from '../actions/common.actions';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function DelayNotificationForm({ hide }) {
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(true)
    const [meetings, setMeetings] = useState([])
    
    useEffect(() => {
        DataService.getMeetingsForCurrentWeek()
            .then(setMeetings)
            .finally(setFetching)
    }, [])

    const dayRemainingMeetings = useMemo(() => {
        return _.sortBy(meetings.filter(meeting => {
            const date = moment(meeting.date)
            return date.isAfter(new Date(), 'second') && date.isSame(moment(), 'date')
        }), ['date'], 'asc')
    }, [meetings])

    const NotificationSchema = useMemo(() => {
        return (
            Yup.object().shape({
                meetingsIds: Yup.array().min(1),
                contactForms: Yup.array().min(1),
                delayMinutes: Yup.number().required().min(5).max(500),  
                reason: Yup.string().required()
              })
        )
    }, [])

    const formik = useFormik({
        initialValues: {
          meetingsIds: [],
          contactForms: [],
          reason: 'MEETING-DELAY',
          delayMinutes: 5
        },
        validateOnMount: true,
        validationSchema: NotificationSchema,
        onSubmit: async values => {
            setLoading(true)
            try {
                DataService.notifyClient(values)
                hide()
            } catch (e) {
                //@TODO error handling toast
            } finally {
                setLoading(false)
            }            
        },
    });

    const onCheckMeeting = (e) => {
        let { meetingsIds } = formik.values
        let newVal;
        if (e.target.checked) {
            newVal = [...meetingsIds, e.target.id]
        } else {
            newVal = meetingsIds.filter(meetingId => meetingId !== e.target.id)
        }
        formik.setFieldValue('meetingsIds', newVal)
    }

    const onCheckContactForm = (e) => {
        const { contactForms } = formik.values
        const { id, checked } = e.target
        formik.setFieldValue('contactForms', checked ? [...contactForms, id] : contactForms.filter(cf => cf !== e.target.id))
    }

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                <Fragment icon={faChartBar} text="Szczegóły">
                    <section className={styles.section}>
                        <label>Opóźnienie (min.)</label>
                        <Input 
                            type="number" 
                            id="delayMinutes" 
                            name="delayMinutes" 
                            placeholder="podaj w minutach"
                            className={styles.textInput}
                            value={String(formik.values['delayMinutes'])}
                            onChange={(e) => formik.setFieldValue('delayMinutes', Number(e.target.value))}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Forma kontaktu</label>
                        <FormGroup row>
                            <FormControlLabel control={<Checkbox
                                id={'SMS'}
                                checked={formik.values['contactForms'].includes('SMS')}
                                onChange={onCheckContactForm}
                            />} label="SMS" />
                            <FormControlLabel control={<Checkbox
                                id={'NOTIFICATION'}
                                checked={formik.values['contactForms'].includes('NOTIFICATION')}
                                onChange={onCheckContactForm}
                            />} label="Aplikacja mobilna" />
                        </FormGroup>                        
                    </section>
                    </Fragment>
                    <Fragment icon={faPeopleGroup} text="Klienci">
                    {fetching ? <LoadingDots /> : (
                    <section className={styles.section}>
                        {dayRemainingMeetings.map(meeting => {
                            const { _id, clientName, client, date, worker, clientPhone, offer } = meeting
                            const isSmsAllowed = clientPhone != undefined
                            const isNotificationAllowed = client.user != undefined
                            const checked = formik.values['meetingsIds'].includes(meeting._id)
                            return (
                                <Row vCentered marginBetween style={{ marginBottom: 16 }}>
                                    <Checkbox
                                        id={_id}
                                        checked={checked}
                                        onChange={onCheckMeeting}
                                    />
                                    <Column>
                                        <b>{moment(date).format('H:mm')}</b>
                                        <small>{clientName} - {_.truncate(offer.title)}</small>
                                        <Row marginBetween vCentered>
                                            {isSmsAllowed && <FontAwesomeIcon color="silver" icon={faMobilePhone} />}
                                            {isNotificationAllowed && <FontAwesomeIcon color="silver" icon={faBell} />}
                                        </Row>
                                    </Column>
                                    <Avatar src={worker.thumb}>
                                        {UtilHelper.getFirstLetters(worker)}
                                    </Avatar>
                                </Row>
                            )
                        })}
                        {dayRemainingMeetings.length === 0 && (
                            <Typography>Brak nadchodzących wizyt dzisiaj</Typography>
                        )}
                    </section>
                    )}
            
                </Fragment>
                <div className={styles.space}></div>
                <Submit
                    disabled={!formik.isValid}
                    label="Wyślij"
                    loading={loading}
                    className={styles.submit} 
                ></Submit>
        </form>
    )
}

export default DelayNotificationForm
