import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { pushToast } from '../actions/common.actions';
import { Column, Row } from '../components/layout/common';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import store from '../store';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function PackagesForm({ hide, activePackage }) {
    const [loading, setLoading] = useState()

    const { data: packages = [] } = useQuery({ 
        queryKey: ['packages'],
        queryFn: DataService.getPackages 
    })
    
    const PackagesSchema = useMemo(() => {
        return (
            Yup.object().shape({
                key: Yup.string().required(),
                quantity: Yup.number().required()
            })
        )
    }, [])

    const formik = useFormik({
        initialValues: {
            key: null
        },
        validationSchema: PackagesSchema,
        validateOnMount: true,
        onSubmit: async values => {
            try {
                setLoading(true)
                const redirectUri = await DataService.createOrder({ packageKey: values.key, quantity: values.quantity, type: 'PACKAGE', method: 'CARD' })
                window.location.replace(redirectUri)
                hide()
            } catch (e) {
                const { data } = e.response
                store.dispatch(pushToast({ type: 'error', text: data?.message }))
            } finally {
                setLoading(false)
            }
        },
    });

    const availableOffers = useMemo(() => {
        return UtilHelper.parseOptions(packages, 'key', 'name')
    }, [packages])

    const { key } = formik.values

    const selectedItem = useMemo(() => {
        return packages.find(i => i.key === key)
    }, [packages, key])

    useEffect(() => {
        if (!packages.length) return ;
        const activeIndex = activePackage?.index || 0
        let item = packages.find(i => i.index === activeIndex + 1)
        if (!item) {
            item = packages.find(i => i.index === activeIndex)
        }
        formik.setFieldValue('key', item?.key)
        formik.setFieldValue('quantity', 12)
    }, [packages])
    
    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Fragment text="">
                <section className={styles.section}>
                    <label>Pakiet</label>
                    <Select
                        options={availableOffers}
                        placeholder="Wybierz pakiet"
                        value={UtilHelper.resolveValueFromDict(availableOffers, formik.getFieldProps('key').value)}
                        onChange={option => formik.setFieldValue('key', option.value)}
                    />
                </section>
            </Fragment>
            {selectedItem && (
            <>
                <Fragment text="Funkcjonalności">
                    <section className={styles.section}>
                        <ul style={{ marginBottom: 0 }}>
                            {selectedItem.items.map(i => (
                                <li>{i}</li>
                            ))}
                        </ul>
                    </section>
                </Fragment>
                <Fragment text="Wariant">
                    {selectedItem.variants.map(variant => {
                        const isSelected = formik.values.quantity === variant.quantity
                        return (
                            <Paper onClick={() => {
                                formik.setFieldValue('quantity', variant.quantity)
                            }} style={{ padding: 16, marginBottom: 8, cursor: 'pointer', border: isSelected ? '1px solid var(--main-color)': 'none' }}>
                                <Row>
                                    <Checkbox checked={isSelected} />
                                    <Column style={{ marginLeft: 8, flex: 1 }}>
                                        <h2 style={{ color: isSelected ? 'var(--main-color)': undefined}}>{variant.label}</h2>
                                        <small>{variant.priceFormatted} + VAT</small>
                                    </Column>
                                    {variant.discount && <Chip color="primary" label={`Oszczędzasz ${variant.discountAmount} zł`} variant="outlined" />}
                                </Row>
                            </Paper>
                        )
                    })}
                </Fragment>
            </>)}
            <div className={styles.space}></div>
            <Submit
                disabled={!formik.isValid || selectedItem?.index < activePackage?.index}
                label="Kup"
                loading={loading}
                className={styles.submit} 
                onSubmit={formik.handleSubmit}
            ></Submit>
        </form>
    )
}

export default PackagesForm
