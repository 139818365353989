import { Button } from "@mui/material";
import { Column, Row } from "../../../components/layout/common";

function CalendarWeekAdd(props) {

    const changeDate = (value) => {
        props.changeWeek(value)
    }
    
    return (
        <Column>
            <small style={{ marginTop: 10, marginBottom: 10 }}>Zmień tydzień:</small>
            <Row>
                {Array.from([1,2,3,4]).map((plus) => (
                    <Button onClick={() => changeDate(plus)} color="primary">+{plus}</Button>
                ))}
            </Row>
            <Row>
                {Array.from([-1,-2,-3,-4]).map((minus) => (
                    <Button onClick={() => changeDate(minus)} color="primary">{minus}</Button>
                ))}
            </Row>
        </Column>
    )
}

export default CalendarWeekAdd