import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: row;
`

const Mode = styled.a`
    /* border: none; */
    /* display: flex; */
    /* border-radius: 10px; */
    /* padding: 16px; */
    /* background: ${props => props.isSelected ? '#efefef' : 'white'}; */
`

function CalendarModeSwitch({ mode, setMode }) {

    return (
        <Container>
            <ToggleButtonGroup size="medium" exclusive value={mode} onChange={(e, val) => setMode(val)}>
                <ToggleButton value="day" >Dzień</ToggleButton>
                <ToggleButton value="week">Tydzień</ToggleButton>
            </ToggleButtonGroup>
        </Container>
    )
    // return (
    //     <Container>
    //         <Mode onClick={() => setMode('week')} isSelected={mode === 'week'}>Tydzień</Mode>
    //         <Mode onClick={() => setMode('day')} isSelected={mode === 'day'}>Dzień</Mode>
    //     </Container>
    // )
}

export default CalendarModeSwitch
