import { faUser } from '@fortawesome/free-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function ClientForm({ hide, client: initialData, callback }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient()

    const onSubmit = useCallback(async (data) => {
        setLoading(true)
        try {
            if (initialData) {
                await DataService.editClient(initialData._id, {
                    name: data.name,
                    phone: data.phone,
                    email: data.email
                })
            } else {
                await DataService.addClient(data)
            }

            callback()
            
            hide()
        } catch (e) {
            const { response } = e
         
        } finally {
            setLoading(false)
        }
    }, [])

    const ClientSchema = useMemo(() => {
        return (
            Yup.object().shape({
                name: Yup.string().min(2).max(100),
                phone: Yup.string().min(9).max(9),
                email: Yup.string().email(),
              })
        )
    }, [])

    
    const formik = useFormik({
        initialValues: {
            name: initialData?.name,
            phone: initialData?.phone,
            email: initialData?.email
        },
        validationSchema: ClientSchema,
        validateOnMount: true,
        onSubmit
    })

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Fragment
                text="Dane klienta"
                icon={faUser}
            >
                <div className={styles.section}>
                    <label>Imię i nazwisko</label>
                    <Input 
                        placeholder="Nazwa"
                        value={formik.values['name']}
                        error={Boolean(formik.errors['name']) && formik.dirty}
                        className={styles.textInput}
                        name="name"
                        id="name"
                        type="text"
                        onChange={formik.handleChange}
                    />
                </div>
                <div className={styles.section}>
                    <label>Telefon klienta</label>
                    <input 
                        placeholder="Wpisz telefon"
                        className={styles.textInput}
                        max={999999999}
                        pattern="[0-9]{9}"
                        name="phone"
                        id="phone"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.getFieldProps('phone').value}
                    />
                </div>
                <div className={styles.section}>
                    <label>Adres e-mail</label>
                    <input 
                        placeholder="Wpisz adres e-mail"
                        className={styles.textInput}
                        pattern="[^@\s]+@[^@\s]+"
                        name="email"
                        id="email"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.getFieldProps('email').value}
                    />
                </div>
            </Fragment>
            <Submit
                disabled={!formik.isValid}
                loading={loading}
                className={styles.submit} 
                label="Zapisz">
            </Submit>
        </form>
    )
}

export default ClientForm
