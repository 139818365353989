import React, { useContext, useState } from 'react'
import { useCallback } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { pushModal, showAlert } from '../../../actions/common.actions';
import { DISPLAY_MULTIPLIER } from '../../../config/CalendarConfig';
import MeetingForm from '../../../forms/MeetingForm';
import moment from 'moment';
import { useDrop } from 'react-dnd'
import store from '../../../store';
import DataService from '../../../services/DataService';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

const HourCell = styled.div`
    &:hover {
        background: #efefef;
    }
    cursor: pointer;
    transition: all 200ms linear;
    border-right: 1px solid silver;
    border-bottom: 1px dashed silver;
    display: flex;
    flex: 1;
    height: calc(60px * ${DISPLAY_MULTIPLIER});
    position: relative;
    overflow: visible;
    z-index: ${props => props.zIndex};
    ${props => props.disabled && css`
        background-color: #dbdbdb;
        opacity: 0.8;
        background-size: 8px 8px;
        background-image:  repeating-linear-gradient(0deg, #949494, #949494 0.4px, #dbdbdb 0.4px, #dbdbdb);
    `};
`

const QuarterLine = styled.div`
    position: absolute;
    top: ${props => props.index * 15 * DISPLAY_MULTIPLIER}px;
    left: 0;
    right: 0;
    height: 1px;
    border: 0;
    border-top: 1px dashed #efefef;
    z-index: -10000;
`

function CalendarHourCell({ hour, worker, isDisabled, column }) {

    const [itemToBe, setItemToBe] = useState(null)
    const queryClient = useQueryClient()
    const [collectedProps, drop] = useDrop(() => ({
        accept: 'MEETING',
        hover: () => {

        },
        drop: ({_id, date, worker: meetingWorker, duration}, monitor) => {
            const { y } = monitor.getDifferenceFromInitialOffset()
            const shift = Math.floor(y / DISPLAY_MULTIPLIER / 15) * 15

            const newDate = moment(date).add(shift, 'minutes').date(hour.date())


            store.dispatch(showAlert({
                text: `Czy na pewno przenieść tę wizytę na termin ${newDate.format('ddd DD MMMM H:mm')}?`,
                title: 'Potwierdzenie',
                onConfirm: async () => {
                    const payload = { date: newDate.toDate() }
                    if (worker?._id !== meetingWorker?._id) {
                        payload.worker = worker._id
                    } 
                    await DataService.editMeeting(_id, payload)
                    queryClient.invalidateQueries({queryKey: ['meetings']})
                }
            }))
            return { newDate }
        }       
      }))
    const dispatch = useDispatch()
    const cellRef = useRef()

    const downCallback = (e) => {
        const { time } = e.target.dataset
        const top = Math.floor(e.offsetY / 15) * 15
        const date = moment(time).add(top, 'minutes')
        const potentialGap = document.createElement('div')
        potentialGap.className = 'tt-potential-event'
        potentialGap.style.top = top * DISPLAY_MULTIPLIER + 'px'
        e.target.appendChild(potentialGap)
        window.potentialGap = { element: potentialGap, startDate: date, worker }
    }

    useEffect(() => {
        cellRef.current?.addEventListener?.('mousedown', downCallback)
        return () => {
            cellRef.current?.removeEventListener?.('mousedown', downCallback)
        }
    }, [worker])

    useEffect(() => {
        if (cellRef.current) {
            drop(cellRef.current)
        }
    }, [cellRef.current])

    return (
        <HourCell
            data-time={hour.toISOString()}
            data-workerId={worker?._id}
            // ref={(props) => {
            //     drop(props)
            // }}
            ref={cellRef}
            disabled={isDisabled}
            column={column}
        >
            {!isDisabled && (
                <>
                    <QuarterLine index={1}/>
                    <QuarterLine index={2}/>
                    <QuarterLine index={3}/>
                </>
            )}
        </HourCell>
    )
}

export default CalendarHourCell
