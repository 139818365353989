import { SET_ACTIVE_DAY, SET_CHANCES, SET_MEETINGS, SET_MODAL, SET_PROVIDER, SET_SEGMENTS, SET_PROVIDERS, SET_CHARITIES, SET_USER, UPDATE_MEETINGS_VERSION, SET_CURRENT_DATE, SET_ACTIVE_WORKER, PUSH_MODAL, HIDE_MODAL, REMOVE_MODAL } from "../const/actions";
import DataService from "../services/DataService";

export const setModal = (config) => ({
    type: SET_MODAL,
    config
})

export const setCharities = (charities) => ({
    type: SET_CHARITIES,
    charities
})

export const setProvider = (provider) => ({
    type: SET_PROVIDER,
    provider
})

export const setSegments = (segments) => ({
    type: SET_SEGMENTS,
    segments
})


export const setMeetings = (meetings) => ({
    type: SET_MEETINGS,
    meetings
})

export const setActiveDay = (day) => ({
    type: SET_ACTIVE_DAY,
    day
})

export const setChances = (chances) => ({
    type: SET_CHANCES,
    chances
})

export const setProviders = (providers) => ({
    type: SET_PROVIDERS,
    providers
})

export const setUser = (user) => ({
    type: SET_USER,
    user
})

export const updateMeetingsVersion = (version) => ({
    type: UPDATE_MEETINGS_VERSION,
    version
})

export const setActiveWorker = (worker) => ({
    type: SET_ACTIVE_WORKER,
    worker
})

export const fetchMeetings =  (date = null) => {
    return async (dispatch, getState) => {
        const { provider } = getState()
        try {
            const meetings = await DataService.getMeetings(provider._id, date)
            dispatch(setMeetings(meetings))
        } catch (e) {
            // meetings not updated
        }        
    }    
}

export const addWorker = (id, email) => {
    return async (dispatch, getState) => {
        const { provider } = getState()
        await DataService.addWorker(id, email)
    }
}

export const updateProvider = (provider) => {
    return async (dispatch, getState) => {
        const updatedProvider = await DataService.getProvider(provider._id)
        dispatch(setProvider(updatedProvider))
    }
}

export const getSegments = () => {
    return async (dispatch, getState) => {
        const segments = await DataService.getDicts()
        dispatch(setSegments(segments))
    }
}

export const getChances = () => {
    return async (dispatch, getState) => {
        const { _id } = getState()['provider']
        const chances = await DataService.getChances(_id)
        dispatch(setChances(chances))
    }
}

export const editProvider = (id, body) => {
    return async (dispatch, getState) => {
        const provider = await DataService.editProvider(id, body)
        dispatch(updateProvider(body))
    }
}

export const getProviders = () => {
    return async (dispatch, getState) => {
        const providers = await DataService.getProviders()
        dispatch(setProviders(providers))
    }
}

export const getCharities = () => {
    return async (dispatch, getState) => {
        const charities = await DataService.getCharities()
        dispatch(setCharities(charities))
    }
}

export const confirmMeeting = (meeting) => {
    return async (dispatch, getState) => {
        await DataService.confirmMeeting(meeting)
        dispatch(fetchMeetings())
    }
}

export const updateThumb = (data) => {
    let formData = new FormData();    
    formData.append('file', data)
    return async (dispatch, getState) => {        
        const { provider } = getState()
        const imgURL = await DataService.sendFile(formData)
        return imgURL
        // const updatedProvider = await DataService.getProvider(provider._id)
        // await DataService.editProvider(updatedProvider._id, {
        //     ...updatedProvider,
        //     thumb: imgURL
        // })
        // const providerWithUpdatedThumb = await DataService.getProvider(provider._id)
        // dispatch(setProvider(providerWithUpdatedThumb))
    }
}

export const checkThirdPartyLogin = (token, type) => {
    return async dispatch => {
        const result = await DataService.checkThirdPartyLogin(token, type)
        return result
    }
}

export const logout = () => {
    localStorage.removeItem('ontime-token')
    localStorage.removeItem('provider')
    window.location.reload()
}

export const setCurrentDate = (date) => ({
    type: SET_CURRENT_DATE,
    date
})

export const pushModal = (modal) => ({
    type: PUSH_MODAL,
    modal
})

export const hideModal = (id) => ({
    type: HIDE_MODAL,
    id
})

export const pushToast = (toast) => ({
    type: 'PUSH_TOAST',
    toast
})

export const hideToast = (id) => ({
    type: 'HIDE_TOAST',
    id
})

export const showAlert = (config) => ({
    type: 'SHOW_ALERT',
    config
})

export const setLoading = (value) => ({
    type: 'SET_LOADING',
    value
})

