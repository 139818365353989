import React, { useState } from 'react'
import { useEffect } from 'react';
import styled from 'styled-components';
import { DISPLAY_MULTIPLIER } from '../../../config/CalendarConfig';
import moment from 'moment'
import { connect } from 'react-redux';

const NowLine = styled.div`
    position: absolute;
    left:0;
    right: 0;
    border: 0;
    border-top: 2px dotted darkred;
    top: ${props => props.top * DISPLAY_MULTIPLIER}px;
    z-index: 200;
`

let interval;

function CalendarNowLine({ start, date }) {
    const calculateDiff = () => moment().diff(moment().hour(start).startOf('h'), 'minutes')
    const [top, setTop] = useState(calculateDiff())

    useEffect(() => {
        interval = setInterval(() => {
            setTop(calculateDiff())
        }, 10000)
        return () => clearInterval(interval)
    }, [])


    if (!moment(date).isSame(moment(), 'date')) return null

    return (
        <NowLine top={top}/>
    )
}


export default CalendarNowLine
