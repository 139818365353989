import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { Rating } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

function RateForm({ hide, meetingId, callback }) {
    const [loading, setLoading] = useState()
    const queryClient = useQueryClient()
    const RateSchema = useMemo(() => {
        return (
            Yup.object().shape({
                value: Yup.number().min(1).max(5),
                description: Yup.string().max(500)
              })
        )
    }, [])

    const formik = useFormik({
        initialValues: {
            value: null,
            description: ''
        },
        validationSchema: RateSchema,
        validateOnMount: true,
        onSubmit: async values => {
            try {
                setLoading(true)
                await DataService.rateClient(meetingId, values)
                queryClient.invalidateQueries({ queryKey: ['meetings'] })
                callback()
                hide()
            } catch (e) {

            } finally {
                setLoading(false)
            }
        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                <Fragment icon={faChartBar} text="Dane podstawowe">
                    <section className={styles.section}>
                        <label>Ocena</label>
                        <Rating 
                            onChange={(e) => {
                                formik.setFieldValue('value', Number(e.target.value))
                            }}
                            value={formik.values['value']} 
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Recenzja</label>
                        <textarea 
                            placeholder="max. 500 znaków"
                            maxLength={500}
                            style={{ height: 100 }}
                            error={Boolean(formik.errors['description']) && formik.dirty}
                            className={styles.textInput}
                            name="description"
                            id="description"
                            type="text"
                            value={formik.getFieldProps('description').value}
                            onChange={formik.handleChange}
                        />
                    </section>
                </Fragment>
                <div className={styles.space}></div>
                <Submit
                    disabled={!formik.isValid}
                    label="Zapisz"
                    loading={loading}
                    className={styles.submit} 
                    onSubmit={formik.handleSubmit}
                ></Submit>
        </form>
    )
}

export default RateForm
