import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import store from '../store'
import _ from 'lodash'
import moment from 'moment'
import { TitleRow } from './layout/details'
import DataService from '../services/DataService'
import PageContainer from './layout/PageContainer'
import LoadingDots from './LoadingDots/LoadingDots'
import { useDispatch } from 'react-redux'
import { pushModal } from '../actions/common.actions'
import MeetingForm from '../forms/MeetingForm'
import { setLoading as setLoadingAction } from '../actions/common.actions'

const VisitRow = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: var(--space);
    ${props => props.isCancelled && css`
        /* color: red; */
        text-decoration: line-through;
    `}
    &:hover {
        /* text-decoration: underline; */
        color: var(--main-color);
    }
`

const Day = styled.div`
    text-decoration: none;
    width: 60px;
    height: 60px;
    border: 1px solid silver;
    border-radius: 10px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
        margin: 0;
        font-size: 20px;
    }
`

const Details = styled.div`
    flex: 1;
    margin-left: 16px;
`

function ClientMeetingsDetails({ clientId, name }) {

    const [loading, setLoading] = useState(true)
    const [visits, setVisits] = useState([])

    const dispatch = useDispatch()

    useEffect(async () => {
        const { _id } = store.getState()['provider']
        DataService.getMeetingsForClient(_id, clientId)
            .then(setVisits)
            .finally(setLoading)
    }, [])

    const openMeeting = async (_id) => {
        dispatch(setLoadingAction(true))
        const item = await DataService.getMeeting(_id)
        dispatch(setLoadingAction(false))
        dispatch(pushModal({
            header: 'Edycja wizyty',
            Component: MeetingForm,
            item
        }))
    }

    return (
        <PageContainer> 
            {loading && <LoadingDots />}
            {_.orderBy(visits, ['date'], ['desc']).map((visit) => {
                const date = moment(visit.date)
                return (
                    <VisitRow onClick={() => openMeeting(visit._id)} isCancelled={visit.status !== 'CONFIRMED'}>
                        <Day>
                            <h3>{date.format('D')}</h3>
                            <small>{date.format('MMM')}</small>
                        </Day>
                        <Details>
                            <small>{date.format('H:mm')}</small>
                            <br/>
                            <b>{visit.offer.title}</b>
                            <br/>
                            <small>Pracownik: {visit.worker?.firstname}</small>
                            <br/>
                            <small>Cena: {visit.price}zł</small>
                        </Details>
                    </VisitRow>
                )
            })}
        </PageContainer>
    )
}

export default ClientMeetingsDetails
