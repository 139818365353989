import axios from 'axios'
import { pushToast, updateMeetingsVersion } from '../actions/common.actions'
import store from '../store'
import moment from 'moment'

async function apiRequest(endpoint, { 
    method = 'get', 
    body,
    headers,
    successInfo
} = {}, callback) {
    // const API_URL =  'https://tst01.traf.to'
    const API_URL =  'https://api.traf.to'
    // const API_URL =  'https://loungebay.pl'
    const TOKEN = localStorage.getItem('ontime-token')

    try {
        const response = await axios({
            url: `${API_URL}/${endpoint}`,
            method,
            data: body,
            headers: {
                Authorization: `Bearer ${TOKEN}`,
                ...headers
            }
        })

        if (callback) {
            callback(response)
        }

        if (successInfo) {
            store.dispatch(pushToast({
                type: 'success',
                text: successInfo
            }))
        }

        return response.data
    } catch(e){
        // if (['post', 'put', 'delete', 'patch'].includes(method)) {
        //     store.dispatch(pushToast({
        //         type: 'error',
        //         text: 'Wystąpił błąd'
        //     }))
        // }
        throw e
    }
}

export default {
    getUser: () => {
        return apiRequest('user', {})
    },
    getProviders: async () => apiRequest('provider', {}),
    getAllProviders: async (query) => apiRequest(`provider/find/all?name=${query}`, {}),
    getProvider: async () => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}`, {})
    },
    getProviderOffers: async () => {
        const { provider } = store.getState()
        return apiRequest(`offer/provider/${provider._id}`)
    },
    getProviderClients: async (query, page = 0) => {
        const { provider } = store.getState()
        const clients = await apiRequest(`client/provider/${provider._id}?search=${query}&page=${page}`)
        return clients
    },
    getProviderChances: async () => {
        const { provider } = store.getState()
        return apiRequest(`chance/provider/${provider._id}`)
    },
    getWorkerScheduleForWeek: async (providerId, workerId, date) => await apiRequest(`provider/${providerId}/schedule/${workerId}/${date}`, {}),
    getWorkersScheduleForWeek: async (providerId, date) => {
        const { provider, currentDate } = store.getState()
        return apiRequest(`provider/${provider._id}/schedule/${currentDate.toISOString()}`)
    },
    isScheduleActive: async (providerId) => await apiRequest(`provider/${providerId}/schedule`, {}),
    setScheduleForDate: async (date, workerId, body) => {
        const { provider } = store.getState()
        const dateFormatted = moment(date).format('YYYY-MM-DD')
        await apiRequest(`provider/${provider._id}/schedule/${workerId}/${dateFormatted}`, { 
            method: 'put', 
            body, 
            successInfo: 'Zmieniono godziny pracy' 
        })
    },
    setSchedule: async (providerId, body, workerId = null) => await apiRequest(`provider/${providerId}/schedule/${workerId || ''}`, { method: 'put', body }),
    addWorkerScheduleDay: async (providerId, input, workerId, date) => await apiRequest(`provider/${providerId}/schedule/${workerId}/${date}`, { method: 'put', body: input }),
    getMeetings: async (providerId, date = null) => {
        const { currentDate, provider } = store.getState()
        return apiRequest(`meeting/provider/${provider._id}?date=${moment(currentDate).toISOString()}`)
    },
    getMeetingsForCurrentWeek: async () => {
        const { provider } = store.getState()
        return apiRequest(`meeting/provider/${provider._id}`)
    },
    getWorkers: async () => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/workers`)
    },
    getGaps: async () => {
        const { provider, currentDate } = store.getState()
        const weekStart = moment(currentDate).startOf('isoWeek').toISOString()
        return apiRequest(`provider/${provider._id}/gaps/${weekStart}`)

    },
    getPendingMeetings: async () => {
        const { provider } = store.getState()
        return apiRequest(`meeting/provider/${provider._id}/pending`)
    },
    getMeeting: (id) => apiRequest(`meeting/${id}`, {}),
    getChances: () => {
        return apiRequest(`chance`, {})
    },
    addChance: (body) => apiRequest('chance', {
        method: 'post',
        body
    }),
    editChance: (body) => apiRequest(`chance/${body._id}`, { method: 'put', body, successInfo: 'Edytowano okazję'  }),
    deleteChance: (chanceId) => apiRequest(`chance/${chanceId}`, { method: 'delete' }),
    addMeeting: (body) => apiRequest('meeting', {
        method: 'post',
        body,
        successInfo: 'Dodano wizytę'
    }),
    editMeeting: (id, body, sendSms) => {
        let url = `meeting/${id}`
        if (sendSms) url += `?sendSms=${sendSms}`
        return apiRequest(url, { method: 'put', body, successInfo: 'Edytowano wizytę' })
    },
    getMeetingsForClient: (providerId, clientId) => apiRequest(`meeting/provider/${providerId}/client/${clientId}`, { method: 'get' }),
    addWorker: (worker) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/worker`, { method: 'post', body: worker })
    },
    editWorker: (worker) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/worker`, { method: 'put', body: worker })
    },
    getDicts: () => apiRequest(`offer/segments/list`, {}),
    addOffer: (body) => apiRequest('offer', { method: 'post', body, successInfo: 'Dodano usługę' }),
    editOffer: (body) => apiRequest(`offer/${body._id}`, { method: 'put', body, successInfo: 'Edytowano usługę' }),
    editProvider: (body) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}`, { method: 'patch', body, successInfo: 'Edytowano dane salonu' })
    },
    removeOffer: (id) => apiRequest(`offer/${id}`, { method: 'delete' }),
    removeVisit: (meetingId) => apiRequest(`meeting/${meetingId}`, { method: 'delete' }),
    getCharities: () => apiRequest('charity', {}),
    addProvider: (body) => apiRequest('provider', { method: 'post', body, successInfo: 'Pomyślnie utworzono salon!' }),
    sendFile: (body) => apiRequest('file', { method: 'post', body, headers: {
        'Content-Type': 'multipart/form-data'
    }}),
    removeWorker: (userId) => {
        const { provider } = store.getState()
        return apiRequest(`user/${userId}/provider/${provider._id}`, { method: 'delete', successInfo: 'Pracownik został usunięty' })
    },
    setWorkerRole: (providerId, user, role) => apiRequest(`provider/${providerId}/role/${role}/user/${user._id}`, { method: 'put' }),
    setMeetingSeen: (meeting) => apiRequest(`meeting/set/seen`, { method: 'put', body: [meeting] }),
    confirmMeeting: (id) => apiRequest(`meeting/set/confirmed`, { method: 'put', body: { _id: id }, successInfo: 'Wizyta została potwierdzona'}),
    login: (email, password) => apiRequest('auth/login', { method: 'post', body: {
        username: email?.toLowerCase(),
        password
    }}),
    checkThirdPartyLogin: (token, type) => apiRequest('auth/check', { method: 'post', body: { type, token }}),
    addGap: (gap) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/gap`, { method: 'put', body: gap })
    },
    removeGap: (gap) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/gap`, { method: 'delete', body: gap })
    },
    getClients: (provider, search = null) => apiRequest(search ?  `client/provider/${provider._id}?search=${search}` : `client/provider/${provider._id}`, {}),
    addNoteToClient: (clientId, content) => {
        const { provider } = store.getState()
        return apiRequest(`client/${clientId}/note/${provider._id}`, { method: 'post', body: { content } })
    },
    deleteNoteFromClient: (clientId, noteId) => apiRequest(`client/${clientId}/note/${noteId}`, { method: 'delete' }),
    getClientById: (clientId) => apiRequest(`client/${clientId}`, {}),
    editClient: (clientId, dto) => apiRequest(`client/${clientId}`, { method: 'patch', body: dto, successInfo: 'Dane klienta zostały zmienione' }),
    addClient: (dto) => {
        const { provider } = store.getState()
        return apiRequest(`client/${provider._id}`, { method: 'post', body: dto, successInfo: 'Klient został utworzony' })
    },
    deleteClient: (clientId) => {
        const { provider } = store.getState()
        return apiRequest(`client/${clientId}/${provider._id}`, { method: 'delete' })
    },
    getEventsForProvider: (providerId, dateFrom) => apiRequest(`provider/${providerId}/events?dateFrom=${dateFrom}`, {}),
    getReport: (providerId, dateFrom, dateTo) => apiRequest(`provider/${providerId}/report?dateFrom=${dateFrom}&dateTo=${dateTo}`, {}),
    copyScheduleForWeek: (date, worker) => {
        const { provider } = store.getState()
        let url = `provider/${provider._id}/schedule/${date}` //?numOfWeeks=${numOfWeeks}`
        if (worker) {
            url += '&worker=' + worker
        }
        return apiRequest(url, { method: 'post', successInfo: 'Pomyślnie skopiowano grafik' })
    },
    createOrder: (body) => {
        const { provider } = store.getState()
        return apiRequest(`order?source=WEB`, { method: 'post', body: {
            ...body,
            providerId: provider._id
        }})
    },
    getProducts: () => {
        const { provider } = store.getState()
        return apiRequest(`product/provider/${provider._id}`, {})
    },
    // getProducts: (search = null) => {
    //     const { provider } = store.getState()
    //     return apiRequest(search ?  `provider/${provider._id}/products?search=${search}` : `product/provider/${provider._id}`, {})
    // },
    getProduct: (id) => apiRequest(`product/${id}`, {}),
    updateProducts: (meetingId, products) => apiRequest(`meeting/${meetingId}/products`, { method: 'put', body: products }),
    addProduct: (product) => {
        const { provider } = store.getState()
        return apiRequest(`product/provider/${provider._id}`, { method: 'post', body: product })
    },
    updateProduct: (product) => apiRequest(`product`, { method: 'put', body: product }),
    updateProductState: (id, state) => apiRequest(`product/${id}/state/${state}`, { method: 'put' }),
    updateSelectedWorkers: (workers) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/workers/selected`, { method: 'post', body: workers })
    },
    getSelectedWorkers: (providerId) => apiRequest(`provider/${providerId}/workers/selected`, {}),
    getWorkerDetails: (workerId) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/worker/${workerId}`, {})
    },
    getOpenDaysForMonth: (offerId, shift = 0) => {
        const { provider } = store.getState()
        const params = new URLSearchParams({ offerId, shift }).toString()
        return apiRequest(`provider/${provider._id}/open?${params}`)
    },
    getCalendarForDate: async (offerId, date) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/calendar?offerId=${offerId}&date=${date}`)
    },
    rateClient: async (meetingId, rateDto) => {
        return apiRequest(`meeting/${meetingId}/rate?type=provider`, { method: 'put', body: rateDto, successInfo: 'Oceniono klienta' })
    },
    notifyClient: async (body) => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/notify`, { method: 'post', body })
    },
    sendSms: async (params) => {
        const { provider } = store.getState()
        const query = new URLSearchParams(params).toString()
        return apiRequest(`provider/sms/${provider._id}?${query}`, { method: 'post' })
    },
    setClientBanned: async (clientId, value) => {
        const { provider } = store.getState()
        return apiRequest(`client/${clientId}/${provider._id}/ban?value=${value}`, { method: 'put', successInfo: 'Klient został zablokowany' })
    },
    getPackages: async () => {
        return apiRequest(`provider/packages/all`)
    },
    getSmsPackages: async () => {
        return apiRequest(`provider/packages/all?type=SMS_PACKAGE`)
    },
    getPaymentMethods: () => apiRequest(`dict?key=paymentMethods`, {}),
    patchProviderAdmin: (providerId, field, data) => apiRequest(`app/provider/${providerId}?field=${field}`, { method: 'put', body: data, successInfo: 'Operacja zakończona sukcesem' }),
    getCustomCategories: () => {
        const { provider } = store.getState()
        return apiRequest(`offer/provider/${provider._id}/category`, {})
    },
    addCustomCategory: (body) => {
        const { provider } = store.getState()
        return apiRequest(`offer/provider/${provider._id}/category`, { method: 'post', body, successInfo: 'Dodano ' })
    },
    getMeetingsByClientName: (query) => {
        const { provider } = store.getState()
        return apiRequest(`meeting/provider/${provider._id}/client?clientName=${query}`)
    },
    getProviderNotifications: () => {
        const { provider } = store.getState()
        return apiRequest(`provider/${provider._id}/notifications`)
    }
}
