import { Alert, Snackbar } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { hideToast } from '../../actions/common.actions'


function Toast({ type, text, id }) {
    const dispatch = useDispatch()
    const [isOpen, setOpen] = useState(true)
    
    const handleClose = useCallback(() => {
        setOpen(false)
        setTimeout(() => {
            dispatch(hideToast(id))
        }, 1000)
    })

    useEffect(() => {
        let timeout = setTimeout(() => {
            dispatch(hideToast(id))
        }, 7000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={isOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert variant="filled" onClose={handleClose} severity={type}>
                {text}
            </Alert>
        </Snackbar>
    )
}

export default Toast    