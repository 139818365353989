import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Avatar, Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pushModal, showAlert } from '../../../actions/common.actions';
import { Row } from '../../../components/layout/common';
import PageContainer from '../../../components/layout/PageContainer';
import StyledTableRow from '../../../components/layout/StyledTableRow';
import WorkerForm from '../../../forms/WorkerForm';
import DataService from '../../../services/DataService';
import Nav from '../Nav';

function WorkersPage() {
    const history = useHistory()
    const { 
        data: workers = [],
    } = useQuery({ queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    const selectableWorkersCount = provider.activePackage?.workers || 0

    const dispatch = useDispatch()

    const queryClient = useQueryClient()

    const selectedIds = useMemo(() => {
        return workers.filter(w => w.isSelected).map(w => w._id)
    }, [workers])

    const onRemove = (_id) => {
        dispatch(showAlert({
            title: 'Usuwanie pracownika',
            text: 'Czy na pewno chcesz usunąć tego pracownika?',
            onConfirm: async () => {
                await DataService.removeWorker(_id)
                queryClient.invalidateQueries({queryKey: ['workers']})
            }
        }))
    }

    const onEdit = useCallback((worker) => {
        dispatch(pushModal({
            Component: WorkerForm,
            header: 'Edytuj pracownika',
            extraProps: {
                _id: worker._id
            }
        }))
    }, [])

    const onChangeSelected = useCallback(async(_id) => {
        let result = selectedIds
        if (selectedIds.includes(_id)) {
            result = selectedIds.filter(id => _id !== id)
        } else {
            if (selectedIds.length === selectableWorkersCount) {
                result =_.slice(selectedIds, 0, selectableWorkersCount - 1)
            }
            result.push(_id)
        }

        await DataService.updateSelectedWorkers(result)
        queryClient.invalidateQueries({queryKey: ['workers']})
    }, [selectedIds])

    const onAdd = () => {
        dispatch(pushModal({
            header: 'Dodaj pracownika',
            Component: WorkerForm,
            extraProps: {
                history
            }
        }))
    }

    return (
        <PageContainer>
            <Nav title="Pracownicy"></Nav>
            <Button 
                style={{ marginBottom: 16 }} 
                onClick={onAdd} 
                size="large"
                startIcon={<FontAwesomeIcon icon={faPlus} />} 
                variant="outlined">Nowy pracownik</Button>
            <Alert style={{ marginBottom: 16 }} variant="standard" color="info">
                Maksymalna liczba pracowników przyjmujących rezerwacje online w Twoim obecnym planie: <b>{selectableWorkersCount}</b>
            </Alert>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>L.p</TableCell>
                            <TableCell align="center">Rezerwacje online</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Imię i nazwisko</TableCell>
                            <TableCell>Adres e-mail</TableCell>
                            <TableCell>Telefon</TableCell>
                            <TableCell align="center">Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workers.map((worker, index) => {
                            const { firstname, lastname, email, phone, thumb, isSelected, _id } = worker
                            const name = firstname + ' ' + lastname
                            return (
                                <StyledTableRow key={worker._id}> 
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell align="center" padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isSelected}
                                            onChange={() => onChangeSelected(_id)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Avatar src={thumb} />
                                    </TableCell>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>{email}</TableCell>
                                    <TableCell>{phone}</TableCell>
                                    <TableCell>
                                        <Row withMargin>
                                            <Button startIcon={<FontAwesomeIcon icon={faEdit}/>} onClick={() => onEdit(worker)} style={{ marginRight: 5 }} variant="outlined">Edytuj</Button>
                                            <Button startIcon={<FontAwesomeIcon icon={faTimes} />}  onClick={() => onRemove(_id)} color="error" variant="outlined">Usuń</Button>
                                        </Row>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    )
}

export default WorkersPage
