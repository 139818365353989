import { Avatar, Button } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Row } from '../../../components/layout/common'
import _ from 'lodash';
import DataService from '../../../services/DataService'
import ProviderForm from '../../../forms/ProviderForm';
import { useCallback } from 'react';
import store from '../../../store';
import { pushModal } from '../../../actions/common.actions';

function CalendarEditProviderBtn() {
    const { data : provider = {} } = useQuery({queryKey: ['provider'], queryFn: DataService.getProvider })

    const editProvider = useCallback(() => {
        store.dispatch(pushModal({
            header: 'Edytuj salon',
            Component: ProviderForm,
            extraProps: {
                initialData: provider
            }
        }))
    }, [provider])

    return (
        <Button onClick={editProvider} variant="contained" fullWidth style={{ marginTop: 32 }}>
            <Row vCentered marginBetween>
                <Avatar sx={{ width: 30, height: 30 }} src={provider.thumb} />
                <span>{_.truncate(provider.name)}</span>
            </Row>
        </Button>
    )
}

export default CalendarEditProviderBtn
