import { faEdit, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, MenuItem, Select } from '@mui/material';
import Input from '@mui/material/Input'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { pushModal, showAlert } from '../../../actions/common.actions';
import { Row } from '../../../components/layout/common';
import PageContainer from '../../../components/layout/PageContainer';
import StyledTableRow from '../../../components/layout/StyledTableRow';
import OfferForm from '../../../forms/OfferForm';
import DataService from '../../../services/DataService';
import store from '../../../store';
import Nav from '../Nav';
import UtilHelper from '../../../helpers/UtilHelper';
import _ from 'lodash';
import useCustomCategories from '../../../hooks/useCustomCategories';

function OffersPage() {

    // const { provider } = store.getState()
    const [selectedSegmentKey, setSelectedSegmentKey] = useState('')
    const [query, setQuery] = useState('')

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })
    
    const { 
        data: offers = [],
    } = useQuery({ queryKey: ['offers'], queryFn: DataService.getProviderOffers })

    const { 
        data: segments = [],
    } = useQuery({ queryKey: ['segments'], queryFn: DataService.getDicts })

    const customCategories = useCustomCategories()

    const queryClient = useQueryClient()

    const availableSegments = useMemo(() => {
        if (!provider.segments) return []
        return segments.filter(seg => provider.segments.includes(seg.key))
    }, [provider, segments])

    const filterByQuery = (list) => {
        if (query.length < 1) return list
        return list.filter(offer => offer.title.toLowerCase().includes(query.toLowerCase()))
    }

    const categories = useMemo(() => {
        if (!offers.length) return []
        const allUsedCategoriesKeys = _.uniq(offers.map(o => o.customCategory || o.category))

        const segmentCategories = segments.find(s => s.key === selectedSegmentKey)?.categories || []
        const segmentCustomCategories = customCategories.filter(cc => segmentCategories.map(c => c.key).includes(cc.category))

        return _.concat(segmentCategories, segmentCustomCategories)
            .filter(c => allUsedCategoriesKeys.includes(c.key))

        /* @TODO: sort */
         // .sort((a, b) => {
                //     const aIndex = categoriesOrder.findIndex(o => o === a.key)
                //     const bIndex = categoriesOrder.findIndex(o => o === b.key)
                //     if (aIndex >= 0 && bIndex >= 0) {
                //         return aIndex - bIndex
                //     } else if (aIndex < 0) {
                //         return 1
                //     } else if (bIndex < 0) {
                //         return -1
                //     }
                // })
        
    }, [offers, selectedSegmentKey])

    const displayedOffers = useMemo(() => { 
        return categories.map((category, index) => {
            const filteredOffers = offers.filter((offer) => {
                if (offer.customCategory) {
                    return offer.customCategory === category.key
                }
                return offer.category === category.key
            })
            return { 
                title: category.name,
                key: category.key,
                data: filterByQuery(filteredOffers),
                index
            }
        }).filter(r => r.data.length > 0)
    }, [offers, segments, categories, selectedSegmentKey, query])

    const categoriesWithOffers = useMemo(() => {
        return availableSegments
            .filter(segment => segment.key === selectedSegmentKey)
            .flatMap(segment => segment.categories)
            .map((category) => {
                const offersForCategory = offers.filter((o) => o.category === category.key )
                return { category, offers: _.orderBy(filterByQuery(offersForCategory), ['title'], ['asc']) }
            })
            .filter(row => row.offers.length > 0)
    }, [availableSegments, offers, selectedSegmentKey, query])

    useEffect(() => {
        if (Array.isArray(availableSegments)) {
            setSelectedSegmentKey(availableSegments[0]?.key)
        }
    }, [availableSegments])
    
    const onAdd = useCallback(() => {
        store.dispatch(pushModal({
            Component: OfferForm,
            header: 'Dodaj usługę',
            extraProps: {
                segment: selectedSegmentKey,
                segments
            }
        }))
    }, [selectedSegmentKey, segments])

    const onEdit = useCallback((offer) => {
        store.dispatch(pushModal({
            Component: OfferForm,
            header: 'Edytuj okazję',
            extraProps: {
                initialData: {
                    ...offer, 
                    workers: offer.workers.map(w => w._id)
                },
                segments,
                segment: selectedSegmentKey
            }
        }))
    }, [selectedSegmentKey, segments])

    const onRemove = useCallback((offer) => {
        store.dispatch(showAlert({
            text: 'Czy na pewno chcesz usunąć tę usługę?',
            title: 'Umów wizytę',
            onConfirm: async () => {
                await DataService.removeOffer(offer._id)
                queryClient.invalidateQueries({ queryKey: ['offers']})
            }
        }))
    }, [])

    return (
        <PageContainer>
            <Nav title="Usługi" />
            <Row marginBetween style={{ marginBottom: 16 }}>
                <Select
                    label="Widoczny obszar"
                    labelId="demo-simple-select-label"
                    onChange={(e) => setSelectedSegmentKey(e.target.value)}
                    value={selectedSegmentKey}>
                    {availableSegments.map((item) => (
                        <MenuItem value={item.key}>{item.name}</MenuItem>
                    ))}
                </Select>
                <Button onClick={onAdd} size="large" startIcon={<FontAwesomeIcon icon={faPlus} />} variant="outlined">Nowa usługa</Button>
            </Row>
            <Input
                style={{ marginBottom: 16 }}
                fullWidth
                value={query} 
                placeholder="Wyszukaj usługę po nazwie" 
                onChange={e => setQuery(e.target.value)} 
            />
            <TableContainer component={Paper}>
                {/* <h3 style={{ margin: 16}}>{row.category.name}</h3> */}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={30}>L.p</TableCell>
                            <TableCell>Nazwa</TableCell>
                            <TableCell align="center">Cena</TableCell>
                            <TableCell align="center">Czas trwania (min)</TableCell>
                            <TableCell align="center">Pracownicy</TableCell>
                            <TableCell align="center">Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedOffers.map(row => (
                            <>
                            <StyledTableRow>
                                <TableCell></TableCell>
                                <TableCell colSpan={6}><b>{row.title}</b></TableCell>
                            </StyledTableRow>
                            {row.data.map((offer, index) => {
                                const { _id, title, price, duration, workers } = offer
                                return (
                                    <StyledTableRow key={_id}>
                                        <TableCell width={25}>{index + 1}</TableCell>
                                        <TableCell>{title}</TableCell>
                                        <TableCell align="center">{price} zł</TableCell>
                                        <TableCell align="center">{duration} min.</TableCell>
                                        <TableCell align="center">
                                            <Row marginBetween>
                                                {workers.map(w => (
                                                    <Avatar title={w.firstname + ' ' + w.lastname} src={w.thumb}>
                                                        {UtilHelper.getFirstLetters(w)}
                                                    </Avatar>
                                                ))}
                                            </Row>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button startIcon={<FontAwesomeIcon icon={faEdit}/>} onClick={() => onEdit(offer)} style={{ marginRight: 5 }} variant="outlined">Edytuj</Button>
                                            <Button startIcon={<FontAwesomeIcon icon={faTimes} />}  onClick={() => onRemove(offer)} color="error" variant="outlined">Usuń</Button>
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    )
}

export default OffersPage
