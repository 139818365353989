import { useEffect } from "react";
import { useRef } from "react";
import { showAlert } from "../../actions/common.actions";
import store from "../../store";
import styles from '../Form.module.css';

const options = {
    // bounds: defaultBounds,
    componentRestrictions: { country: "pl" },
    fields: ["address_components", "geometry", "icon", "name", "formatted_address"],
    strictBounds: false,
    // types: ["street"],
};

export default function AddressInput({ onChange, value }) {
    let autocomplete;
    const inputRef = useRef()
    useEffect(() => {
        if (!inputRef.current) return;
        if (!window.google) { return }

        autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
        window.google.maps.event.addListener(autocomplete, 'place_changed', function() {
            const place = autocomplete.getPlace()
            const { formatted_address, address_components, geometry: { location: { lat, lng } } } = place
            //@TODO - move validation to higher level
            if (!address_components.find((c) => c.types.includes('street_number'))) {
                store.dispatch(showAlert({ type: 'error', text: 'Adres musi zawierać numer budynku'}))
                inputRef.current.value = value || ''
                return ;
            }
            const addressDto = {
                displayAddress: formatted_address,
                location: {
                    lat: lat(),
                    lng: lng()
                }
            }
            onChange(addressDto)
        });

    }, [inputRef])

    useEffect(() => {
        if (!inputRef.current) { return; }
        inputRef.current.value = value || ''
    }, [inputRef])

    return (
        <input className={styles.textInput} type="text" ref={inputRef}/>
    )
}