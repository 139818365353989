import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import AppNavigator from './AppNavigator';
import Alert from './components/Alert';
import RightModals from './components/RightModal/RightModals';
import ToastsContainer from './components/Toast/ToastsContainer';
import store from './store';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


const queryClient = new QueryClient()

function App() {

  const isMobile = useMediaQuery({ query: '(orientation: portrait)' })

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        alert('Wersja przeglądarkowa biz.traf.to nie jest jeszcze przystosowana do urządzeń mobilnych. Prosimy o pobranie aplikacji mobilnej')
      }, 1500)
    }
  }, [])

  return (
    
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
      <QueryClientProvider client={queryClient}>
          <Alert />
          <ToastsContainer/>
          <RightModals />
          <AppNavigator />
      </QueryClientProvider>
      </DndProvider>
    </Provider>
  )
}

export default App
