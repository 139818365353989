import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../actions/common.actions'
import PageContainer from '../../../components/layout/PageContainer'
import StyledTableRow from '../../../components/layout/StyledTableRow'
import DataService from '../../../services/DataService'
import Nav from '../Nav'

function ConfigPage({ }) {
    const dispatch = useDispatch()
    const { data : provider = {} } = useQuery({queryKey: ['provider'], queryFn: DataService.getProvider })
    const queryClient = useQueryClient()
    const { isBookingOpen, automaticConfirmation, smsNotifications } = provider

    const updateBookingOpen = async (checked) => {
        await DataService.editProvider({ isBookingOpen: checked })
        queryClient.invalidateQueries({ queryKey: ['provider'] })
    }

    const setIsBookingOpen = useCallback((checked) => {
        if (checked) {
            dispatch(showAlert({
                title: 'Rezerwacje online',
                text: 'Pamiętaj, że by przyjmować rezerwacje online musisz też wybrać dostępnych pracowników (zakładka "Pracownicy") oraz ustawić dla nich dostępność w kalendarzu',
                onConfirm: () => updateBookingOpen(checked)
            }))
        } else {
            updateBookingOpen(checked)
        }        
    }, [])

    const setAutomaticConfirmation = useCallback( async (checked) => {
        await DataService.editProvider({ automaticConfirmation: checked })
        queryClient.invalidateQueries({ queryKey: ['provider'] })  
    }, [])

    return (
        <PageContainer>
            <Nav title="Konfiguracja"></Nav>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Parametr</TableCell>
                            <TableCell align="center">Stan</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <TableCell>
                                Rezerwacje wizyt on-line
                            </TableCell>
                            <TableCell align="center">
                                <Switch onChange={e => setIsBookingOpen(e.target.checked)} checked={isBookingOpen} />
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>
                                Automatyczna akceptacja wizyt on-line
                            </TableCell>
                            <TableCell align="center">
                                <Switch onChange={e => setAutomaticConfirmation(e.target.checked)} checked={automaticConfirmation} />
                            </TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    )
}

export default ConfigPage
