import React from 'react'

function LoginPageGoogleButton() {
    return (
        <div>
            <div id="g_id_onload"
                data-client_id="372768794767-l6bl47f07jmc716m2csfdpi1c5rk03nq.apps.googleusercontent.com"
                data-login_uri="https://www.traf.to/login"
                data-callback="handleCredentialResponse"
                data-auto_prompt="false">
            </div>
            <div class="g_id_signin"
                data-type="standard"
                data-size="medium"
                data-width="300"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="circle"
                data-logo_alignment="left">
            </div>
        </div>
    )
}

export default LoginPageGoogleButton
