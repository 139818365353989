import { styled } from "@material-ui/core";
import { TableRow } from "@mui/material";

export default styled(TableRow)(({ theme, props }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&:highlight': {
      background: 'yellow'
    }
}));