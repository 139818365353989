import { faStar } from '@fortawesome/fontawesome-free-regular';
import { faChartBar, faMoneyBillAlt, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCashRegister, faGhost, faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControlLabel } from '@mui/material';
import Switch from "@mui/material/Switch";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { pushModal, showAlert } from '../actions/common.actions';
import withConfirmationButton from '../components/hoc/withConfirmationButton';
import { Row } from '../components/layout/common';
import PaidModule from '../components/PaidModule';
import ProductsTableForm from '../components/ProductsTableForm';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import store from '../store';
import Autocomplete from './Autocomplete/Autocomplete';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import RateForm from './RateForm';
import Submit from './Submit/Submit';
import _, { initial } from 'lodash'
import HiddenPhone from '../components/HiddenPhone';
import moment from 'moment';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function MeetingForm({ date, workerId, initialData, hide }) {
    const [newClient, setNewClient] = useState(initialData ? true : false) 
    const [rated, setRated] = useState(false)
    const [loading, setLoading] = useState(false)
    const isPast = initialData?.isPast
    const { 
        data: offers = [],
    } = useQuery({ queryKey: ['offers'], queryFn: DataService.getProviderOffers })

    const { 
        data: workers = [],
    } = useQuery({ queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false})

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    const { 
        data: availablePaymentMethods = [],
    } = useQuery({ queryKey: ['paymentMethods'], queryFn: DataService.getPaymentMethods, refetchOnMount: false, refetchOnReconnect: false })

    const { smsNotifications } = provider

    const queryClient = useQueryClient()

    const isFailed = initialData?.status === 'FAILED'

    const onRemove = useCallback(async() => {
        await DataService.removeVisit(initialData._id)
        queryClient.invalidateQueries({queryKey: ['meetings']})
        hide()
    }, [initialData])

    const onSetFailed = useCallback(async () => {
        await DataService.editMeeting(initialData._id, {  status: isFailed ? 'CONFIRMED' : 'FAILED' })
        queryClient.invalidateQueries({queryKey: ['meetings']})
        hide()
    }, [initialData, isFailed])

    const onChangeFinalized = useCallback(async () => {
        await DataService.editMeeting(initialData._id, { isFinalized: true })
        queryClient.invalidateQueries({queryKey: ['meetings']})
        hide()
    }, [initialData])

    const MeetingSchema = useMemo(() => {
        return (
            Yup.object().shape({
                date: Yup.date()
                  .required('Data jest wymagana'),
                price: Yup.number()
                  .min(0)
                  .max(5000)
                  .required('Cena jest wymagana'),
                worker: Yup.string().required('Pracownik jest wymagany'),  
                allOffers: Yup.array().required('Usługa jest wymagana'), 
                clientName: newClient ? Yup.string().max(50).min(3).required() : null, 
                // clientPhone: newClient ? Yup.string().required().min(9).max(9) : null, 
                client: !newClient ? Yup.string().required('Klient jest wymagany') : null, 
                duration: Yup.number().required().min(5).max(720),
                notes: Yup.string().max(500)
              })
        )
    }, [newClient])

    const availableOffers = useMemo(() => {
        const offersWithSubcategory = Array.isArray(provider.customCategories) ? offers.map(o => {
            const cc = provider.customCategories.find(custom => custom.key === o.customCategory)
            if (cc) {
                return { ...o, title: o.title + ` (${cc.name})`}
            }
            return o
        }) : offers
        return UtilHelper.parseOptions(_.orderBy(offersWithSubcategory, ['title'], ['asc']), '_id', 'title')
    }, [offers, provider])

    const showChangeDateAlertAndSubmit = (id, payload) => {
        store.dispatch(showAlert({
            text: `Czy na pewno przenieść tę wizytę na termin ${moment(payload.date).format('ddd DD MMMM H:mm')}?`,
            title: 'Potwierdzenie',
            onReject: () => {
                setLoading(false)
            },
            AdditionalContent: ({ state, setState }) => {
                return (
                    <FormControlLabel
                        sx={{ mt: 1 }}
                        control={
                            <Switch 
                                color='primary'
                                checked={state.sendSms} 
                                onChange={(e) => setState(prev => ({ ...prev, sendSms: e.target.checked }))} 
                            />
                        }
                        label="Wysłać sms z informacją do klienta?"
                    />
                )
            },
            onConfirm: async ({ sendSms }) => {
                await DataService.editMeeting(id, payload, sendSms)
                queryClient.invalidateQueries({queryKey: ['meetings']})
                queryClient.invalidateQueries({queryKey: ['products']})
                setLoading(false)
                hide()
            }
        }))
    }

    const formik = useFormik({
        initialValues: {
          date: (initialData?.date && new Date(initialData?.date)) || date,
          offer: initialData?.offer?._id,
          worker: initialData?.worker?._id || workerId,
          clientName: initialData?.clientName,
          clientPhone: initialData?.clientPhone,
          client: initialData?.client,
          price: initialData?.price,
          paymentMethod: initialData?.paymentMethod || 'CASH',
          type: initialData?.type || 'PROVIDER',
          provider: initialData?.provider || store.getState()['provider'],
          products: initialData?.products || [],
          isSmsConfirmation: initialData?.isSmsConfirmation || false,
          isSmsReminder: initialData?.isSmsReminder || smsNotifications.meetingReminder,
          duration: initialData?.duration || 0,
          notes: initialData?.notes || '',
          allOffers: initialData?.allOffers.map(o => o._id) || [],
          isFinalized: initialData?.isFinalized || false
        },
        validateOnMount: true,
        validationSchema: MeetingSchema,
        onSubmit: async values => {
            setLoading(true)
            const offer = values.allOffers[0]
            const additionalOffers = _.slice(values.allOffers, 1)
            if (initialData) {
                const initialDate = new Date(initialData.date).getTime()
                const newDate = new Date(values.date).getTime()
                if (initialDate !== newDate) {
                    showChangeDateAlertAndSubmit(initialData._id, { ...values, offer, additionalOffers })
                    return;
                } else {
                    await DataService.editMeeting(initialData._id, { ...values, offer, additionalOffers })
                }
            } else {
                await DataService.addMeeting({ ...values, offer, additionalOffers })
            }
            queryClient.invalidateQueries({queryKey: ['meetings']})
            queryClient.invalidateQueries({queryKey: ['products']})
            setLoading(false)
            hide()
        },
    });

    const availableWorkers = useMemo(() => {

        const selectedOffers = offers.filter(offer => formik.values.allOffers.includes(offer._id))
        if (!selectedOffers.length) {
            formik.setFieldValue('worker', null)
            return [];
        }

        const output = selectedOffers.map(offer => offer.workers).reduce((prev, curr) => {
            return _.intersectionBy(prev, curr, '_id')
        }, selectedOffers.map(offer => offer.workers)[0])

        if (!output.length) {
            formik.setFieldValue('worker', null)
        } else if (output.length === 1) {
            formik.setFieldValue('worker', output[0]._id)
        } else {
            const initial = initialData?.worker || output.find(oo => oo._id === workerId)
            formik.setFieldValue('worker', initial?._id || output[0]._id)
        }

        return UtilHelper
            .parseOptions(output, '_id', (w) => `${w.firstname} ${w.lastname}`)
            
    }, [workers, formik.values.allOffers, offers])


    const onOfferChange = (newOffers) => {
        let price = 0, duration = 0;
        newOffers.forEach(offer => {
            const foundOffer = offers.find(o => o._id === offer.value)
            price = price + foundOffer.price
            duration = duration + foundOffer.duration
        })

        setTimeout(() => {
            formik.setFieldValue('price', price)
            formik.setFieldValue('duration', duration)
        })
        
        formik.setFieldValue('allOffers', newOffers.map(o => o.value))
    }
    
    const onRateClient = useCallback(() => {
        store.dispatch(pushModal({
            Component: RateForm,
            header: 'Oceń klienta - ' + formik.values['clientName'],
            extraProps: {
                meetingId: initialData._id,
                callback: () => setRated(true)
            }
        }))
    }, [])

    const onClientSelect = (id) => {
        const { _id } = store.getState()['provider']
        DataService.getMeetingsForClient(_id, id)
            .then((visits) => {
                const lastVisit = visits[0]
                if (!lastVisit) return
                
                onOfferChange(lastVisit.allOffers.map(o => ({value: o._id})))
            })
    }

    
    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                {initialData && <div style={{ margin: '16px 32px', marginBottom: 0 }}>
                    <Row marginBetween className={styles.section}>
                        <Button hidden={true}></Button>
                        <Button 
                            startIcon={<FontAwesomeIcon icon={faTimes} />} 
                            color="error"
                            fullWidth
                            size="small"
                            onClick={UtilHelper.withConfirmation(onRemove)}
                            variant="outlined">Anuluj</Button>
                        <Button
                            onClick={UtilHelper.withConfirmation(onSetFailed)}
                            startIcon={<FontAwesomeIcon icon={faGhost} />} 
                            color="error"
                            fullWidth
                            size="small"
                            hidden={!isPast}
                            variant="outlined">{isFailed ? 'Cofnij nieobecność' : 'Nieobecność'}</Button>
                        <Button
                            onClick={onRateClient}
                            startIcon={<FontAwesomeIcon icon={faStar} />} 
                            color="info"
                            size="small"
                            fullWidth
                            hidden={!isPast || initialData.providerRating || rated}
                            variant="outlined">Oceń</Button>
                        <Button
                            onClick={UtilHelper.withConfirmation(onChangeFinalized)}
                            startIcon={<FontAwesomeIcon icon={faCashRegister} />} 
                            color="info"
                            fullWidth
                            size="small"
                            hidden={!isPast || initialData?.isFinalized}
                            variant="outlined">Finalizuj</Button>
                    </Row>
                </div>}
                <Fragment 
                    text="Klient" 
                    icon={faUser}
                    rightComponentVisible={!initialData}
                    RightComponent={() => (
                        <Row vCentered>
                            <span className={styles.newClient}>Nowy klient</span>
                            <Switch
                                onChange={(e) => setNewClient(e.target.checked)} 
                                checked={newClient}
                            /> 
                        </Row>
                    )}
                >                
                {newClient ? (
                    <>
                        <section className={styles.section}>
                            <label>Imię i nazwisko klienta</label>
                            <Input 
                                placeholder="max. 50 znaków"
                                error={Boolean(formik.errors['clientName']) && formik.dirty}
                                className={styles.textInput}
                                name="clientName"
                                id="clientName"
                                type="text"
                                value={formik.getFieldProps('clientName').value}
                                onChange={formik.handleChange}
                                disabled={initialData}
                            />
                        </section>
                        <section className={styles.section}>
                            <label>Telefon klienta</label>
                            {!initialData ? (
                            <input 
                                placeholder="Wpisz telefon"
                                className={styles.textInput}
                                max={999999999}
                                pattern="[0-9]{9}"
                                name="clientPhone"
                                id="clientPhone"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.getFieldProps('clientPhone').value}
                                disabled={initialData}
                            />
                            ) : (
                                <HiddenPhone phoneNumber={formik.getFieldProps('clientPhone').value} />
                            )}
                        </section>
                    </>
                ) : (
                    <section className={styles.section}>
                        <label>Wyszukaj klienta</label>
                        <Autocomplete
                            formik={formik}
                            selectCallback={onClientSelect}
                            id="client"
                            parseFn={client => `${client.name} (${client.phone || 'brak tel'}) ${client.user !== undefined ? '[Konto]' : ''}`}
                            fetchFn={DataService.getProviderClients}
                        />
                    </section>
                )}
                </Fragment>
                <Fragment icon={faChartBar} text="Dane podstawowe">
                    <section className={styles.section}>
                        <label>Data</label>
                        <DateTimePicker
                            className={styles.textInput}
                            value={formik.getFieldProps('date').value}
                            onChange={(date) => formik.setFieldValue('date', date)}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Usługi</label>
                        <Select
                            isMulti
                            options={availableOffers}
                            placeholder="Wybierz usługi"
                            styles={{
                                menuList: (provided) => ({
                                    ...provided,
                                    minHeight: 500
                                })
                            }}
                            value={UtilHelper.resolveValueFromDict(availableOffers, formik.values.allOffers)}
                            onChange={onOfferChange}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Czas trwania (min.)</label>
                        <Input
                            value={formik.getFieldProps('duration').value}
                            error={Boolean(formik.errors['duration']) && formik.dirty}
                            className={styles.textInput}
                            placeholder="podaj w min."
                            id="duration"
                            type="number"
                            max={5000}
                            name="duration"
                            onChange={formik.handleChange}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Pracownik</label>
                        <Select
                            isDisabled={!formik.values.allOffers?.length}
                            options={availableWorkers}
                            maxHeight={500}
                            placeholder="Wybierz pracownika"
                            value={UtilHelper.resolveValueFromDict(availableWorkers, formik.values.worker)}
                            onChange={option => formik.setFieldValue('worker', option.value)}
                        />
                    </section>
                </Fragment>
                <Fragment 
                    text="Dodatkowe" 
                    icon={faUser}
                >                
                <section className={styles.section}>
                    <label>Notatki</label>
                    <textarea 
                        placeholder="max. 500 znaków"
                        style={{ height: 100 }}
                        maxLength={500}
                        className={styles.textInput}
                        name="notes"
                        id="notes"
                        type="text"
                        value={formik.getFieldProps('notes').value}
                        onChange={formik.handleChange}
                    />
                </section>
                <section className={styles.section}>
                    {!initialData && (
                            <Row vCentered stretched>
                                <label>Potwierdzenie wizyty SMS</label>
                                <Switch 
                                    checked={formik.values['isSmsConfirmation']}
                                    onChange={e => formik.setFieldValue('isSmsConfirmation', e.target.checked)}
                                />
                            </Row>
                    )}
                    <Row vCentered stretched>
                        <label>Przypomnienie SMS 24h przed</label>
                        <Switch 
                            checked={formik.values['isSmsReminder']}
                            onChange={e => formik.setFieldValue('isSmsReminder', e.target.checked)}
                        />
                    </Row>
                </section>
                </Fragment>
                <Fragment text="Płatność" icon={faMoneyBillAlt}>
                    <section className={styles.section}>
                        <label>Cena</label>
                        <Input
                            value={formik.getFieldProps('price').value}
                            error={Boolean(formik.errors['price']) && formik.dirty}
                            className={styles.textInput}
                            placeholder="PLN"
                            id="price"
                            type="number"
                            max={5000}
                            name="price"
                            onChange={formik.handleChange}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Metoda płatności</label>
                        <Select
                            options={availablePaymentMethods}
                            placeholder="Wybierz metodę płatności"
                            value={UtilHelper.resolveValueFromDict(availablePaymentMethods, formik.getFieldProps('paymentMethod').value)}
                            onChange={option => formik.setFieldValue('paymentMethod', option.value)}
                        />
                    </section>
                </Fragment>
                {initialData && <Fragment text="Wykorzystane produkty" icon={faShoppingCart}>
                    <div className={styles.section}>
                        <PaidModule
                            moduleKey="PRODUCTS"
                            render={() => {
                                return (
                                    <ProductsTableForm 
                                        updateProducts={(newVal) => formik.setFieldValue('products', newVal)}
                                        productsArray={formik.values['products']}
                                    />  
                                )
                            }}
                        />                        
                    </div>
                </Fragment>}
                <div className={styles.space}></div>
                <Submit
                    disabled={!formik.isValid}
                    label="Zapisz"
                    loading={loading}
                    className={styles.submit} 
                    onSubmit={formik.handleSubmit}
                ></Submit>
        </form>
    )
}

export default MeetingForm
