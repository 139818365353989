import { Alert } from '@mui/material';
import React from 'react'
import Logout from '../../components/Logout/Logout';
import ProviderForm from '../../forms/ProviderForm';
import styles from './NewPage.module.css';

function NewPage({ user }) {
    return (
        <>
        <div className={styles.logout}>
            <Logout user={user}/>
        </div>
        <div className={styles.background}>
            <Alert color="info" variant="outlined" style={{ marginBottom: 32, width: 500 }}>
                Hej! Wygląda na to, że nie administrujesz żadnym miejscem na Traf.to.
                Dodaj swoje miejsce, by dotrzeć do nowych klientów i organizować swoją codzienną pracę z kalendarzem!
            </Alert>
            <div className={styles.box}>
                <ProviderForm />
            </div>
        </div>
        </>
    )
}

export default NewPage
