import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { Rating } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Switch from "@mui/material/Switch";
import Submit from './Submit/Submit';
import styled, { css } from 'styled-components';
import { Row } from '../components/layout/common';
import { useEffect } from 'react';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function InvoiceDataForm({ hide, user: userData }) {
    const [loading, setLoading] = useState()
    const { firstname, lastname } = userData

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    const { nipNumber: fetchedNipNumber, isCompany: fetchedIsCompany, address: { displayAddress: fetchedAddress }, name: fetchedName, invoiceData: initialData } = provider
    
    const InvoiceDataSchema = useMemo(() => {
        return (
            Yup.object({
                name: Yup.string().min(3).max(150).required(),
                nipNumber: Yup.string().min(9).max(10)
                    .when('isCompany', {
                        is: true,
                        then: Yup.string().required()
                    }),
                address: Yup.string().required(),
                isCompany: Yup.boolean()
            })
        )
    }, [])

    const fetchData = () => {
        // setDirty(true)
        const formattedName = fetchedIsCompany ? fetchedName : `${firstname} ${lastname}`
        formik.setValues({
            name: formattedName, 
            nipNumber: fetchedIsCompany ? fetchedNipNumber : undefined, 
            isCompany: fetchedIsCompany, 
            address: fetchedAddress,
            isCompany: fetchedIsCompany
        })
        // inputRef.current.setNativeProps({ text: fetchedAddress })
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            nipNumber: '',
            address: ''
        },
        validationSchema: InvoiceDataSchema,
        validateOnMount: true,
        onSubmit: async values => {
            try {
                setLoading(true)
                await DataService.editProvider({ invoiceData: values })
            } catch (e) {
                
            } finally {
                setLoading(false)
                hide()
            }
        },
    });

    useEffect(() => {
        if (!initialData) { return ; }
        formik.setValues({
            ...initialData
        })
    }, [initialData])

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <section className={styles.section} style={{ margin: '16px 32px', marginBottom: 0 }}>
                <Button 
                    fullWidth
                    size="small"
                    onClick={fetchData}
                    variant="outlined">Pobierz dane z profilu</Button>
                </section>
            <Fragment 
                icon={faChartBar} 
                text="Dane podstawowe"
                rightComponentVisible={true}
                RightComponent={() => (
                    <Row vCentered>
                        <span className={styles.newClient}>Działalność gosp.</span>
                        <Switch
                            onChange={(e) => formik.setFieldValue('isCompany', e.target.checked)} 
                            checked={formik.values.isCompany}
                        /> 
                    </Row>
                )}
            >
                <section className={styles.section}>
                    <label>Nazwa</label>
                    <Input
                        placeholder="max. 50 znaków"
                        error={Boolean(formik.errors['name']) && formik.dirty}
                        className={styles.textInput}
                        name="name"
                        id="name"
                        type="text"
                        value={formik.getFieldProps('name').value}
                        onChange={formik.handleChange}
                    />
                </section>
                {formik.values.isCompany && <section className={styles.section}>
                    <label>NIP</label>
                    <Input 
                        placeholder="max. 50 znaków"
                        error={Boolean(formik.errors['nipNumber']) && formik.dirty}
                        className={styles.textInput}
                        name="nipNumber"
                        id="nipNumber"
                        type="text"
                        value={formik.getFieldProps('nipNumber').value}
                        onChange={formik.handleChange}
                    />
                </section>}
                <section className={styles.section}>
                    <label>Adres</label>
                    <Input 
                        placeholder="max. 50 znaków"
                        error={Boolean(formik.errors['address']) && formik.dirty}
                        className={styles.textInput}
                        name="address"
                        id="address"
                        type="text"
                        value={formik.getFieldProps('address').value}
                        onChange={formik.handleChange}
                    />
                </section>
            </Fragment>
            <div className={styles.space}></div>
            <Submit
                disabled={!formik.isValid}
                label="Zapisz"
                loading={loading}
                className={styles.submit} 
                onSubmit={formik.handleSubmit}
            ></Submit>
        </form>
    )
}

export default InvoiceDataForm
