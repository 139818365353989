import React from 'react'
import { connect } from 'react-redux'
import Toast from './Toast'

function ToastsContainer({ toasts }) {
    return (
        <>
        {toasts.map((toast) => (
            <Toast {...toast}></Toast>
        ))}
        </>
    )
}

const mapStateToProps = (state) => ({
    toasts: state.toasts
})

export default connect(mapStateToProps)(ToastsContainer)
