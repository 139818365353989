import moment from 'moment';
import { HIDE_MODAL, PUSH_MODAL, REMOVE_MODAL, SET_ACTIVE_DAY, SET_ACTIVE_WORKER, SET_CHANCES, SET_CHARITIES, SET_CURRENT_DATE, SET_MEETINGS, SET_PROVIDER, SET_PROVIDERS, SET_SEGMENTS, SET_USER, UPDATE_MEETINGS_VERSION } from "../const/actions";
import { v4 as uuid } from 'uuid';

export const modal = (state = null, action) => {
    switch (action.type) {
        // case SET_MODAL:
        //     return action.config
        default:
            return state
    }
}

export const provider = (state = JSON.parse(localStorage.getItem('provider')), action) => {
    switch (action.type) {
        case SET_PROVIDER:
            localStorage.setItem('provider', JSON.stringify(action.provider))
            return action.provider
        default:
            return state
    }
}

export const meetings = (state = [], action) => {
    switch (action.type) {
        case SET_MEETINGS:
            return action.meetings
        default:
            return state
    }
}

export const activeDay = (state = moment().toDate(), action) => {
    switch (action.type) {
        case SET_ACTIVE_DAY:
            return action.day
        default:
            return state
    }
}

export const segments = (state = [], action) => {
    switch (action.type) {
        case SET_SEGMENTS:
            return action.segments
        default:
            return state
    }
}

export const chances = (state = [], action) => {
    switch(action.type) {
        case SET_CHANCES:
            return action.chances
        default:
            return state
    }
}

export const providers = (state = [], action) => {
    switch (action.type) {
        case SET_PROVIDERS:
            return action.providers
        default:
            return state
    }
}

export const charities = (state = [], action) => {
    switch (action.type) {
        case SET_CHARITIES:
            return action.charities
        default:
            return state
    }
}

export const user = (state = null, action) => {
    switch (action.type) {
        case SET_USER:
            return action.user
        default:
            return state
    }
}

export const meetingsVersion = (state = 0, action) => {
    switch (action.type) {
        case UPDATE_MEETINGS_VERSION:
            return action.version 
        default: 
            return state
    }
}

export const currentDate = (state = moment().toDate(), action) => {
    switch (action.type) {
        case SET_CURRENT_DATE:
            return action.date
        default:
            return state
    }
}

export const activeWorker = (state = null, action) => {
    switch (action.type) {
        case SET_ACTIVE_WORKER:
            return action.worker
        default:
            return state
    }
}

export const modals = (state = [], action) => {
    switch (action.type) {
        case PUSH_MODAL:
            return [...state, { ...action.modal, visible: true, id: uuid() }]
        case HIDE_MODAL:
            return state.filter(modal => modal.id != action.id)
        default:
            return state
    }
}

export const toasts = (state = [], action) => {
    switch (action.type) {
        case 'PUSH_TOAST':
            return [...state, { ...action.toast, visible: true, id: uuid() }]
        case 'HIDE_TOAST':
            return state.filter(modal => modal.id != action.id)
        default:
            return state
    }
}

export const alert = (state = {}, action) => {
    switch (action.type) {
        case 'SHOW_ALERT':
            return { ...action.config, visible: true }
        default:
            return state
    }
}

export const loading = (state = false, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return action.value
        default:
            return state
    }
}