import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import * as Yup from 'yup';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';
import moment from 'moment';
import styled, { css } from 'styled-components';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function GapForm({ hide, initialData, worker }) {
    const [loading, setLoading] = useState(false)
    const { 
        data: workers = [],
    } = useQuery({ queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })

    const queryClient = useQueryClient()

    const GapSchema = useMemo(() => {
        return (
            Yup.object().shape({
                dateFrom: Yup.date()
                  .required('Data jest wymagana'),
                dateTo: Yup.date().required(),
                label: Yup.string().max(200)
                // worker: Yup.string()
              })
        )
    }, [])
    
    const availableWorkers = useMemo(() => {
        return UtilHelper.parseOptions(workers, '_id', (w) => `${w.firstname} ${w.lastname}`)
    }, [workers])

    const formik = useFormik({
        initialValues: {
          dateFrom: initialData?.dateFrom || moment().startOf('hour').toDate(),
          dateTo: initialData?.dateTo || moment().add(1, 'hour').startOf('hour').toDate(),
          worker: initialData?.worker?._id || null,
          type: 'SINGLE'
        },
        validateOnMount: true,
        validationSchema: GapSchema,
        onSubmit: async values => {
            setLoading(true)
            await DataService.addGap(values)
            queryClient.invalidateQueries({queryKey: ['gaps']})
            setLoading(false)
            hide()
        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                <Fragment icon={faChartBar} text="Szczegóły">
                    <section className={styles.section}>
                        <label>Data od</label>
                        <DateTimePicker
                            className={styles.textInput}
                            value={formik.getFieldProps('dateFrom').value}
                            onChange={(date) => formik.setFieldValue('dateFrom', date)}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Data do</label>
                        <DateTimePicker
                            className={styles.textInput}
                            value={formik.getFieldProps('dateTo').value}
                            onChange={(date) => formik.setFieldValue('dateTo', date)}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Etykieta</label>
                        <Input
                            placeholder="max. 200 znaków"
                            error={Boolean(formik.errors['label']) && formik.dirty}
                            className={styles.textInput}
                            name="label"
                            id="label"
                            type="text"
                            value={formik.getFieldProps('label').value}
                            onChange={formik.handleChange}
                        />
                    </section>
                    <section className={styles.section}>
                        <label>Pracownik</label>
                        <Select
                            options={availableWorkers}
                            placeholder="Wybierz pracownika"
                            value={UtilHelper.resolveValueFromDict(availableWorkers, formik.getFieldProps('worker').value)}
                            onChange={option => formik.setFieldValue('worker', option.value)}
                        />
                    </section>
                </Fragment>
                <div className={styles.space}></div>
                <Submit
                    disabled={!formik.isValid}
                    label="Zapisz"
                    loading={loading}
                    className={styles.submit} 
                    onSubmit={formik.handleSubmit}
                ></Submit>
        </form>
    )
}

export default GapForm
