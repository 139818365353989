import React, { useMemo } from 'react'
import Select from 'react-select'
import UtilService from '../../services/UtilService'

const hours = UtilService.getHours()

function ProviderShiftHoursSelect({ daySchedule, isActive, which, weekDay, onChange }) {

    const availableHours = useMemo(() => {
        return {
            from: hours.filter(hour => hour.value < daySchedule?.to || !daySchedule?.to),
            to: hours.filter(hour => hour.value > daySchedule?.from || !daySchedule?.from),
        }
    }, [hours, daySchedule])

    return (
        <Select
            isDisabled={!isActive}
            options={availableHours[which]}
            placeholder=""
            value={hours.find(h => h.value === (daySchedule || {})[which])}
            onChange={(hour) => onChange({
                type: 'CHANGE_HOUR', 
                weekDay, 
                which, 
                hour: hour.value
            })}
            styles={styles}
        ></Select>
    )
}

export default ProviderShiftHoursSelect

const styles = {
    // indicatorsContainer: (provided) => ({ display: 'none' }),
    control: (provided) => ({ ...provided }),
    container: (provided) => ({ ...provided, flex: 1 }),
    valueContainer: (provided) => ({ ...provided, justifyContent: 'center' })
}

