import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

function RegisterPage({ route }) {

    const { search } = useLocation()

    const user = new URLSearchParams(search)

    return (
        <div>{user.values}</div>
    )
}

export default RegisterPage
