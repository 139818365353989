import React from 'react'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import AdminRoute from './components/AdminRoute'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import NewPage from './pages/NewPage/NewPage'
import PanelPage from './pages/PanelPage/PanelPage'
import PaymentPage from './pages/PaymentPage/PaymentPage'
import ProvidersPage from './pages/ProvidersPage/ProvidersPage'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import AdminPage from './pages/AdminPage/AdminPage'

export default function AppNavigator() {
    return (
        <Router>
            <Switch>
                <Route path='/panel' render={() => <AuthenticatedRoute render={({ user }) => <PanelPage user={user} />}/>}></Route>
                <Route path='/salony' component={() => <AuthenticatedRoute render={({ user }) => <ProvidersPage user={user}/>} />}></Route>
                <Route path='/nowy' component={() => <AuthenticatedRoute render={({ user }) => <NewPage user={user}/>} />}></Route>
                <Route path='/platnosc' render={() => <AuthenticatedRoute render={({ user }) => <PaymentPage user={user} />}/>}></Route>
                <Route path='/login' component={LoginPage}></Route>
                <Route path='/rejestracja' component={RegisterPage}></Route>
                <Route path='/superuser' render={() => <AdminRoute render={({ user }) => <AdminPage user={user} />}/>}></Route>
                <Route exact path='/' render={() => <Redirect to='/panel'></Redirect>}></Route>
            </Switch>
        </Router>
    )
}
