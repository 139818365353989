import styled, { css } from 'styled-components'; 

export const PrimaryButton = styled.button`
    height: 50px;
    background: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: 3px solid var(--main-color);
    border-radius: 10px;
    min-width: 192px;
    transition: all 300ms ease-in-out;
    &:hover {
        color: var(--main-color);
        background: white;
    }
`

export const Circle = styled.div`
    background: ${props => props.color};
    width: 15px;
    height: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-family: 'Arial';
    justify-content: center;
    color: white;
    font-size: 10px;
    text-shadow: 0 0 6px rgba(0,0,0,50%);
    font-weight: 600;
`

export const WorkerThumb = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    ${props => props.marginBetween && css`
        & > * {
            margin-right: 5px;
        }
    `}
    ${props => props.stretched && css`
        justify-content: space-between;
    `}
    ${props => props.vCentered && css`
        align-items: center;
    `}
`

export const Column = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const RoundItem = styled.div`
    padding: 16px;
    cursor: pointer;
    margin: 16px;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 12px rgba(0,0,0,0.15);
    border-radius: 10px;
    align-items: center;
`

export const Table = styled.table`
    width: 100%;
    th {
        text-align: left;
        padding: 8px;
        font-weight: 500;
    }

    thead {
        background: #efefef;
    }

    td {
        padding: 8px;        
    }

    ${props => props.bottomBordered && css`
        td {
            border-bottom: 1px solid #efefef;
        }
    `}

    tr {
        transition: all 200ms ease-in-out;
    }

    tr:hover {
        background: #efefef;
        cursor: pointer;
    }

    tr.tt-table-highlight {
        background: #efefef;
    }
`


export const ItemsTable = styled.table`
    width: 100%;
    thead {
        background: #efefef;
        height: 40px;
        
        th {
            font-weight: 500;
        }
    }

    td {
        padding: 10px;
        border-bottom: 1px solid #efefef;
        input {
            border: none;
            background: transparent;
            width: 100%;
            font-size: 16px;
            height: 40px;
            text-indent: 10px;
            font-family: 'Source Sans Pro'
        }
    }
`

export const SmallButton = styled.button`
    border: 1px solid ${props => props.color || 'grey'};
    color: ${props => props.color || 'grey'};
    background: white;
    border-radius: 10px;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    padding: 5px 10px;
    transition: all 200ms linear;
    :hover {
        background: #efefef;
    }
`

export const InvisibleButton = styled.button`
    padding: 5px;
    border: 0;
    background: transparent;
`

export const SecondaryButton = styled.button`
    padding: 5px;
    cursor: pointer;
    border: 3px solid var(--main-color);
    border-radius: 10px;
    color: ${props => !props.selected ? 'var(--main-color)' : 'white'};
    font-size: 16px;
    font-weight: 600;
    background: ${props => props.selected ? 'var(--main-color)' : 'white'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    transition: all 300ms ease-in-out;
    &:hover {
       background: var(--main-color);
       color: white;
    }
    height: 50px;
    min-width: 192px;
`

export const ChanceButton = styled.button`
    position: fixed;
    padding: 32px;
    bottom: var(--space);
    right: var(--space);
    border-radius: 50%;
    color: white;
    font-size: 32px;
    border: 0;
    background: var(--main-color);
`