import { Avatar, Button } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Row } from './layout/common'
import { CropperContainer, Fade } from './layout/thumb'

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })


const getCroppedImg = async (url, crop) => {
    const image = await createImage(url)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    /* setting canvas width & height allows us to 
    resize from the original image resolution */
    canvas.width = 500
    canvas.height = 500
    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob)
        }, 'image/jpeg')
    })
}

function Thumb(props) {
    const { src, style, callback } = props
    const [input, setInput] = useState(null)
    const [image, setImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [cropArea, setCropArea] = useState(null)
    const [zoom, setZoom] = useState(1)

    const onCropComplete = useCallback(async () => {
        const img = await getCroppedImg(image, cropArea)
        const reader = new FileReader();
        reader.readAsDataURL(img); 
        reader.onloadend = function() {
            var base64data = reader.result;   
            const base64WithoutPrefix =  base64data.substring(base64data.indexOf(','))
            callback(base64WithoutPrefix)            
            input.files = null
            setImage(null)
        }
    }, [cropArea])

    const onCancel = useCallback(() => {
        setImage(null)
        input.files = null
    })

    const onEdit = useCallback(() => {
        input.click()
    }, [input])

    useEffect(() => {
        if (!input) { return ; }
        input.onchange = async function() {
            try {
                const file = this.files[0];
                const reader = new FileReader();
                reader.onloadend = function() {
                    setImage(reader.result)
                }
                reader.readAsDataURL(file);
            } catch (e) {
                console.log(e)
            }
            
        }
    }, [input])

    return (
        <>
            {image && (
                <Fade>
                    <CropperContainer>
                        <Cropper
                            image={image}                            
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            cropSize={{
                                width: 500,
                                height: 500
                            }}
                            onCropChange={setCrop}
                            onCropComplete={(_, cropAreaPixels) => setCropArea(cropAreaPixels)}
                            onZoomChange={setZoom}
                        />
                    </CropperContainer>
                    <Row vCentered marginBetween>
                        <Button variant="contained" onClick={onCropComplete}>Zatwierdź</Button>
                        <Button variant="outlined" onClick={onCancel}>Anuluj</Button>
                    </Row>
                </Fade>
            )}
            <input style={{
                display: 'none'
            }} ref={setInput} type="file" />
            <Avatar 
                onClick={onEdit} 
                {...props}
                style={{ cursor: 'pointer', width: 100, height: 100 }} 
            />
        </>
    )
}

export default Thumb
