import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import React, { useCallback } from 'react';
import { pushModal } from '../../../actions/common.actions';
import PageContainer from '../../../components/layout/PageContainer';
import InvoiceDataForm from '../../../forms/InvoiceDataForm';
import store from '../../../store';
import Nav from '../Nav';

function InvoicesPage({ user }) {

    const onEdit = useCallback(() => {
        store.dispatch(pushModal({
            Component: InvoiceDataForm,
            header: 'Edytuj dane do faktury',
            extraProps: {
                user
            }
        }))
    }, [])

    return (
        <PageContainer>
            <Nav title="Faktury" />
            <Button onClick={onEdit} size="large" startIcon={<FontAwesomeIcon icon={faPlus} />} variant="outlined">Dane do faktury</Button>
        </PageContainer>
    )
}

export default InvoicesPage
