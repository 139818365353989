import { useQuery } from "@tanstack/react-query"
import DataService from "../services/DataService"
import _, { set } from 'lodash'
import { Button } from '@mui/material';
import styles from '../forms/Form.module.css';
import { useState } from "react";
import useWorkerPermission from "../hooks/useWorkerPermissions";

export default function HiddenPhone({ phoneNumber }) {

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    const hasPhoneVisibility = useWorkerPermission('READ_CLIENT')

    const [hidden, setHidden] = useState(!hasPhoneVisibility && _.get(provider, 'customConfig.clientPhoneHidden'))

    const showNumber = () => {
        setHidden(false)
        DataService.sendSms({ type: 'client-phone-unlocked' })
    }

    if (hidden) {
        return (
            <Button 
                // startIcon={<FontAwesomeIcon icon={faTimes} />} 
                color="info"
                fullWidth
                size="small"
                onClick={showNumber}
                variant="outlined">Pokaż numer</Button>
        )
    }

    return (
        <input 
            placeholder="Brak nr tel"
            className={styles.textInput}
            max={999999999}
            pattern="[0-9]{9}"
            name="clientPhone"
            id="clientPhone"
            type="text"
            value={phoneNumber}
            disabled
        />
    )
}