import { StylesProvider } from '@material-ui/core'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import { useEffect } from 'react'
import UtilHelper from '../../helpers/UtilHelper'
import DataService from '../../services/DataService'
import Select from 'react-select'
import BookingDetailsDayPicker from './BookingDetailsDayPicker'
import _ from 'lodash'
import styles from '../../forms/Form.module.css'
import moment from 'moment'
import styled from 'styled-components';

const NoHoursContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
`

function BookingDetails(props) {
    const { 
        selectedHour, 
        setSelectedHour,
        offerId,
        selectedWorker,
        setSelectedWorker,
        setStep
    } = props

    const [allHours, setAllHours] = useState([])
    const [selectedDay, selectDay] = useState()

    useEffect(() => {
        if (!selectedDay) return;
        setSelectedWorker(null)
        setSelectedHour(null)
        setStep(2)
        DataService.getCalendarForDate(offerId, selectedDay)
            .then((calendar) => {
                setAllHours(calendar)
            })
    }, [selectedDay, offerId])

    const { 
        data: allWorkers = [],
    } = useQuery({ queryKey: ['workers'], queryFn: DataService.getWorkers, refetchOnWindowFocus: false })

    const workers = useMemo(() => {
        return allHours
            .filter(row => row.availableHours.length > 0)        
            .map(row => allWorkers.find(worker => worker._id === row.worker))
            .filter(_.isObject)
    }, [allHours, allWorkers])

    const availableWorkers = useMemo(() => {
        return UtilHelper.parseOptions(workers, '_id', (w) => `${w.firstname} ${w.lastname}`)
    }, [workers])

    const availableHours = useMemo(() => {
        const foundRow = allHours.find(row => row.worker === selectedWorker)
        const hours = foundRow?.availableHours || []
        return hours.map(h => ({ label: moment(h).format('H:mm'), value: h }))
    }, [selectedWorker, selectedDay, allHours])

    const noAvailableHours = !allHours.find(row => row.worker == null)?.availableHours?.length

    return (
        <>
            <section>
                <BookingDetailsDayPicker
                    {...props} 
                    setSelectedDate={selectDay}
                    selectedDate={selectedDay}
                />
            </section>
            {noAvailableHours ? (
                <NoHoursContainer>
                    <span>Brak dostępnych godzin</span>
                </NoHoursContainer>
            ) : (
            <>
                <section className={styles.section}>
                    <label>Pracownik</label>
                    <Select
                        onChange={(worker) => {
                            setSelectedWorker(worker.value)
                            setSelectedHour(null)
                            setStep(2)
                        }}
                        placeholder="Wybierz pracownika"
                        options={availableWorkers}
                        value={UtilHelper.resolveValueFromDict(availableWorkers, selectedWorker)}
                    />
                </section>
                <section className={styles.section}>
                    <label>Godzina</label>
                    <Select
                        onChange={(h) => {
                            setSelectedHour(h.value)
                            setStep(3)
                        }}
                        placeholder="Wybierz godzinę"
                        options={availableHours}
                        value={UtilHelper.resolveValueFromDict(availableHours, selectedHour)}
                    />
                </section>
            </>
            )}
        </>
    )
}

export default BookingDetails
