import { Avatar, Input, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import { useContext, useEffect, useRef, useState } from "react"
import DataService from "../../../services/DataService"
import styled from 'styled-components'
import moment from 'moment'
import store from "../../../store"
import { pushModal, setLoading } from "../../../actions/common.actions"
import MeetingForm from "../../../forms/MeetingForm"
import UIContext from "../../../context/UIContext"

const Container = styled.div`
    position: relative;
    min-width: 250px;
`

const Results = styled.div`
    position: absolute;
    top: 40px;
    z-index: 1000;
    background: white;
    box-shadow: 0 0 12px #efefef;
    border-radius: 10px;
    width: 500px;
`

function CalendarClientsVisitsDropdown(props) {

    const changeLoading = (value) => store.dispatch(setLoading(value))
    const [query, setQuery] = useState('')
    const [results, setResults] = useState([])

    const { onDateChange } = useContext(UIContext)

    const queryTimeout = useRef()

    const onChangeQuery = (e) => {
        const { value } = e.target
        setQuery(value)
        if (queryTimeout.current) {
            clearTimeout(queryTimeout.current)
        }

        if (value.length < 3) {
            setResults([])
            return;
        }

        queryTimeout.current = setTimeout(() => {
            changeLoading(true)
            DataService.getMeetingsByClientName(value)
                .then(setResults)
                .finally(() => changeLoading(false))
        }, 200)
    }

    const onBlur = (e) => {
        setTimeout(() => {
            setResults([])
        }, 200)
    }

    const formatTitle = (item) => {
        const date = moment(item.date).format('DD.MM H:mm')
        return `${date}, ${item.formattedTitle}`
    }

    const openMeeting = async (meeting) => {
        onDateChange(new Date(meeting.date))
        store.dispatch(setLoading(true))
        const item = await DataService.getMeeting(meeting._id)
        store.dispatch(setLoading(false))
        store.dispatch(pushModal({
            header: 'Edycja wizyty',
            Component: MeetingForm,
            item
        }))
    }

    return (
        <Container>
            <Input
                onBlur={onBlur}
                value={query} 
                fullWidth
                onChange={onChangeQuery}
                placeholder="Wyszukaj wizytę klienta" 
            />
            {results.length > 0 && (
                <Results>
                    <List sx={{ bgcolor: 'white' }}>
                        {results.map(item => (
                            <ListItem style={{ cursor: 'pointer'}} onClick={(e) => {
                                openMeeting(item)
                            }} key={item.meeting}>
                                <ListItemAvatar>
                                    <Avatar>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.clientName} secondary={formatTitle(item)} />
                            </ListItem>
                        ))}
                    </List>
                </Results>
            )}
        </Container>
    )
}

export default CalendarClientsVisitsDropdown
