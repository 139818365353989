import { useQuery } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import PageContainer from '../../components/layout/PageContainer'
import DataService from '../../services/DataService'
import { Avatar, Button, Chip, Input, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import StyledTableRow from '../../components/layout/StyledTableRow'
import DateTimePicker from 'react-datetime-picker';
import UtilHelper from '../../helpers/UtilHelper'
import styles from '../../forms/Form.module.css';
import Select from 'react-select';
import store from '../../store'
import { showAlert } from '../../actions/common.actions'
import { Row } from '../../components/layout/common'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

function InputWithTick (props) {
    const [value, setValue] = useState(props.initialValue)
    const [dirty, setDirty] = useState(false)
    return (
        <Row>
            <input
                {...props}  
                key={props.providerId}
                value={value} 
                onChange={e => {
                    setValue(e.target.value)
                    setDirty(true)
                }}
            />
            <IconButton onClick={() => props.onConfirm(value)} disabled={!dirty} variant="contained" color="success">
                <FontAwesomeIcon icon={faCheck}/>
            </IconButton>
        </Row>
    )
}

function AdminPage() {

    // const { data: providers = [], refetch } = useQuery({ 
    //     queryFn: DataService.getAllProviders,
    //     queryKey: ['allProviders'],
    //     refetchOnWindowFocus: false
    // })

    const [providers, setProviders] = useState([])

    const { data: packages = [] } = useQuery({ 
        queryKey: ['packages'],
        queryFn: DataService.getPackages 
    })

    const availablePackages = useMemo(() => {
        return UtilHelper.parseOptions(packages, 'key', 'name')
    }, [packages])

    const [query, setQuery] = useState('')

    const updateProviders = () => DataService.getAllProviders(query).then(setProviders)

    useEffect(() => {
        updateProviders()
    }, [query])

    // const filteredProviders = providers.filter(provider => provider.name.toLowerCase().includes(query.toLowerCase()))
    
    const onPatch = (providerId, field, data) => {
        store.dispatch(showAlert({
            text: 'Czy na pewno chcesz wykonać tę akcję?',
            title: 'Potwierdzenie',
            onConfirm: () => {
                DataService.patchProviderAdmin(providerId, field, data)
                    .then(updateProviders)
            }
        }))
    }

    return (
        <>
        <PageContainer>
            <Input
                style={{ marginBottom: 16 }}
                fullWidth
                value={query} 
                placeholder="Wyszukaj po nazwie" 
                onChange={e => setQuery(e.target.value)} 
            />  
            <TableContainer style={{ overflow: 'visible' }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Nazwa</TableCell>
                            <TableCell>Pakiet</TableCell>
                            <TableCell>Pakiet do</TableCell>
                            <TableCell>Liczba SMS</TableCell>
                            <TableCell>Priorytet</TableCell>
                            <TableCell>Usuń</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {providers.map((provider, index) => {
                        const { thumb, name, status, address } = provider
                        const isDeleted = status === 'DELETED'
                        return (
                            <StyledTableRow key={provider._id} style={{ opacity: isDeleted ? 0.5 : 1, textDecoration: isDeleted ? 'line-through' : 'none' }}>
                                <TableCell style={{ width: 60 }} align="center">
                                    <Avatar src={thumb}></Avatar>
                                </TableCell>
                                <TableCell style={{ width: 250 }}>
                                    {name}<br/>
                                    <small style={{ color: 'silver' }}>{address.displayAddress} </small>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        options={availablePackages}
                                        placeholder="Pakiet"
                                        value={UtilHelper.resolveValueFromDict(availablePackages, provider.activePackage.key)}
                                        onChange={(item) => {
                                            onPatch(provider._id, 'package', { packageItem: packages.find(p => p.key === item.value) })
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    {provider.activePackage.activeTo && <DateTimePicker
                                        className={styles.textInput}
                                        value={new Date(provider.activePackage.activeTo)}
                                        onChange={(date) => onPatch(provider._id, 'packageActiveTo', { date })}
                                    />}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', width: 70 }}>
                                    <InputWithTick
                                        providerId={provider._id}
                                        style={{ width: 60 }}
                                        initialValue={provider.sms}
                                        className={styles.textInput}
                                        type="number"
                                        max={5000}
                                        name="duration"
                                        onConfirm={(value) => {
                                            onPatch(provider._id, 'sms', { sms: value })
                                        }}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', width: 70 }}>
                                    <InputWithTick
                                        providerId={provider._id}
                                        style={{ width: 60 }}
                                        initialValue={provider.priority}
                                        className={styles.textInput}
                                        type="number"
                                        max={5000}
                                        name="priority"
                                        onConfirm={(value) => {
                                            onPatch(provider._id, 'priority', { priority: value })
                                        }}                                    />
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', width: 70 }}>
                                <Button 
                                    color="error"
                                    fullWidth
                                    size="small"
                                    onClick={() => {
                                        onPatch(provider._id, 'status', { status: 'DELETED' })
                                    }}
                                    variant="outlined">Usuń</Button>
                                </TableCell>
                            </StyledTableRow>
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
        </>
    )
}

export default AdminPage
