import { Chip, Divider } from "@mui/material"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import moment from "moment"
import styled from 'styled-components'
import { setCurrentDate } from "../actions/common.actions"
import DataService from "../services/DataService"
import store from "../store"
import { Row } from "./layout/common"
import { connect } from "react-redux"

const Container = styled.div`
    font-size: 12px;
`

function ProviderNotifications({ hide, currentDate }) {

    const { data: notifications = [] } = useQuery({ queryKey: ['notifications'], queryFn: DataService.getProviderNotifications })

    const queryClient = useQueryClient()

    const goTo = (notification) => {
        const date = moment(notification.meetingDate).startOf('date').toDate()
        store.dispatch(setCurrentDate(date))
        if (!moment(date).isSame(currentDate, 'isoWeek')) {
            queryClient.invalidateQueries({queryKey: ['meetings']})
            queryClient.invalidateQueries({queryKey: ['shift']})
            queryClient.invalidateQueries({queryKey: ['gaps']})
        }
        hide()
    }
    
    return (
        <Container>
            {notifications.map((notification) => (
                <>
                    <div onClick={() => goTo(notification)} style={{ padding: 'var(--space)', cursor: 'pointer' }}>
                        <Row>
                            <Chip size="small" label={moment(notification.date).format('H:mm')}/>
                            <Chip style={{ marginLeft: 8 }} size="small" label={notification.user}/>
                        </Row>
                        <br/>
                        <b>{notification.title}</b>
                        <br/>
                        <small>{notification.content}</small>
                    </div>
                    <Divider orientation="horizontal" flexItem />
                </>
            ))}
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    currentDate: state.currentDate
})

export default connect(mapStateToProps)(ProviderNotifications)
