import { faCalendar, faChartBar, faUser } from '@fortawesome/free-regular-svg-icons';
import Switch from "@mui/material/Switch";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import BookingDetails from '../components/BookingDetails/BookingDetails';
import { Row } from '../components/layout/common';
import UtilHelper from '../helpers/UtilHelper';
import DataService from '../services/DataService';
import store from '../store';
import Autocomplete from './Autocomplete/Autocomplete';
import styles from './Form.module.css';
import Fragment from './Fragment/Fragment';
import Submit from './Submit/Submit';

const Input = styled.input`
    ${props => props.error && css`
        border: 1px solid red !important;
    `}
`

function QuickMeetingForm({ hide }) {
    const [newClient, setNewClient] = useState(true)
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const { 
        data: offers = [],
    } = useQuery({ queryKey: ['offers'], queryFn: DataService.getProviderOffers })

    const queryClient = useQueryClient()

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    const { smsNotifications } = provider

    const MeetingSchema = useMemo(() => {
        return (
            Yup.object().shape({
                date: Yup.date()
                  .required('Data jest wymagana'),
                worker: Yup.string().required('Pracownik jest wymagany'),  
                offer: Yup.string().required('Usługa jest wymagana'), 
                clientName: newClient ? Yup.string().max(50).min(3).required() : null, 
                client: !newClient ? Yup.string().required('Klient jest wymagany') : null,
                isSmsReminder: Yup.boolean(),
                isSmsConfirmation: Yup.boolean(),
              })
        )
    }, [newClient])

    const availableOffers = useMemo(() => {
        return UtilHelper.parseOptions(offers, '_id', 'title')
    }, [offers])
    
    const formik = useFormik({
        initialValues: {
          paymentMethod: 'CASH',
          type: 'PROVIDER',
          provider: store.getState()['provider'],
          products: [],
          isSmsConfirmation: smsNotifications?.meetingConfirmation || false,
          isSmsReminder: smsNotifications?.meetingReminder ||  false
        },
        validateOnMount: true,
        validationSchema: MeetingSchema,
        onSubmit: async values => {
            setLoading(true)
            await DataService.addMeeting(values)
            queryClient.invalidateQueries({queryKey: ['meetings']})
            setLoading(false)
            hide()
        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
                {step >= 1 && (
                <Fragment icon={faChartBar} text="Krok 1: Usługa">
                    <section className={styles.section}>
                        <label>Usługa</label>
                        <Select
                            options={availableOffers}
                            placeholder="Wybierz usługę"
                            value={UtilHelper.resolveValueFromDict(availableOffers, formik.getFieldProps('offer').value)}
                            onChange={option => {
                                formik.setFieldValue('offer', option.value)
                                setStep(2)
                            }}
                        />
                    </section>
                </Fragment>
                )}
                {step >= 2 && (
                    <Fragment icon={faCalendar} text="Krok 2: Szczegóły">
                        <BookingDetails
                            setStep={setStep}
                            selectedHour={formik.values['date']}
                            selectedWorker={formik.values['worker']}
                            setSelectedWorker={(w) => formik.setFieldValue('worker', w)}
                            setSelectedHour={(date) => formik.setFieldValue('date', date)}
                            offerId={formik.values['offer']}
                        />
                    </Fragment>
                )}
                {step >= 3 && (
                    <Fragment 
                        text="Krok 3: Klient" 
                        icon={faUser}
                        rightComponentVisible={true}
                        RightComponent={() => (
                            <Row vCentered>
                                <span className={styles.newClient}>Nowy klient</span>
                                <Switch
                                    onChange={(e) => setNewClient(e.target.checked)} 
                                    checked={newClient}
                                /> 
                            </Row>
                        )}
                    >                
                    {newClient ? (
                        <>
                            <section className={styles.section}>
                                <label>Imię i nazwisko klienta</label>
                                <Input 
                                    placeholder="max. 50 znaków"
                                    error={Boolean(formik.errors['clientName']) && formik.dirty}
                                    className={styles.textInput}
                                    name="clientName"
                                    id="clientName"
                                    type="text"
                                    value={formik.getFieldProps('clientName').value}
                                    onChange={formik.handleChange}
                                />
                            </section>
                            <section className={styles.section}>
                                <label>Telefon klienta</label>
                                <input 
                                    placeholder="Wpisz telefon"
                                    className={styles.textInput}
                                    max={999999999}
                                    pattern="[0-9]{9}"
                                    name="clientPhone"
                                    id="clientPhone"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.getFieldProps('clientPhone').value}
                                />
                            </section>
                        </>
                    ) : (
                        <section className={styles.section}>
                            <label>Wyszukaj klienta</label>
                            <Autocomplete
                                formik={formik}
                                id="client"
                                parseFn={client => `${client.name} (${client.phone || 'brak tel'})`}
                                fetchFn={DataService.getProviderClients}
                            />
                        </section>
                    )}
                    <section className={styles.section}>
                        <Row vCentered stretched>
                            <label>Potwierdzenie wizyty SMS</label>
                            <Switch 
                                checked={formik.values['isSmsConfirmation']}
                                onChange={e => formik.setFieldValue('isSmsConfirmation', e.target.checked)}
                            />
                        </Row>                        
                    </section>
                    <section className={styles.section}>
                        <Row vCentered stretched>
                            <label>Przypomnienie SMS 24h przed</label>
                            <Switch 
                                checked={formik.values['isSmsReminder']}
                                onChange={e => formik.setFieldValue('isSmsReminder', e.target.checked)}
                            />
                        </Row>                        
                    </section>
                    </Fragment>
                )}
                <div className={styles.space}></div>
                {step >= 2 && (
                    <Submit
                        disabled={!formik.isValid}
                        label="Zapisz"
                        loading={loading}
                        className={styles.submit} 
                        onSubmit={formik.handleSubmit}
                    ></Submit>
                )}
        </form>
    )
}

export default QuickMeetingForm
