import { FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { pushModal, setLoading, showAlert } from '../../../actions/common.actions';
import {
    DEFAULT_MEETING_COLOR,
    DISPLAY_MULTIPLIER,
} from '../../../config/CalendarConfig';
import UIContext from '../../../context/UIContext';
import MeetingForm from '../../../forms/MeetingForm';
import DataService from '../../../services/DataService';
import store from '../../../store';

const MeetingItem = styled.div`
    z-index: 100;
    position: absolute;
    opacity: ${props => props.isFinalized || props.isFailed ? 0.5 : 1};
    cursor: pointer;
    top: ${props => props.top * DISPLAY_MULTIPLIER}px;
    right: 0;
    left: 0;
    border-radius: 10px;
    border: 1px solid silver;
    background-color: white;
    border-left: 3px solid ${props => props.color || DEFAULT_MEETING_COLOR};
    height: ${props => props.duration * DISPLAY_MULTIPLIER}px;
    box-shadow: 0 0 12px rgba(0,0,0,0.3);
    overflow: hidden;
    color: black;
    line-height: 14px;
    font-size: 12px;
    transition: all 200ms linear;
    ${props => (props.groupCount > 1) && css`
        width: calc(100% / ${props.groupCount});
        left: calc((100% / ${props.groupCount}) * ${props.groupIndex - 1});
    `};
    &:hover {
        width: 100%;
        left: 0;
        z-index: 200;
    }

    ul {
        padding-left: 10px;
        margin-left: 0px;
        list-style-type: circle;
        margin-bottom: 0;
        small {
            font-size: 10px;
        }
    }
`

const Content = styled.div`
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-start; */
    padding: 5px;
    height: 100%;
    background-color: ${props => `${!props.isFailed ? props.color + '55' : 'black'}`};
    ${props => props.isFailed && css`color: silver`};
`

const ClientSpan = styled.small`
    text-decoration: underline;
    font-weight: bold;
`

const getHourCellElement = (elems) => {
    return Array.from(elems).filter(e => e.getAttribute('data-time') != null)[0]
}

function CalendarMeeting(props) {
    const { isPreview, duration, date, offer: mainOffer, top, zIndex, groupIndex, groupCount, isFinalized, client, clientName, notes, allOffers, _id, worker, status } = props
    const dispatch = useDispatch()
    const startDate = moment(date)
    const shiftTimeout = useRef()
    const endDate = moment(startDate).add(duration, 'minutes')
    const meetingItemRef = useRef()
    const dummyImageRef = useRef()
    const { dragData, setDragData } = useContext(UIContext)
    const dragElemRef = useRef()
    const dropAreaRef = useRef()
    const queryClient = useQueryClient()

    useEffect(() => {
        const dummyImage = new Image();
        dummyImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAVklEQVR42mP8/w+AC/f5+AwAB/UBC6YBlAAAAABJRU5ErkJggg==';
        document.body.appendChild(dummyImage)
        dummyImageRef.current = dummyImage
    }, [])

    const dragStartCallback = (e) => {
        e.dataTransfer.setDragImage(dummyImageRef.current, 0, 0)
        e.dataTransfer.setData("text", e.target.id);
        dragElemRef.current = props
    }

    const dragCallback = (e) => {
        
        const { clientX: x, clientY: y } = e

        const elementUnderTouch = getHourCellElement(document.elementsFromPoint(x, y))
        if (!elementUnderTouch) return;

        const rectElementUnderTouch = elementUnderTouch.getBoundingClientRect();
        // Oblicz offset kursora względem elementu
        const hourCellOffsetY = y - rectElementUnderTouch.top

        const shift = Math.floor(hourCellOffsetY / DISPLAY_MULTIPLIER / 5) * 5

        if (elementUnderTouch && elementUnderTouch.getAttribute('data-time')) {
            let newDate = moment(elementUnderTouch.getAttribute('data-time')).add(shift, 'minute').toString()
            if (newDate !== dropAreaRef.current) {
                dropAreaRef.current = newDate
                if (shiftTimeout.current) {
                    clearTimeout(shiftTimeout.current)
                }
                shiftTimeout.current = setTimeout(() => setDragData(prev => ({
                    dragElement: {...props, workerId: elementUnderTouch.getAttribute('data-workerId') }, 
                    dropArea: dropAreaRef.current 
                })), 100)
            }
        }
    }

    const touchCallback = (e) => {
        e.preventDefault()
        if (!dragData.dragElement) { setDragData(prev => ({...prev, dragElement: props})) }

        const touch = e.touches[0];
        const x = touch.clientX;
        const y = touch.clientY;

        const elementUnderTouch = getHourCellElement(document.elementsFromPoint(x, y))
        if (!elementUnderTouch) return;

        const rectElementUnderTouch = elementUnderTouch.getBoundingClientRect();

        // Oblicz offset kursora względem elementu
        const hourCellOffsetY = y - rectElementUnderTouch.top

        const shift = Math.floor(hourCellOffsetY / DISPLAY_MULTIPLIER / 5) * 5

        if (elementUnderTouch && elementUnderTouch.getAttribute('data-time')) {
            let newDate = moment(elementUnderTouch.getAttribute('data-time')).add(shift, 'minute').toString()
            if (newDate !== dropAreaRef.current) {
                dropAreaRef.current = newDate
                if (shiftTimeout.current) {
                    clearTimeout(shiftTimeout.current)
                }
                shiftTimeout.current = setTimeout(() => setDragData(prev => ({
                    dragElement: {...props, workerId: elementUnderTouch.getAttribute('data-workerId') }, 
                    dropArea: dropAreaRef.current 
                })), 100)
            }
        }
    }

    const dragEndCallback = () => {
        const { dragElement, dropArea } = dragData
        if (!dropArea || !dragElement) return 
        const newDate = moment(dropArea)
        store.dispatch(showAlert({
            text: `Czy na pewno przenieść tę wizytę na termin ${newDate.format('ddd DD MMMM H:mm')}?`,
            title: 'Potwierdzenie',
            onReject: () => {
                setDragData({dragElement: null, dropArea: null})
            },
            AdditionalContent: ({ state, setState }) => {
                return (
                    <FormControlLabel
                        sx={{ mt: 1 }}
                        control={
                            <Switch 
                                color='primary'
                                checked={state.sendSms} 
                                onChange={(e) => setState(prev => ({ ...prev, sendSms: e.target.checked }))} 
                            />
                        }
                        label="Wysłać sms z informacją do klienta?"
                    />
                )
            },
            onConfirm: async ({ sendSms }) => {
                const payload = { date: newDate.toDate() }
                if (dragElement?.workerId !== worker?._id) {
                    payload.worker = dragElement?.workerId
                } 
                await DataService.editMeeting(_id, payload, sendSms)
                setDragData({dragElement: null, dropArea: null})
                queryClient.invalidateQueries({queryKey: ['meetings']})
            }
        }))
    }

    useEffect(() => {
        if (!meetingItemRef.current) return
        
        meetingItemRef.current?.addEventListener?.('drag', dragCallback)
        meetingItemRef.current?.addEventListener?.('touchmove', touchCallback)
        meetingItemRef.current?.addEventListener?.('dragstart', dragStartCallback)
        meetingItemRef.current?.addEventListener?.('dragend', dragEndCallback)
        meetingItemRef.current?.addEventListener?.('touchend', dragEndCallback)
        return () => {
            meetingItemRef.current?.removeEventListener?.('drag', dragCallback)
            meetingItemRef.current?.removeEventListener?.('touchmove', touchCallback)
            meetingItemRef.current?.removeEventListener?.('dragstart', dragStartCallback)
            meetingItemRef.current?.addEventListener?.('dragend', dragEndCallback)
            meetingItemRef.current?.addEventListener?.('touchend', dragEndCallback)
        }
    }, [meetingItemRef, dragData])

    const isDragging = dragData.dragElement && dragData.dragElement._id === _id && !isPreview
    
    const isFailed = status === 'FAILED'

    return (
        <>
        <Tooltip title={notes || ''}>
        <MeetingItem
            ref={meetingItemRef}
            draggable="true"
            isFinalized={isFinalized}
            isFailed={isFailed}
            color={mainOffer.color}
            zIndex={zIndex + groupIndex}
            groupIndex={groupIndex}
            groupCount={groupCount}
            style={isDragging && !isPreview ? { opacity: 0} : {}}
            onClick={async (e) => {
                e.stopPropagation()
                dispatch(setLoading(true))
                const item = await DataService.getMeeting(_id)
                dispatch(setLoading(false))
                dispatch(pushModal({
                    header: 'Edycja wizyty',
                    Component: MeetingForm,
                    item
                }))
            }}
            top={top}
            duration={duration}
        >
            <Content isFailed={isFailed} color={mainOffer.color}>
                                <b style={{ fontSize: 10 }}>{startDate.format('H:mm')} - {endDate.format('H:mm')}</b>
                <br/>
                <ClientSpan>{clientName} {(client.rate != undefined) && `(${client.rate})`}</ClientSpan>
                <ul>
                    {allOffers.map(offer => <li><small>{offer.title}</small></li>)}
                </ul>
            </Content>
        </MeetingItem>
        </Tooltip>
        </>
    )
}

export default React.memo(CalendarMeeting)
