import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { Button, Typography } from '@mui/material';
import LoadingDots from '../LoadingDots/LoadingDots';
import { useQuery } from '@tanstack/react-query';
import { Row } from '../layout/common';
import DataService from '../../services/DataService';

const Day = styled(Button)`
    flex: 1;
    /* height: 50px; */
    margin: 5px;
    border-radius: 5px;
    border-width: 1px;
    border-color: silver;
    min-width: auto !important;
    flex-direction: column;
    ${props => props.type === 'OPEN' && css`
        border-color: var(--main-color);
    `};
    ${props => props.type === 'SELECTED' && css`
        border-color: var(--main-color);
        background-color: var(--main-color);
    `};
    justify-content: center;
    align-items: center;
`

const DayName = styled.small`
    flex: 1;
    height: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px;
`

const DayText = styled(Typography)`
    font-size: 16px;
    color: silver;
    ${props => props.type === 'OPEN' && css`
        color: var(--main-color);
    `};
    ${props => props.type === 'SELECTED' && css`
        color: white;
    `};
`

function BookingDetailsDayPicker({ selectedDate, offerId, setSelectedDate }) {

    const [shift, setShift] = useState(0)
    const [openDays, setOpenDays] = useState([])
    const [isFetching, setFetching] = useState(true)
    
    useEffect(() => {
        if (!offerId) { return ; }
        setFetching(true)
        DataService.getOpenDaysForMonth(offerId)
            .then(setOpenDays)
            .finally(setFetching)
    }, [offerId])
    
    useEffect(() => {
        if (!Array.isArray(openDays)) return;
        const firstFreeDay = openDays.find(item => item.open)
        if (firstFreeDay) {
            setSelectedDate(firstFreeDay.date)
        }
    }, [openDays]) 

    const weeks = useMemo(() => {
        if (!Array.isArray(openDays)) return []
        return _.chunk(openDays, 7)
    }, [openDays])

    if (isFetching) {
        return <LoadingDots/>
    }

    return (
        <>
            {weeks.map((week) =>(
                <Row key={week[0].date}>
                    {week.map(({date, open}) => {
                        const isSelected = moment(date).isSame(moment(selectedDate), 'date')
                        const type = isSelected ? 'SELECTED' : (open ? 'OPEN' : 'REGULAR')
                        return (
                            <Day size="small" key={date} onClick={() => {
                                if (!open) return;
                                setSelectedDate(date)
                            }} type={type}>
                                <DayText type={type}>
                                        {moment(date).format('D')}
                                </DayText>
                                <DayText style={{ fontSize: 11 }} type={type}>{moment(date).format('ddd')}</DayText>
                            </Day>
                        )
                    })}
                </Row>
            ))}
        </>
    )
}

export default BookingDetailsDayPicker
