import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { FacebookLoginButton } from "react-social-login-buttons";
import * as Yup from 'yup';
import { pushToast } from '../../actions/common.actions';
import { Row } from '../../components/layout/common';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { TT_URL } from '../../const/url';
import DataService from '../../services/DataService';
import store from '../../store';
import styles from './LoginPage.module.css';
import LoginPageGoogleButton from './LoginPageGoogleButton';

const fbStyles = {
    width: '100%',
    height: 30,
    margin: 0,
    marginBottom: 8,
    borderRadius: 20,
    fontSize: 14
}

function LoginPage() {
    const { 
        isFetching,
        data: user
     } = useQuery({ 
         queryFn: DataService.getUser, 
         queryKey: ['user'],
         refetchOnWindowFocus: false,
         retry: false
    })

    const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient()
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://accounts.google.com/gsi/client'
        document.head.appendChild(script)

        window.handleCredentialResponse = function (response) {
            login3rdParty(response.credential, 'google')
        }
        
    }, [])

    const login3rdParty = async (token, type) => {
        try { 
            const response = await DataService.checkThirdPartyLogin(token, type)
            localStorage.setItem('ontime-token', response.access_token)
            queryClient.invalidateQueries({ queryKey: ['user'] })
        } catch (err) {
            const { response } = err
            if (response?.status === 404) { register3rdPartyUser({ ...response.data, token, type }) }
        }
    }

    const register3rdPartyUser = (newUserData) => {
        window.location.replace(`${TT_URL}/register.html?ref=biz&${new URLSearchParams(newUserData).toString()}`)
        // setRedirect(newUserData)
    }

    const onLoginWithFb = useCallback(() => {
        window.FB.login((response) => {
            if(response.status !== 'connected') { return ; }
            const { accessToken } = response?.authResponse
            login3rdParty(accessToken, 'facebook')
        });
    })

    const onLogin = async (values) => {
        const { email, password } = values 
        setLoading(true)
        try {
            const result = await DataService.login(email, password)
            localStorage.setItem('ontime-token', result.access_token)
            queryClient.invalidateQueries({ queryKey: ['user'] })
        } catch (e) {
            store.dispatch(pushToast({ type: 'error', text: 'Nieprawidłowe dane' }))
        } finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        validationSchema: Yup.object({
            email: Yup.string().required().email(),
            password: Yup.string().required() 
        }),
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: onLogin
    })

    if (user) {
        return <Redirect to={`/panel`} />
    }

    // if (redirect) {
    //     return <Redirect to={`${TT_URL}/register.html?ref=biz&${new URLSearchParams(redirect).toString()}`} />
    // }

    return (
        <>
        {(loading || isFetching) && <LoadingBackdrop />}
        <div className={styles.background}>
            <img className={styles.logo} src="logosoc.png"/>
            <form onSubmit={formik.handleSubmit}>
            <div className={styles.box}>
                <h3><FontAwesomeIcon icon={faLock} /> Logowanie</h3>
                <Input name="email" onChange={e => formik.setFieldValue('email', e.target.value)} value={formik.values['email']} id="email" placeholder="Adres e-mail"></Input>
                <Input name="password" onChange={e => formik.setFieldValue('password', e.target.value)} value={formik.values['password']} id="password" placeholder="Hasło" type="password"></Input>
                <Button type="submit" variant="contained">Zaloguj</Button>
                <span className={styles.or}>LUB</span>
                <FacebookLoginButton 
                    onClick={onLoginWithFb}
                    iconSize={15}
                    align="center"
                    text="Zaloguj z Facebookiem"
                    style={fbStyles}>
                    {/* <small style={{ fontSize: 14 }}>Zaloguj z Facebookiem</small>  */}
                </FacebookLoginButton>
                <LoginPageGoogleButton />
                <Row className={styles.buttons} stretched marginBetween>
                    <Button onClick={() => window.location.replace(`${TT_URL}/register.html?ref=biz`)} size="small" variant="text">Zarejestruj się</Button>
                    <Button onClick={() => window.location.replace(`${TT_URL}/recovery.html`)} size="small" variant="text">Zapomniałem hasła</Button>
                </Row>
            </div>
            </form>
        </div>
        </>
    )
}

export default LoginPage
