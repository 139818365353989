import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DataService from '../../../services/DataService';

const Fade = styled.div`
    padding-top: 128px;
    position: absolute;
    top: 0; bottom: 0;
    right: 0; left: 0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* background-color: rgba(255,255,255,0.2); */
    backdrop-filter: blur(5px);
    z-index: 1000;
    flex-direction: column;
    h3 {
        font-size: 22px;
    }
`

function CalendarNoOffersPlaceholder() {

    const { data: offers = [], isFetched } = useQuery({ queryKey: ['offers'], queryFn: DataService.getProviderOffers, refetchOnWindowFocus: false })
    const history = useHistory()

    const goToOffers = () => {
        history.push('/panel/uslugi')
    }

    if (isFetched && offers.length === 0) {
        return (
            <Fade>
                <h3>Dodaj usługi do swojego salonu, by korzystać z kalendarza</h3>
                <Button onClick={goToOffers} variant="outlined">Przejdź do usług</Button>
            </Fade>
        )
    } 

    return null
    
}

export default CalendarNoOffersPlaceholder
