import { Paper } from '@material-ui/core'
import { Switch, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material'
import moment from 'moment'
import React from 'react'
import StyledTableRow from '../layout/StyledTableRow'
import ProviderShiftHoursSelect from './ProviderShiftHoursSelect'

function ProviderShiftHours({ schedule, onChange, containerStyles = {} }) {
    moment.locale('pl')
    const weekDays = moment.weekdaysShort(true)
    const WEEK = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

    return (
        <div>
        <TableContainer style={containerStyles}>
            <Table>
                <TableHead>
                    <TableCell width={50} align="center">Stan</TableCell>
                    <TableCell width={50} align="center">Dzień</TableCell>
                    <TableCell>Od</TableCell>
                    <TableCell>Do</TableCell>
                </TableHead>
                <TableBody>
                    {weekDays.map((day, index) => {
                        const value = schedule?.[WEEK[index]]
                        const isActive = value !== undefined
                        return (
                            <StyledTableRow key={day}>
                                <TableCell>
                                    <Switch
                                        onChange={(e) => onChange({ type: e.target.checked ? 'SET_OPEN' : 'SET_CLOSED', weekDay: WEEK[index] })} 
                                        checked={isActive}
                                    ></Switch>
                                </TableCell>
                                <TableCell>{day}</TableCell>
                                <TableCell>
                                    <ProviderShiftHoursSelect
                                        onChange={onChange}
                                        isActive={isActive}
                                        daySchedule={value}
                                        weekDay={WEEK[index]}
                                        which={'from'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <ProviderShiftHoursSelect
                                        onChange={onChange}
                                        isActive={isActive}
                                        daySchedule={value}
                                        weekDay={WEEK[index]}
                                        which={'to'}
                                    />
                                </TableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    )
}

export default ProviderShiftHours
