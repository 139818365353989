import { faCalendar, faEdit, faNoteSticky, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, Input, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pushModal, showAlert } from '../../../actions/common.actions';
import ClientMeetingsDetails from '../../../components/ClientMeetingsDetails';
import { Row } from '../../../components/layout/common';
import PageContainer from '../../../components/layout/PageContainer';
import StyledTableRow from '../../../components/layout/StyledTableRow';
import LoadingDots from '../../../components/LoadingDots/LoadingDots';
import ClientForm from '../../../forms/ClientForm';
import DataService from '../../../services/DataService';
import Nav from '../Nav';
import store from '../../../store';
import ClientNoteForm from '../../../forms/ClientNoteForm';
import HiddenPhone from '../../../components/HiddenPhone';

let queryTimeout;

function ClientsPage() {

    const [query, setQuery] = useState('')
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [clients, setClients] = useState([])
    const [page, setPage] = useState(0)
    const [hideLoadMore, setHideLoadMore] = useState(false)

    const { data: provider = {} } = useQuery({ 
        queryKey: ['provider'],
        queryFn: DataService.getProvider 
    })

    const { _id: providerId } = provider

    const loadMore = useCallback(() => {
        setLoading(true)
        DataService.getProviderClients(query, page + 1)
            .then((array) => {
                setHideLoadMore(array.length < 10)
                if (!array.length) return
                setPage(prev => prev + 1)
                setClients(prev => [...prev, ...array])
                
            })
            .finally(setLoading)
    }, [page, query])

    const fetch = useCallback(() => {
        DataService.getProviderClients(query, 0)
            .then((array) => {
                setHideLoadMore(array.length < 10)
                if (!array.length) return
                setClients(array)
            })
            .finally(setLoading)
    }, [query])

    const reset = () => {
        setLoading(true)
        setClients([])
        setPage(0)
        if (queryTimeout) {
            clearTimeout(queryTimeout)
        }
        queryTimeout = setTimeout(fetch, 300)
    }

    useEffect(() => {
        reset()
    }, [query])

    const onSetBanned = async (clientId, value) => {
        await DataService.setClientBanned(clientId, value)
        fetch()
    }
    
    const onRemove = useCallback((_id) => {
        dispatch(showAlert({
            text: 'Czy na pewno chcesz usunąć klienta?',
            title: 'Usuwanie klienta',
            onConfirm: async () => {
                await DataService.deleteClient(_id)
                reset()
            }
        }))
    })

    const onShowMeetings = useCallback((id, name) => {
        dispatch(pushModal({
            header: `Wizyty - ${name}`,
            Component: ClientMeetingsDetails,
            extraProps: {
                clientId: id
            }
        }))
    })

    const onEdit = useCallback((client) => {
        dispatch(pushModal({
            header: `Klient - ${client.name}`,
            Component: ClientForm,
            extraProps: {
                client, callback: reset
            }
        }))
    })

    const onAdd = useCallback(() => {
        dispatch(pushModal({
            header: `Dodawanie klienta`,
            Component: ClientForm,
            extraProps: {
                callback: reset
            }
        }))
    })

    const getBanBtnLabel = (isBanned) => {
        return isBanned ? 'Odblokuj' : 'Zablokuj'
    }

    const onDeleteNote = async (id, clientId) => {
        await DataService.deleteNoteFromClient(clientId, id)
        reset()
    }

    const onAddNote = async (client) => {
        store.dispatch(pushModal({
            Component: ClientNoteForm,
            header: 'Dodaj notatkę',
            extraProps: {
                callback: reset,
                clientId: client._id
            }
        }))
    }

    return (
        <PageContainer>
            <Nav title="Klienci" />
            <Row marginBetween style={{ marginBottom: 16 }}>
                <Button onClick={onAdd} size="large" startIcon={<FontAwesomeIcon icon={faPlus} />} variant="outlined">Nowy klient</Button>
            </Row>
            <Input
                style={{ marginBottom: 16 }}
                fullWidth
                value={query} 
                placeholder="Wyszukaj po imieniu, nazwisku lub nr telefonu" 
                onChange={e => setQuery(e.target.value)} 
            />
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width={100} align="center">Konto</TableCell>
                        <TableCell>Imię i nazwisko</TableCell>
                        <TableCell align="center">Ocena</TableCell>
                        <TableCell width={200}>Telefon</TableCell>
                        <TableCell>Notatka</TableCell>
                        <TableCell>Data dodania</TableCell>
                        <TableCell>Akcje</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.map((client, index) => {
                        const { name, rate, phone, user, createdAt, _id, bans, notes } = client
                        const isRegistered = user != undefined
                        const isBanned = bans.includes(providerId)
                        return (
                            <StyledTableRow key={_id}>
                                <TableCell align="center">
                                    <FontAwesomeIcon 
                                        color={isRegistered ? 'var(--main-color)' : 'silver'} 
                                        icon={isRegistered ? faCheckCircle : faTimesCircle} 
                                    />
                                </TableCell>
                                <TableCell>{name}</TableCell>
                                <TableCell align="center">{rate}</TableCell>
                                <TableCell>
                                    <HiddenPhone phoneNumber={phone} />
                                </TableCell>
                                <TableCell>{notes.map(note => (
                                    <Chip style={{ fontSize: 10, maxWidth: 150 }} sx={{
                                        height: 'auto',
                                        '& .MuiChip-label': {
                                          display: 'block',
                                          whiteSpace: 'normal',
                                        },
                                      }} onDelete={() => onDeleteNote(note._id, _id)} label={note.content} variant="outlined"></Chip>
                                ))}</TableCell>
                                <TableCell>{moment(createdAt).format('DD.MM.YYYY')}</TableCell>
                                <TableCell align="center">
                                    <Row marginBetween>
                                        <Button startIcon={<FontAwesomeIcon icon={faCalendar} />} onClick={() => onShowMeetings(_id, name)} variant="outlined">Wizyty</Button>
                                        {<Button startIcon={<FontAwesomeIcon icon={faNoteSticky} />} onClick={() => onAddNote(client)} variant="outlined">Notatka</Button>}
                                        {user === undefined && <Button startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={() => onEdit(client)} variant="contained">Edycja</Button>}
                                        {!isRegistered && <Button startIcon={<FontAwesomeIcon icon={faTimes} />}  onClick={() => onRemove(_id)} color="error" variant="outlined">Usuń</Button>}
                                        {isRegistered && <Button startIcon={<FontAwesomeIcon icon={faTimes} />}  onClick={() => onSetBanned(_id, !isBanned)} color="error" variant="outlined">{getBanBtnLabel(isBanned)}</Button>}
                                    </Row>
                                </TableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="center" colSpan={7}>
                            {loading ? <LoadingDots /> : (!hideLoadMore && <Button onClick={loadMore} variant="outlined">Pokaż więcej</Button>)}
                        </TableCell>
                    </TableRow>
                </TableFooter>
                </Table>
            </TableContainer>
        </PageContainer>
    )
}

export default ClientsPage
