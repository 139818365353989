import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled, { css } from 'styled-components';
import { pushModal } from '../../../actions/common.actions';
import { Column, Row } from '../../../components/layout/common';
import ChanceForm from '../../../forms/ChanceForm';
import DataService from '../../../services/DataService';
import store from '../../../store';

const ChanceItem = styled(Row)`
    margin-bottom: 16px;
    cursor: pointer;
    small {
        font-size: 12px;
    }
    
    b {
        font-size: 12px;
    }
`
const Day = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    height: 40px;
    width: 30px;
    border-radius: 5px;
    border-width: 1px;
    border-color: silver;
    background: #efefef;
    justify-content: center;
    align-items: center;
    ${props => props.column && css`
        line-height: 14px;
        flex-direction: column;
    `}
    color: grey;
`

const Discount = styled.b`
    color: green;
    margin-left: 5px;
`

function CalendarChancesList() {
    const { data: chances = [] } = useQuery({ queryKey: ['chances'], queryFn: DataService.getProviderChances })

    return (
        <>
            <Button 
                variant="outlined" 
                type="button" 
                fullWidth
                style={{ marginBottom: 16 }}
                onClick={() => store.dispatch(pushModal({
                    header: 'Dodaj okazję',
                    Component: ChanceForm
                }))}
            >Dodaj okazję</Button>
            {chances.map((chance) => {
            const { options, offers, worker, maxDiscount } = chance
            const firstOption = options[0] && moment(options[0])
            return (
                <ChanceItem onClick={() => store.dispatch(pushModal({
                    header: 'Edytuj okazję',
                    Component: ChanceForm,
                    extraProps: {
                        initialData: chance
                    }
                }))}>
                    <Row vCentered>
                    {firstOption ? (
                        <Day type="OPEN" column>
                            <b>{firstOption.format('D')}</b>
                            <small>{firstOption.format('MMM')}</small>
                        </Day> 
                    ) : (
                        <Day type="OPEN"><FontAwesomeIcon color={'grey'} icon={faQuestion}></FontAwesomeIcon></Day>
                    )}
                    </Row>
                    <Column>
                        <Row vCentered>
                            <b>{worker.firstname}</b>
                            {(maxDiscount > 0) && <Discount>-{maxDiscount}%</Discount>}
                        </Row>
                        <small>{_.truncate(offers.map(o => o.title).join(', '))}</small>
                    </Column>
                </ChanceItem>
            )
        })}
    </>
    )
}

export default CalendarChancesList
