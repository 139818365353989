import React from 'react'
import styles from './LoadingDots.module.css'

function LoadingDots() {
    return (
        <div className={styles["lds-ellipsis"]}><div></div><div></div><div></div><div></div></div>
    )
}

export default LoadingDots
